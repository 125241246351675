.personalCart {
  width: 100%;
  min-height: 275px;
  padding: 44px;
  box-shadow: 0px 5px 15px 0px #0000000d;
  background-color: #fff;
}

.personalCartContainer {
  width: 100%;
  height: 100%;
}
.personalCartHeader {
  display: flex;
  justify-content: space-between;
  margin-bottom: 28px;
}
.personalCartHeader h3 {
  font-size: 30px;
  font-weight: 700;
  line-height: 35px;
  color: var(--black);
  margin-right: 30px;
  white-space: nowrap;
}
.personalCartUser {
  width: 100%;
  height: 118px;
  border-radius: 5px;
  background-color: #f7f7fb;
  display: flex;
  align-items: center;
  padding: 20px 0px 16px 28px;
  margin-bottom: 16px;
  cursor: pointer;
  border: 2px solid transparent;
}
.acitve {
  border: 2px solid var(--red);
}
.checked {
  width: 16px;
  height: 16px;
  min-width: 16px;
  min-height: 16px;
  border-radius: 50%;
  background-color: #e5e5ee;
  margin-right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.checkedActive {
  width: 16px;
  height: 16px;
  min-width: 16px;
  min-height: 16px;
  border-radius: 50%;
  background-color: var(--red);
}
.checkedActive div {
  width: 10px;
  height: 10px;
  min-width: 10px;
  min-height: 10px;
  border-radius: 5px;
  background-color: #fff;
}
.personalContact {
  display: flex;
}
.personalContact :first-child {
  margin-right: 16px;
}
.personalInfo p {
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
}
.personalContact p svg,
.personalInfo p img {
  width: 12px;
  height: 12px;
  margin-right: 3px !important;
  object-fit: contain;
}
.personalCartImg {
  width: 78px;
  height: 78px;
  margin-right: 32px;
  border-radius: 40px;
  background-color: #e5e5ee;
  display: flex;
  align-items: center;
  justify-content: center;
}
.personalCartImg svg {
  height: 32px;
  width: 26px;
}
.personalInfo {
}
.personalInfo h4 {
  font-size: 18px;
  font-weight: 700;
  line-height: 23px;
  color: var(--black);
  margin-bottom: 11px;
}
.personalCartHeader button {
  border: none;
  background: none;
  background-color: var(--red);
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  border-radius: 5px;
  color: #fff;
  max-width: 293px;
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  cursor: pointer;
}
@media (max-width: 1280px) {
  .personalCart {
    max-width: 100%;
    width: 100%;
  }
}
@media (max-width: 768px) {
  .personalCartHeader {
    flex-direction: column;
    row-gap: 10px;
  }
  .personalCartHeader button {
    font-size: 14px;
  }
  .personalCart {
    max-width: 100%;
    width: 100%;
    padding: 22px;
  }
  .personalCartHeader h3 {
    font-size: 20px;
  }
  .personalCartUser {
    padding: 10px 10px 10px 16px;
  }
  .personalCartImg {
    display: none;
  }
  .personalContact {
    flex-direction: column;
  }
  .personalInfo h4 {
    font-size: 16px;
    line-height: 16px;
    margin-bottom: 6px;
  }
  .personalContact p svg,
  .personalInfo p img {
    margin-right: 8px !important;
  }
  .personalContact :first-child {
    margin-right: 0;
  }
}
@media (max-width: 420px) {
  .checked {
    display: none;
  }
  .personalInfo p,
  .personalCartHeader button {
    font-size: 13px;
  }
}
