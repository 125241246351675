.categoryBrandLoad {
  width: 100%;
  height: 500px;
  margin: 100px 0px 50px;
}

.example {
  scroll-margin-top: 180px;
}

.categoryTitleLoad {
  width: 35%;
  height: 200px;
  margin-top: 30px;
}
.notFound {
  text-align: center;
  font-size: 30px;
  color: var(--black);
  padding-top: 50px;
}
.categoryAtributeLoad {
  width: 100%;
  height: 44px;
  margin-bottom: 30px;
}
.categoryProductListLoad {
  width: 100%;
  height: 700px;
}

.title {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 16px;
  margin-bottom: 46px;
  position: relative;
}
.titleText {
  font-size: 30px;
  font-weight: bold;
  text-transform: uppercase;
}
.titleCount {
  padding: 4px 16px;
  color: #fff;
  font-weight: bold;
  background-color: var(--red);
  border-radius: 90px;
  font-size: 18px;
  margin-bottom: -5px;
}
.categorySlider {
  margin: 78px 0px 100px;
}
.categoryBrand {
  margin: 100px 0px 120px;
  display: grid;
  justify-content: space-between;
  grid-template-columns:
    minmax(100px, 251px)
    minmax(100px, 251px)
    minmax(100px, 251px)
    minmax(100px, 251px)
    minmax(100px, 251px);
  row-gap: 30px;
  column-gap: 44px;
}
.categoryBrandItem {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.categoryItemTop {
  width: 100%;
  height: 235.79px;
  border-radius: 246.9px;
  background-color: var(--gray-20);
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
.categoryItemTop img {
  max-width: 144px;
  width: 100%;
  height: 144px;
}
.categoryItemBottom {
  text-align: center;
}
.productList {
  margin-top: 43px;
  display: grid;
  justify-content: space-between;
  grid-template-columns: repeat(5, minmax(200px, 250px));
  gap: 25px;
  padding: 20px 0;
}

.productListPagination {
  margin-top: 50px;
  display: flex;
  justify-content: space-between;
}
.categoryMore {
  width: 100%;
  height: 44px;
  background-color: var(--gray-20);
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 56px;
}
.categoryRecordPage {
  display: flex;
  align-items: center;
}
.categoryRecordPage span {
  font-size: 16px;
  font-weight: 700;
  line-height: 19px;
  color: var(--black);
}
.categoryRecordSelect {
  width: 46px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: relative;
}
.categoryRecordSelect span {
  display: block;
  margin-right: 5px;
}
.categorySelectList {
  box-shadow: 0px 4px 15px 0px #0000001a;
  background-color: #fff;
  position: absolute;
  height: 105px;
  width: 46px;
  bottom: -105px;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px 4px;
}
.categorySelectList span {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0px;
}
.categorySelectList :not(:last-child) {
  padding-bottom: 8px;
  margin-bottom: 8px;
  border-bottom: 1px solid var(--black);
}

.categoryMore span {
  font-size: 16px;
  font-weight: 400;
  line-height: 19px;
  color: var(--black);
  cursor: pointer;
}
.categoryAtribute {
  display: flex;
  /* align-items: center; */
}
.categoryAtributeFix {
  height: 52px;
  display: flex;
  flex-wrap: nowrap;
}
.categoryContainer {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  flex-wrap: nowrap;
  overflow-x: auto;
  padding-bottom: 5px;
}
.categoryAtribute svg {
  width: 52px;
  height: 52px;
  cursor: pointer;
}
.categoryAtributeActive :not(:last-child) {
  margin-right: 6px;
}
.categoryAtributeActive {
  height: 52px;
  margin: 20px 0px 32px;
  overflow-x: auto;
  display: flex;
  flex-wrap: nowrap;
  padding-bottom: 5px;
}
.categoryAtributeActive :hover {
  background-color: var(--red);
  color: #fff;
}
.categoryAtributeActive :hover svg rect {
  fill: #fff;
}
.categoryAtributeActive svg {
  margin-left: 10px;
  margin-top: -2px;
}
.categoryAtributeActive svg rect {
  fill: var(--red);
}
.categoryAtributeActive span {
  cursor: pointer;
  display: inline-block;
  background-color: var(--gray-20);
  font-size: 15px;
  font-weight: 700;
  line-height: 18px;
  color: var(--black);
  padding: 15px 10px;
  border-radius: 25px;
  white-space: nowrap;
}
.categoryAtribute :not(:last-child) {
  margin-right: 6px;
}
.categoryAtribute span {
  cursor: pointer;
  display: inline-block;
  background-color: var(--gray-20);
  font-size: 15px;
  font-weight: 700;
  line-height: 18px;
  color: var(--black);
  padding: 17px 10px;
  border-radius: 25px;
  white-space: nowrap;
}

.categoryAtribute span:hover {
  color: #fff;
  background-color: var(--red);
}

.categoryBrandItem span {
  cursor: pointer;
}

.categoryBrandItem {
  transition: all 1s ease-out;
}

.categoryBrandItem:hover img {
  transform: scale(1.2, 1.2);
  transition: all 1s ease-out;
}

.categoryBrandItem:hover span {
  color: var(--red);
  transition: all 1s ease-out;
}

@media (max-width: 1440px) {
  /* .productList {
    grid-template-columns:
      minmax(100px, 219px)
      minmax(100px, 219px)
      minmax(100px, 219px)
      minmax(100px, 219px)
      minmax(100px, 219px);
  } */

  .categoryBrand {
    grid-template-columns:
      minmax(100px, 251px)
      minmax(100px, 251px)
      minmax(100px, 251px)
      minmax(100px, 251px);
  }
}
@media screen and (max-width: 1200px) {
  .productList {
    grid-template-columns: repeat(4, minmax(200px, 280px));
    gap: 15px;
  }

  .categoryBrand {
    grid-template-columns:
      minmax(100px, 251px)
      minmax(100px, 251px)
      minmax(100px, 251px);
  }
}

@media screen and (max-width: 900px) {
  .productList {
    grid-template-columns: repeat(3, minmax(210px, 300px));
  }
}

@media screen and (max-width: 768px) {
  .categoryBrand {
    grid-template-columns:
      minmax(100px, 251px)
      minmax(100px, 251px);
  }
  .productListPagination {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }
  .categoryRecordPage {
    margin-top: 20px;
  }
  .categoryItemTop {
    height: 230px;
  }
  .categoryItemTop img {
    max-width: 120px;
    width: 100%;
    height: 100px;
  }
}

@media screen and (max-width: 726px) {
  .productList {
    grid-template-columns: repeat(3, minmax(190px, 300px));
  }
}

@media screen and (max-width: 668px) {
  .productList {
    grid-template-columns: repeat(2, 1fr);
  }
  .container {
    max-width: none !important;
    min-width: 0 !important;
    width: 100% !important;
  }
}

@media (max-width: 576px) {
  .categoryItemTop {
    height: 190px;
  }
  .categoryItemTop img {
    max-width: 90px;
    width: 100%;
    height: 80px;
  }
  .titleText {
    font-size: 25px;
  }
  .categorySlider {
    margin: 30px 0px 50px;
  }
}

@media screen and (max-width: 442px) {
  /* .productList {
    grid-template-columns: repeat(1, auto);
  } */
  .container {
    max-width: none !important;
    width: 100% !important;
  }
  .categoryItemTop {
    height: 150px;
  }
  .categoryItemTop img {
    max-width: 80px;
    width: 100%;
    height: 70px;
  }
}

@media screen and (max-width: 356px) {
  .titleText {
    font-size: 18px;
  }
  .titleCount {
    margin-bottom: unset;
    font-size: 16px;
  }
  .categorySlider {
    margin: 30px 0px 50px;
  }
  .categoryItemTop {
    height: 130px;
  }
  .categoryItemTop img {
    max-width: 60px;
    width: 100%;
    height: 60px;
  }
}

/* width */
.categoryContainer::-webkit-scrollbar {
  height: 3px;
}
.categoryAtributeActive::-webkit-scrollbar {
  height: 3px;
}
/* Handle */
.categoryContainer::-webkit-scrollbar-thumb {
  background: var(--red);
  border-radius: 10px;
}

.categoryAtributeActive::-webkit-scrollbar-thumb {
  background: var(--red);
}
