.user_page {

}

.user_page_body {
    display: flex;
    gap: 18px;
    align-items: flex-start;
    margin: 90px 0 170px 0;

}

.null_history_title {
    display: flex;
    align-items: center;
    gap: 10px;
}

.null_history_title span {
    font-size: 30px;
    font-weight: bold;
    color: black;
    text-transform: uppercase;
}
.novu_favs {
    margin-top: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 40px;
    align-self: center;

}
.null_history{
     width: 100%;
}

.novu_favs_text {
    text-align: center;
    font-size: 25px;
    font-weight: bold;
    color: black;
    text-transform: uppercase;
}

@media (max-width: 768px) {
    .user_page_body {
        flex-direction: column-reverse;
        margin: 50px 0 60px 0;
    }

}





