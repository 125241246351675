.details_big_banner {
    margin-bottom: 60px;
}

.details_big_banner img {
    max-width: 100%;
    max-height: 100%;
    border-radius: 5px;
}

.details_big_banner_loader {
    height: 350px;
}

@media (max-width: 722px) {
    .details_big_banner {
        margin-bottom: 40px;
    }
    .details_big_banner_loader {
        height: 200px;
    }
}

@media (max-width: 490px) {
    .details_big_banner {
        margin-bottom: 10px;
    }
    .details_big_banner_loader {
        height: 100px;
    }

}