.country{
  width:100%;
  overflow: auto;
  flex: 1 1 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 30px 60px 10px 60px;
}

.country :not(:last-child){
   margin-bottom: 28px;
}


.countryItem p{
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .countryItem p span{
    font-size: 20px;
    font-weight: 400;
    line-height: 23px;
    color:var(--black)   
  } 
  .brandImg{
    max-width: 100px;
    width: 100%;
    height: 30px;
    position: relative;
  }
.brandImg img{
   position: absolute;
   width: 100%;
   height: 100%;
   object-fit: contain;
   top: 0px;
   left: 0px;
   bottom: 0px;
   right: 0px;
   cursor: pointer;
  }

.checkboxWrapper input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.checkboxWrapper label {
  position: relative;
  cursor: pointer;
  background: #fff;
  width: 4.4%;
  height: 5%;
}
.checkboxWrapper span{
   cursor: pointer;
}
.checkboxWrapper span:hover{
  color: var(--red);
} 
.checkboxWrapper label:before {
  content: "";
  -webkit-appearance: none;
  background-color: transparent;
  border: 1px solid var(--black);
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
    inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
  padding: 10px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 5px;
}

.checkboxWrapper input:checked + label:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 8px;
  width: 6px;
  height: 14px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  
}
.checkboxWrapper input:checked + label {
  background: var(--red);
  width: 4.4%;
  height: 5%;
  /* overflow: hidden; */
}

@media(max-width:768px){
  .checkboxWrapper label {
    width: 8.5%;

  }
  .checkboxWrapper input:checked + label {
    width: 8.5%;
  }  
}



/* width */
::-webkit-scrollbar {
    width: 10px;
    
  }
  
  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 20px;
  }
  
  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }


    
  @media(max-width:768px){
  .countryItem p span{
    font-size: 12px;  
  } 
  .country{
    padding: 30px 20px 10px 20px;
  }
  }