.questions_body {

}

.questions_body h1 {

}

.questions_list_content {
    display: flex;
    align-items: flex-start;
    gap: 30px;
    margin: 34px 0 62px 0;
}

.questions_list {
    max-width: 406px;
    width: 100%;
    background: #f7f7fb;
    border-radius: 5px;
    padding: 24px;
    display: flex;
    flex-direction: column;
}

.questions_item {
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
    padding: 16px 10px;
    border-bottom: 1px solid rgba(40, 40, 40, 0.1);
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 10px;
}

.input_filter_search {
    margin-bottom: 14px;
    position: relative;
}

.input_filter_search input {
    font-size: 14px;
    padding: 12px 30px 12px 20px;
    background-color: transparent;
    border-radius: 2px;
    border: 1px solid #28282833;
    width: 100%;
}

.input_filter_search input:focus {
    border-color: var(--green);

}

.search_filter_btn {
    cursor: pointer;
    border: none;
    background-color: transparent;
    position: absolute;
    top: 12px;
    right: 12px;
}
.questionsList_loader {
    display: flex;
    width: 100%;
    gap: 40px;

}

.questionsList_loader_item {
    width: 49%;
    height: 300px;
}


@media (max-width: 1280px) {
    .questions_list_content {
        flex-direction: column;
    }
    .questionsList_loader{
        flex-direction: column;
        width: 100%;

    }
    .questionsList_loader_item{
        width: 100%;
    }
}

@media (max-width: 600px) {
    .questions_list_content {

    }

    .questions_body h1 {
        font-size: 20px;
    }
}

