.container_catalog_slides {
  display: flex;
  flex-direction: row;
  overflow: hidden;
  gap: 20px;
}
.catalog_home_slides {
  display: flex;
  flex-direction: column;
  gap: 36px;
}
.container {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  column-gap: 30px;
  overflow: hidden;
}
.leftContent {
  max-width: 950px;
  width: 100%;
  display: flex;
  position: relative;
  overflow: clip;
}
.leftLoadContent_big_slider {
  height: 500px;
  width: 100%;
}
.leftLoadContent_mini_slider {
  height: 240px;
  margin-top: 0;
}
.leftSlider {
  /* border-radius: 10px;
  height: 388px;
  max-width: 766px; */
  max-width: 715px;
  width: 100%;
  height: 388px;
  display: flex;
  position: relative;
  overflow: clip;
}

.leftSliderImg {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 10px;
}
.rightContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 20px;
  width: 38%;
  min-width: 150px;
  overflow: clip;
}
.rightSlider {
  width: 100%;
  max-width: 290px;
  height: 184px;
}

.rightSlider Img {
  width: 100%;
  max-width: 460px;
  object-fit: contain;
  border-radius: 10px;
}
.slideBtn {
  z-index: 2;
  position: absolute;
  width: 50px;
  height: 50px;
  box-shadow: 0px -1px 16px rgb(0 0 0 / 12%);
  justify-content: center;
  align-items: center;
  border-radius: 90px;
  background-color: #fff;
  cursor: pointer;
  top: calc(50% - 25px);
}
.slidePrevArrow {
  left: 15px;
  transform: rotate(180deg);
}
.slideNextArrow {
  right: 15px;
}
.slideBtn:hover svg path {
  stroke: var(--red);
}

.leftLoadContent {
  width: 100%;
  height: 100%;
  aspect-ratio: 10 / 4;
}
@media only screen and (max-width: 1392px) {
  .container {
    flex-direction: column;
    row-gap: 20px;
    justify-content: center;
  }
  .leftContent {
    max-width: none;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    position: relative;
    overflow: clip;
  }
  .rightContent {
    flex-direction: row;
    column-gap: 20px;
    width: 100%;
    min-width: 100%;
    justify-content: space-between;
    overflow: clip;
  }
  .leftSliderImg {
    min-width: 100%;
  }
  .rightSlider {
    width: 100%;
    max-width: 48%;
    height: auto;
  }
  .leftSlider {
    height: auto;
    min-width: 100%;
  }
  .rightSlider Img {
    max-width: none;
  }
  .container_catalog_slides {
    flex-direction: column;
    row-gap: 20px;
  }
  .rightSlider {
    width: 50%;
  }
  .rightSlider img {
    object-fit: cover;
  }
  .container_catalog_slides {
    align-items: center;
  }
}
@media only screen and (max-width: 992px) {
  .rightContent {
    column-gap: 12px;
  }
}

@media only screen and (max-width: 600px) {
  .rightSlider img {
    border-radius: 5px;
  }
}
@media only screen and (max-width: 430px) {
  .container {
    row-gap: 8px;
  }
}
