.topBtn {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  right: 20px;
  bottom: 30px;
  cursor: pointer;
  z-index: 12;
  height: 40px;
  width: 40px;
  background: #e31335;
  border-radius: 30px;
  box-shadow: 0 10px 20px 0 rgba(253, 73, 73, 0.3);
}
.topBtn .arrow {
  transform: rotate(180deg);
  width: 15px;
}
.topBtn .arrow path {
  stroke: #fff;
}
@media only screen and (max-width: 992px) {
  .topBtn {
    bottom: 100px;
  }
}
