.container {
  max-width: 218px;
  height: 108px;
  width: calc(100% / 2 - 10px);
  cursor: pointer;
  border: 2px solid #e5e5ee;
  border-radius: 5px;
  padding: 20px 18px 10px 18px;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  row-gap: 14px;
}
.container:hover {
  border-color: var(--red);
}
.checkBtn {
  position: absolute;
  top: 20px;
  left: 18px;
  background-color: #e5e5ee;
  border-radius: 50%;
  cursor: pointer;
  height: 14px;
  width: 14px;
  border: 4px solid transparent;
}
.cartImgs {
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 10px;
}
.cartImg {
  max-width: 130px;
  height: 32px;
}
.imgBuyNow {
  max-width: 105px;
  height: 66px;
}
.cartImg img,
.imgBuyNow img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.cartText {
  font-size: 10px;
  text-transform: uppercase;
  font-weight: 600;
  text-align: center;
}
.activeContainer {
  border-color: var(--red);
}
.activeCheckBtn {
  border-color: var(--red);
}
@media only screen and (max-width: 768px) {
  .checkBtn {
    top: 13px;
    left: 14px;
  }
}

@media only screen and (max-width: 600px) {
  .checkBtn {
    top: 10px;
    left: 10px;
  }
}
@media only screen and (max-width: 430px) {
  .container {
    padding: 10px 9px 5px 9px;
    justify-content: center;
    row-gap: 7px;
  }
  .cartText {
    font-size: 9px;
  }
}
