.generalBanner {
}
.general_banner_img img {
  border-radius: 5px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.twoBanersBlck {
  gap: 20px;
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.twoBanersBlck a {
  width: 100%;
  height: 100%;
}
.LoadContentGeneralBanner {
  height: 450px;
}
.LoadContentTwoBanersBlck {
  height: 450px;
}
.twoBanersBlck img {
  border-radius: 5px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.infoAboutBrand {
  font-size: 18px;
  line-height: 30px;
  text-align: center;
  color: #898383;
  margin: 65px 0;
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.infoAboutBrand p {
  text-align: justify;
  font-size: 16px;
  font-family: arial, helvetica, sans-serif;
}
.secondTitle {
  font-size: 30px;
  text-align: center;
  max-width: 966px;
  font-weight: bold;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 40px;
}
.topPicksProduct {
  margin-bottom: 104px;
}
.bigBanner {
  margin-bottom: 50px;
}
.lastInfoBrand {
  background: #f7f7fb;
  border-radius: 5px;
  padding: 56px;
  color: #898383;
  margin: 62px 0;
  font-size: 18px;
  line-height: 150%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: auto;
  max-width: 100%;
}
.lastInfoBrand element div div {
  width: auto !important;
  max-width: 100% !important;
  height: auto !important;
}
@media only screen and (max-width: 1440px) {
  .promotionCategoryBlock {
    justify-content: flex-start;
  }
  .categoryBlockTitle {
    justify-content: flex-start;
  }
  .LoadContentGeneralBanner {
    height: 350px;
  }
  .LoadContentTwoBanersBlck {
    height: 350px;
  }
}
@media only screen and (max-width: 1024px) {
  .promotionCategoryBlock {
    margin-bottom: 20px;
  }
  .topPicksProduct {
    margin-bottom: 40px;
  }
  .lastInfoBrand {
    padding: 40px 44px;
  }
  .LoadContentGeneralBanner {
    height: 300px;
  }
  .LoadContentTwoBanersBlck {
    height: 300px;
  }
}
@media only screen and (max-width: 768px) {
  .discoverBrands {
    gap: 14px;
  }
  .secondTitle {
    font-size: 18px;
    margin-bottom: 20px;
  }
  .lastInfoBrand {
    padding: 30px 42px;
  }
  .LoadContentGeneralBanner {
    height: 96px !important;
  }
  .LoadContentTwoBanersBlck {
    height: 96px !important;
  }
}
