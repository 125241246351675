.main {
  display: flex;
  flex-direction: row;
  column-gap: 25px;
  flex-wrap: wrap;
}
.titleBlock {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.moreBtn {
  font-size: 16px;
  cursor: pointer;
  text-transform: uppercase;
  text-decoration: underline;
}
.titleBlock .moreBtn:hover {
  color: var(--red);
  text-decoration: none;
}
.blogItem {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  max-width: 336px;
  width: 23%;
  cursor: pointer;
  flex-grow: 4;
}
.blogItemImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 6px;
}
.blogItemTitle {
  font-size: 18px;
  min-height: 48px;
  font-weight: bold;
}
.blogItemTitle:hover {
  color: var(--red);
}
.blogItemDate {
  color: #636363;
  font-size: 16px;
}
.loadContentImg {
  width: 100%;
  height: auto;
  aspect-ratio: 10 / 3.3;
}
.loadContentTitle {
  width: 100%;
  height: 48px;
}
.loadContentDate {
  width: 100%;
  height: 16px;
}
@media (max-width: 1300px) {
  .blogItemDate {
    display: none;
  }
  .loadContentDate {
    display: none;
  }
}

@media only screen and (max-width: 1024px) {
  .blogItem {
    width: 30%;
    flex-grow: 3;
  }
  .blogItem:nth-child(4) {
    display: none;
  }
}
@media only screen and (max-width: 768px) {
  .moreBtn {
    font-size: 14px;
  }
}

@media only screen and (max-width: 600px) {
  .blogItem {
    width: 100%;
    min-width: 100%;
    /* min-width: fit-content; */
  }
  .blogItem:nth-child(4) {
    display: flex;
  }
  .main {
    overflow-x: scroll;
    overflow-y: hidden;
    flex-wrap: nowrap;
    padding-bottom: 20px;
  }
  .main::-webkit-scrollbar {
    height: 2px;
    width: 2px;
  }

  .main::-webkit-scrollbar-thumb {
    background-color: var(--red);
    border-radius: 10px;
    background-clip: padding-box;
    transition: all 0.3s ease-out;
  }

  /* .blogItem:nth-child(2),
  .blogItem:nth-child(3) {
    display: none;
  } */
}
