.title {
  font-size: 30px;
  font-weight: bold;
  text-transform: uppercase;
  margin: 20px 0;
}

.stories__list {
  padding: 0 !important;
}

.stories__wrapper {
  width: 100%;
  justify-content: flex-start;
  align-items: start;
  padding: 0 !important;
}

.stories__wrapper .swiper-slide {
  background: #fff;
  width: 114px !important;
  max-width: 114px;
  height: auto;

  & div {
    border: 3px solid #f9f9f9;
    border-radius: 50%;
    padding: 9px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    /* width: 114px !important; */
    /* height: 114px !important; */

    &:hover {
      border-color: #e31335;
    }
  }
  & p {
    text-align: center;
  }
}

.stories__wrapper .swiper-slide img,
.stories__wrapper .swiper-slide video {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.StoryLoader {
  min-width: 114px !important;
  height: 114px !important;
  border-radius: 50% !important;
  aspect-ratio: 1 / 1;
}

.stories__wrapper .swiper-button-next {
  right: var(--swiper-navigation-sides-offset, -19px);
}

.stories__wrapper .swiper-button-prev {
  left: var(--swiper-navigation-sides-offset, -19px);
}

.stories__wrapper .swiper-button-prev,
.stories__wrapper .swiper-button-next {
  background: white;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  box-shadow: 0 0 6px #807e7e;
  cursor: pointer;

  &::after {
    --swiper-navigation-size: 22px;
    color: black;
    font-weight: bold;
  }
}

.scrollable-list .story-item {
  min-width: 80px;
  height: 80px;
  border-radius: 50%;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.scrollable-list .story-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.StoriesTitle {
  font-size: 13px;
  text-align: center;
}

@media screen and (max-width: 430px) {
  .stories__wrapper .swiper-slide {
    width: 80px !important;
    /* height: 80px !important; */
  }

  .StoryLoader {
    min-width: 80px !important;
    height: 80px !important;
  }

  .title {
    font-size: 18px !important;
  }

  .stories__wrapper .swiper-slide {
    & .StoriesTitle {
      text-align: center;
      font-size: 8px;
    }
  }
}
