.sales_block_svg_title {
  justify-content: flex-start;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 14px;
  margin-top: 30px;
  margin-bottom: 46px;
}

.sales_block_svg_title h3 {
  color: var(--red);
  opacity: 0.8;
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
}

.sales_block_svg_title span {
  padding: 4px 16px;
  color: white;
  font-weight: bold;
  border-radius: 90px;
  font-size: 18px;
}

@media (max-width: 1024px) {
  .sales_block_svg_title {
    gap: 10px;
    margin-top: 20px;
    margin-bottom: 26px;
  }
}
@media (max-width: 600px) {
  .sales_block_svg_title h3 {
    font-size: 14px;
  }
  .sales_block_svg_title span {
    font-size: 14px;
    padding: 4px 10px;
  }
}
@media (max-width: 490px) {
  .sales_block_svg_title span {
    font-size: 14px;
    padding: 4px 0;
  }
}
