.aboutUsBlock {
  display: flex;
  flex-direction: column;
  gap: 26px;
}
.creditBanner {
  width: 100%;
}
.creditBannerImg {
  width: 100%;
}
element {
  line-height: 30px;
}
.aboutDescription {
  font-size: 18px;
  line-height: 30px;
  color: #898383;
  text-align: justify;
}
.aboutDescription > p {
  font-weight: 400;
  color: #687083;
  font-size: 13px;
  text-align: left;
}
.brendsBlock {
  display: flex;
  gap: 15px;
  justify-content: flex-start;
  margin: 44px 0;
  flex-wrap: wrap;
}
.brend {
  width: calc(100% / 8 - 15px);
  max-width: 166px;
  height: 70px;
  background: #ffffff;
  border: 1px solid #eeeeee;
  border-radius: 5px;
  padding: 15px 28px;
  cursor: pointer;
}
.brend:hover {
  border-color: #d10000;
  transition: 0.3s;
}
.banner {
  object-fit: contain;
  width: 100%;
  height: 100%;
  vertical-align: middle;
}
.moreBtn {
  width: calc(100% / 8 - 15px);
  max-width: 166px;
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background-color: #d10000;
}
.moreBtn > element {
  color: #ffffff;
}
.moreBtn:hover {
  background-color: #980a21;
  transition: 0.3s;
}
.aboutSecondBanner {
  background: #f7f7fb;
  gap: 20px;
  padding: 0 72px 52px 72px;
  margin: 70px 0 52px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.secondBannerDescrip span {
  max-width: 676px;
  color: #687083;
  font-size: 13px;
  text-align: justify;
  line-height: 24px;
}
.aboutDescriptionRight {
  max-width: 530px;
  width: 100%;
  margin-top: -26px;
}
.aboutSecondBanner img {
  object-fit: contain;
  width: 100%;
  height: 100%;
  vertical-align: middle;
}
.aboutDescriptionSecond {
  font-size: 13px;
  text-align: justify;
  line-height: 30px;
}
.aboutDescriptionSecond > span {
  color: #687083;
}
.teamMembersBlock {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #f7f7fb;
  border-radius: 5px;
  gap: 20px;
  margin: 50px 0;
  padding: 66px 56px 32px 140px;
}
.teamMemberAbout {
  max-width: 430px;
  display: flex;
  flex-direction: column;
  row-gap: 24px;
}
.teamMembersBlockTitle element {
  font-size: 40px;
  line-height: 100%;
  font-style: italic;
  text-transform: uppercase;
  /* font-weight: 600;
  font-style: italic; */
  font-family: "Mardoto-Regular";
  font-weight: bold;
}
.moreAboutMembers {
  margin-top: 40px;
  padding: 14px 40px;
  border-radius: 5px;
  color: var(--white);
  font-size: 16px;
  width: max-content;
  cursor: pointer;
  background-color: #d10000;
}
.moreAboutMembers > element {
  color: #ffffff;
}
.moreAboutMembers:hover {
  background-color: #980a21;
  transition: 0.3s;
}
.moreAboutMembersImg {
  max-width: 440px;
  width: 100%;
}
.moreAboutMembersImg img {
  width: 100%;
}
.banksAbout {
  display: flex;
  align-items: center;
  gap: 20px;
  margin: 26px 0 60px 0;
  flex-wrap: wrap;
}
.banksAboutItem {
  width: calc(100% / 6 - 20px);
  min-width: 220px;
  height: 118px;
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  border-radius: 10.5908px;
  padding: 30px 0;
  cursor: pointer;
  border: 1px solid transparent;
}
.banksAboutItem:hover {
  border-color: #d10000;
  transition: 0.3s;
}
.banksAboutItem > img {
  object-fit: contain !important;
  width: 100%;
  height: 100%;
  max-width: 225px;
  padding: 0 10px;
  box-sizing: border-box;
}
.LoadContentGeneralBanner {
  height: 450px;
}
.LoadContentdescript {
  height: 250px;
}
.LoadContentBrend {
  min-width: calc(100% / 8 - 15px);
  max-width: 166px;
  height: 70px;
}
.LoadContentaboutDescriptionRight {
  height: 350px;
}
@media (max-width: 1280px) {
  .aboutSecondBanner {
    gap: 45px;
    padding: 62px 56px 50px 56px;
    flex-direction: column;
  }
  .secondBannerDescrip {
    max-width: none;
  }
  .aboutDescriptionRight {
    margin: 0;
  }
  .teamMembersBlock {
    margin: 30px 0;
    gap: 14px;
  }
  .LoadContentGeneralBanner {
    height: 300px;
  }
}
@media (max-width: 1024px) {
  .brend {
    min-width: calc(100% / 6 - 13px);
    height: 62px;
    padding: 15px 17px;
  }
  .LoadContentBrend {
    min-width: calc(100% / 6 - 13px);
    max-width: 166px;
  }
  .moreBtn {
    min-width: calc(100% / 6 - 13px);
    /* padding: 21px 74px; */
  }
  .aboutDescription {
    font-size: 16px;
  }
  .teamMembersBlock {
    padding: 36px 14px 36px 30px;
  }
  .brend:nth-child(6) {
    display: none;
  }
  .brend:nth-child(7) {
    display: none;
  }
  .LoadContentGeneralBanner {
    height: 200px;
  }
}
@media (max-width: 768px) {
  .brend {
    padding: 13px 15px;
    width: calc(100% / 4 - 13px);
  }
  .LoadContentBrend {
    padding: 13px 15px;
    width: calc(100% / 4 - 13px);
  }
  .brend:nth-child(4) {
    display: none;
  }
  .brend:nth-child(5) {
    display: none;
  }
  .moreBtn {
    width: calc(100% / 4 - 13px);
    max-width: 157px;
  }
  .teamMembersBlockTitle element {
    font-size: 29px;
  }
  /* .moreAboutMembers {
    padding: 10px 30px;
    font-size: 11px;
  } */
  .banksAbout {
    margin: 30px 0;
  }
  .moreAboutMembers {
    padding: 10px 30px;
  }
  .moreAboutMembers > element {
    font-size: 11px;
    line-height: 100%;
  }
  .teamMembersBlock {
    padding: 36px 14px 36px 30px;
  }
  .teamMemberAbout {
    row-gap: 16px;
  }
}
@media (max-width: 600px) {
  .brendsBlock {
    justify-content: center;
  }
  .banksAboutItem {
    width: 100%;
  }
  .brend {
    width: calc(100% / 2 - 20px);
    max-width: 180px;
  }
  .LoadContentBrend {
    width: calc(100% / 2 - 20px);
    max-width: 180px;
  }
  .moreBtn {
    width: calc(100% / 2 - 20px);
    max-width: 180px;
  }
  .LoadContentGeneralBanner {
    height: 96px !important;
  }

  .teamMembersBlockTitle > element {
    font-size: 16px;
    line-height: 16px;
  }
  .moreAboutMembers {
    padding: 6px 16px;
  }
  .moreAboutMembers > element {
    font-size: 6px;
  }
  .teamMemberAbout {
    max-width: 170px;
    row-gap: 8px;
  }
  .moreAboutMembers {
    padding: 6px 16px;
    font-size: 6px;
  }
  .moreAboutMembersImg {
    max-width: 170px;
    width: 100%;
  }
}
@media (max-width: 430px) {
  .banksAboutItem {
    width: 45%;
    min-width: calc(100% / 2 - 20px);
  }
}
