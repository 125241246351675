.container {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 9999999999;
  background-color: rgba(0, 0, 0, 0.5);
}
.main {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  overflow-y: scroll;
  overflow-x: hidden;
  row-gap: 0;
  height: 100%;
  background-color: var(--back-gray);
  min-width: 285px;
  max-width: 300px;
  padding: 32px 20px 32px 20px;
}
.filterHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 12px;
  font-size: 18px;
  text-transform: uppercase;
  padding: 0 18px;
}
.filterHeader span {
  margin-left: 12px;
  font-size: 18px;
  text-transform: uppercase;
}
.close {
  cursor: pointer;
}
