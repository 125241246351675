.cart_content_container {
    display: flex;
    position: relative;
    flex-direction: column;
}

.cart_checkout_container {
    margin-top: 46px;
    gap: 20px;
    margin-bottom: 50px;
}

.cart_gray_back {
    position: absolute;
    z-index: 1;
    height: 90%;
    width: 100%;
    top: 700px;
    max-height: 1310px;
    left: 0;
    right: 0;
    background-color: #f7f7fb;
}

/*CART lEFT BLOCK*/
.cart_left_block {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 40px;
}

.cart_products_list {
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    background-color: white;
}

.cart_products_item {
    padding: 10px 16px;
    column-gap: 20px;
    border-bottom: 1px solid #2828281a;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.products_item_img {
    max-width: 110px;
    width: 100%;
    height: 110px;
    position: relative;
}

.products_item_img .productImage {
    height: 85%;
    width: 100%;
    display: block;
    object-fit: contain;
    vertical-align: middle;
}
.sticker {
    position: absolute;
    top: 8px;
    left: -6px;
    display: flex;
    column-gap: 2px;
    align-items: center;
    width: 60px;
    object-fit: contain;
}

.products_item_info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 79%;
}

.item_info_title_count {
    max-width: 44%;
    width: 100%;
    column-gap: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.item_info_title {
    font-size: 18px;
    overflow: hidden;
    max-width: 154px;
}

.price_favorite_block {
    max-width: 46%;
    width: 100%;
    column-gap: 10px;
    display: flex;
}

.info_prise_block {
    flex-wrap: wrap;
    display: flex;
    align-items: center;
    column-gap: 8px;
    width: 100%;
    min-width: 150px;
}
.promoPriceContainer {
    display: flex;
    align-items: center;
    flex-direction: row;
    column-gap: 8px;
}
.price {
    font-size: 18px;
    font-weight: bold;
    text-wrap: nowrap;
}
.newPrice {
    font-size: 18px;
    font-weight: bold;
    color: var(--red);
    text-wrap: nowrap;
}
.oldPriceContainer {
    position: relative;
}
.oldPrice {
    font-size: 12px;
    color: #898383;
    text-wrap: nowrap;
}

.oldPriceContainer .line {
    width: 100%;
    position: absolute;
    border-bottom: 1px solid var(--red);
    transform: rotate(-6deg);
    left: 0;
    top: 10px;
}
.installingPriceBlock {
    width: 100%;
    display: flex;
    align-items: center;
}
.installingBtnBlock {
    position: relative;
    width: 40px;
    height: 18px;
    background: var(--black);
    border-radius: 2px;
    border: 2px solid var(--black);
    cursor: pointer;
}
.installingBtnBlockActive {
    background: var(--red);
    border: 2px solid var(--red);
}
.installingBtn {
    width: 16px;
    height: 14px;
    background: var(--white);
    border-radius: 2px;
}
.installingBtnBlockActive .installingBtn {
    transform: translate(20px);
}

.line_through {
    width: 100%;
    position: absolute;
    border-bottom: 1px solid var(--red);
    top: 9px;
    transform: rotate(-6deg);
    left: 0;
}

.item_favorite_block {
    border: 1px solid rgba(40, 40, 40, 0.2);
    border-radius: 5px;
    padding: 10px 12px;
    display: flex;
    align-items: center;
    column-gap: 12px;
}

.favorite_delete {
    cursor: pointer;
}

.favorite_right_bord {
    height: 18px;
    border-right: 1px solid rgba(40, 40, 40, 0.2);
}

/*checkout*/

.cart_right_block_check {
    margin-top: 120px;
    display: flex;
    flex-direction: column;
    border-radius: 15px;
    row-gap: 80px;
    width: 100%;
    transition: all 0ms;
    background-color: #f7f7fb;
    padding: 50px 42px 60px 42px;
    box-shadow: 0px 0px 8.4px 0px rgba(0, 0, 0, 0.25);
}
.cart_block {
    display: flex;
}
.check_promocode_block {
    width: 100%;
    border-radius: 15px;
    background: #f7f7fb;
    box-shadow: 0px 0px 8.4px 0px rgba(0, 0, 0, 0.25);
    padding: 35px;
    display: flex;
    flex-direction: column;
    row-gap: 8px;
}

.haveAre_you_promo {
    font-size: 16px;
    font-weight: bold;
    text-transform: uppercase;
    color: rgba(40, 40, 40, 0.4);
}

.check_only_memebers {
    margin-top: 10px;
    display: flex;
    position: relative;
}

.check_memebers_input {
    border: none;
    font-size: 14px;
    padding: 15px 100px 15px 24px;
    width: 100%;
}

.send_memb_email_btn {
    position: absolute;
    right: 8px;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 5px;
    font-size: 14px;
    padding: 9px 25px;
    border: 1px solid transparent;
    background-color: var(--red);
    color: white;
    cursor: pointer;
}
.send_memb_email_btn:hover {
    background-color: var(--dark-red);
}
.errorInput{
    border-color: var(--red);
}
.cart_checkout_block {
    width: 100%;
    padding-left: 86px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.cart_checkout_block_title {
    font-size: 30px;
    font-weight: bold;
    text-align: center;
    color: black;
    text-transform: uppercase;
}

.checkout_prouducts_count_block {
    display: flex;
    gap: 40px;
    justify-content: space-between;
    align-items: center;
}

.checkout_prouducts_count {
    min-width: max-content;
    font-size: 16px;
    color: rgba(40, 40, 40, 0.4);
}

.checkout_prouducts_price {
    min-width: max-content;
    font-size: 18px;
    font-weight: 600;
}
.inf_name {
    color: var(--red);
}
.checkout_prouducts_shiping {
    gap: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.round_transparent_left {
    position: absolute;
    min-width: 52px;
    width: 52px;
    height: 52px;
    min-height: 52px;
    border-radius: 90px;
    box-shadow: 0px -5px 4px rgb(0 0 0 / 25%);
    background-color: var(--white);
    right: -70px;
}

.round_transparent_right {
    position: absolute;
    min-width: 52px;
    width: 52px;
    height: 52px;
    min-height: 52px;
    border-radius: 90px;
    box-shadow: 0px -5px 4px rgb(0 0 0 / 25%);
    background-color: var(--white);
    left: -70px;
}

.dash_cart {
    width: 100%;

    /* gic */
    border-bottom: 3px dashed rgba(40, 40, 40, 0.1);
}

.additional_coment_section_area {
    width: 100%;
    padding: 12px 24px;
    font-size: 14px;
    background-color: var(--white);
    border-radius: 5px;
    margin-top: 10px;
    border: none;
    resize: none;
}

.general_price_block {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.order_complete_button_container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
}
.order_complete_button {
    padding: 20px 68px;
    border-radius: 5px;
    color: var(--white);
    cursor: pointer;
    font-size: 16px;
    border: none;
    background-color: var(--red);
}
.order_complete_button:hover {
    background-color: var(--dark-red);
    transition: background-color 0.3s ease;
}
.general_price_tools button:hover {
    background-color: var(--dark-red);
}

.general_price_title {
    font-size: 16px;
    color: rgba(40, 40, 40, 0.4);
}

.total_price_with_delivery {
    font-size: 24px;
}

.installationText {
    min-width: max-content;
    display: inline-block;
    font-size: 12px;
    color: var(--red);
}

.thanksModalBg {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.2);
    z-index: 9999999999999999;
    display: flex;
    justify-content: center;
    align-items: center;
}
.thanksModal {
    max-width: 530px;
    width: 100%;
    background-color: var(--white);
    padding: 40px 36px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    flex-direction: column;
    row-gap: 30px;
}
.thanksModalClose {
    align-self: flex-end;
    cursor: pointer;
}
.thanksModalImg {
    max-width: 135px;
    height: 146px;
}
.thanksModalTitle {
    font-size: 30px;
    font-weight: bold;
    color: black;
    text-transform: uppercase;
}
.thanksModalSubTitle {
    font-size: 14px;
    text-align: center;
    font-weight: 600;
    color: rgba(40, 40, 40, 0.6);
}
.thanksModalBtn {
    max-width: 374px;
    width: 100%;
    padding: 16px;
    display: flex;
    border-radius: 5px;
    align-self: center;
    justify-content: center;
    background-color: var(--red);
    cursor: pointer;
}
.thanksModalBtn span {
    color: var(--white);
    font-size: 14px;
}

/* gift */
.stickersBlock {
    position: absolute;
    top: 126px;
    right: 22px;
    display: flex;
    column-gap: 4px;
    align-items: center;
    justify-content: flex-end;
    width: max-content;
    z-index: 999;
}
.giftBox {
    display: flex;
    align-items: center;
    position: absolute;
    right: -40px;
    bottom: 30px;

    cursor: pointer;
    min-width: 30px;
    width: 30px;
    height: 30px;
    min-height: 30px;
    justify-content: center;
    background: radial-gradient(
        96.77% 96.77% at 93.33% 96.77%,
        #af001d 0.51%,
        #e31335 100%
    );
    box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
}
.giftBox:hover .giftHovered {
    display: flex;
}
.giftHovered {
    display: none;
    padding: 15px;
    bottom: 30px;
    right: -5px;
    gap: 5px;
    text-align: start;
    border-radius: 10px;
    position: absolute;
    max-width: 244px;
    width: max-content;
    font-family: "Mardoto-Regular";
    background-color: var(--white);
    box-shadow: 0px 4.82129px 48.2129px rgb(0 0 0 / 12%);
}
.giftHoveredMain {
    border: 1px solid #dfdfe2;
    border-radius: 2px;
    display: flex;
    cursor: pointer;
    flex-direction: column;
    row-gap: 14px;
    padding: 10px;
    min-width: 1px;
    width: 100%;
    align-items: flex-start;
}
.giftHovered:hover .giftHoveredMain {
    border-color: var(--red);
}
.giftHoveredImg {
    height: 40px;
    object-fit: contain;
    max-width: 60px;
}

@media (max-width: 1440px) {
    .check_promocode_block {
        padding: 20px;
    }
    .cart_checkout_block {
        padding-left: 20px;
    }
    .products_item_img {
        max-width: 100px;
        height: 100px;
    }
    .cart_left_block {
        width: 100%;
    }
}
@media only screen and (max-width: 1280px) {
    .cart_checkout_container {
        flex-direction: column;
        margin-top: 10px;
        row-gap: 22px;
    }

    .cart_left_block,
    .cart_right_block_check {
        width: 100%;
    }
}
@media only screen and (max-width: 992px) {
    .item_info_title_count {
        flex-direction: column;
        gap: 16px;
        align-items: flex-start;
    }
    .price_favorite_block {
        flex-direction: column;
        gap: 20px;
        align-items: flex-start;
    }
    .item_favorite_block {
        align-self: end;
    }
    .giftBox {
        right: 5px;
        bottom: 5px;
    }
    .cart_block {
        flex-direction: column;
        row-gap: 84px;
    }
    .cart_checkout_block {
        row-gap: 24px;
    }
    .cart_right_block_check {
        padding: 24px;
    }
}

@media (max-width: 600px) {
    .check_promocode_block {
        padding: 8px;
    }
    .item_info_title {
        max-width: 240px;
        font-size: 14px;
        height: 32px;
    }
    .price_favorite_block {
        max-width: none;
        position: relative;
    }

    .products_item_info {
        align-items: flex-start;
        flex-direction: column;
    }
    .newPrice,
    .price {
        font-size: 14px;
    }
    .oldPrice {
        font-size: 12px;
    }
    .cart_right_block_check {
        min-width: auto;
    }
}
@media (max-width: 768px) {
    .cart_checkout_block_title {
        font-size: 18px;
    }
    .checkout_prouducts_count_block {
        gap: 14px;
    }
    .checkout_prouducts_shiping {
        gap: 14px;
    }
}

@media (max-width: 591px) {
    .products_item_info {
        flex-direction: column;
    }
    .cart_checkout_block {
        padding: 0;
    }

    .item_info_title {
        font-size: 14px;
    }

    .newPrice,
    .price {
        font-size: 16px;
    }

    .oldPrice {
        font-size: 13px;
    }
    .oldPriceLine {
        width: 100%;
        position: absolute;
        border-bottom: 1px solid var(--red);
        top: 11px;
        transform: rotate(-6deg);
        left: 0;
    }

    .add_personal_form {
        padding: 20px;
    }
    .info_prise_block {
        flex-direction: row;
        flex-wrap: nowrap;
        margin-bottom: 10px;
    }
}
@media (max-width: 442px) {
    .checkout_prouducts_count {
        font-size: 10px;
    }
    .checkout_prouducts_price {
        font-size: 12px;
    }
    .inf_name {
        font-size: 10px;
    }
    .general_price_title {
        font-size: 14px;
    }
    .general_price textarea {
        padding: 10px 15px;
        font-size: 12px;
    }
    .general_price_tools button {
        padding: 10px;
        font-size: 12px;
    }
    .cart_products_item {
        /* padding: 10px 5px; */
    }
    .send_memb_email_btn {
        font-size: 12px;
        padding: 9px 17px;
    }

    .check_memebers_input {
        border: none;
        font-size: 14px;
        padding: 10px 50px 10px 15px;
        width: 100%;
    }
    .check_memebers_input,
    .additional_coment_section_area {
        padding-left: 8px;
        padding-right: 8px;
    }
}
@media (max-width: 415px) {
    .item_favorite_block {
        position: relative;
        bottom: 0;
    }
    .cart_right_block_check {
        row-gap: 40px;
    }
    .haveAre_you_promo {
        font-size: 14px;
    }
    .cart_block {
        row-gap: 40px;
    }
}
