.LoadContentGeneralBanner {
  height: 450px;
}
.banner {
  width: 100%;
}
.deliveryTypeBlck {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  margin: 56px 0 40px 0;
}
.deliveTypeName {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 136px;
  padding: 20px;
  font-size: 30px;
  text-transform: uppercase;
  font-weight: 600;
  border: 2px solid #d10000;
  border-radius: 5px;
  text-align: center;
  width: calc(100% / 3 - 20px);
}
.LoadContentDelivery {
  width: calc(100% / 3 - 20px);
  height: 136px;
}
element > div > span {
  color: #687083 !important;
}
.LoadContentText {
  height: 50px;
  width: 60%;
}

.deliveryStepsBlck {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin: 40px 0;
}

.second_title {
  font-size: 30px;
  font-weight: bold;
  color: black;
}
.LoadContentSecond_title {
  height: 40px;
  width: 200px;
}
.stepsofDeliv {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
.steps {
  height: 176px;
  background-color: #f7f7fb;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  width: calc(100% / 4 - 20px);
}
.LoadContentSteps {
  height: 176px;
  width: calc(100% / 4 - 20px);
}
.stepImg {
  width: 68px;
  height: 68px;
  padding: 18px;
  border-radius: 90px;
  background: #eaeff5;
  border: 1px solid rgba(40, 40, 40, 0.2);
}
.sepsTitle {
  font-size: 16px;
  text-align: center;
  font-weight: bold;
  max-width: 240px;
}
.terms {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 26px;
}
.term {
  display: flex;
  align-items: center;
  background: #f7f7fb;
  border-radius: 5px;
  padding: 20px 30px;
  justify-content: flex-start;
  gap: 20px;
  width: 100%;
}
.LoadContentTerm {
  height: 108px;
  width: 100%;
}
.termStep {
  display: flex;
  align-items: center;
  font-size: 23px;
  font-weight: bold;
  min-width: 68px;
  width: 68px;
  height: 68px;
  justify-content: center;
  background: #eaeff5;
  border-radius: 90px;
  border: 1px solid rgba(40, 40, 40, 0.2);
}
.termName {
  font-size: 16px;
  font-weight: bold;
}
.lastInfo {
  line-height: 26px;
}
.LoadContentLastInfo {
  height: 100px;
  width: 100%;
}
.LoadContentTitle {
  height: 50px;
}
@media only screen and (max-width: 1280px) {
  .deliveTypeName {
    font-size: 20px;
  }
}

@media only screen and (max-width: 1024px) {
  .deliveryTypeBlck {
    margin: 20px 0;
  }
  .deliveTypeName {
    height: 110px;
  }
  .LoadContentDelivery {
    height: 110px;
  }
  .deliveryStepsBlck {
    margin: 30px 0;
    gap: 20px;
  }
  .sepsTitle {
    font-size: 14px;
  }
  .terms {
    flex-direction: column;
    gap: 20px;
  }
  .LoadContentText {
    height: 70px;
  }
  .LoadContentLastInfo {
    height: 150px;
  }
}
@media only screen and (max-width: 768px) {
  .deliveryTypeBlck {
    gap: 18px;
  }
  .deliveTypeName {
    font-size: 18px;
    width: calc(100% / 3 - 12px);
  }
  .LoadContentDelivery {
    width: calc(100% / 3 - 12px);
  }
  .second_title {
    font-size: 18px;
    margin: 0;
  }
  .steps {
    height: 140px;
  }
  .LoadContentSteps {
    height: 140px;
  }
  .stepImg {
    width: 56px;
    height: 56px;
    padding: 14px;
  }
  .sepsTitle {
    font-size: 12px;
  }
  .lastInfo > div > p {
    font-size: 16px !important;
    line-height: 26px !important;
  }
  .LoadContentText {
    height: 90px;
  }
  .LoadContentLastInfo {
    height: 150px;
  }
}
@media only screen and (max-width: 600px) {
  .deliveryTypeBlck {
    flex-direction: column;
    gap: 16px;
  }
  .deliveTypeName {
    width: 100%;
    height: 80px;
  }
  .LoadContentDelivery {
    width: 100%;
    height: 80px;
  }
  .stepsofDeliv {
    flex-wrap: wrap;
  }
  .steps {
    width: calc(100% / 2 - 10px);
  }
  .LoadContentSteps {
    width: calc(100% / 2 - 10px);
  }
  .term {
    gap: 14px;
    padding: 20px 20px;
  }
  .LoadContentText {
    height: 110px;
  }
  .LoadContentLastInfo {
    height: 160px;
  }
}
@media only screen and (max-width: 430px) {
  .lastInfo > p {
    line-height: 26px !important;
  }
  .LoadContentText {
    height: 130px;
  }
}
