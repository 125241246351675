.footer {
  background-color: var(--back-gray);
  padding: 50px 0 32px 0;
  display: flex;
  flex-direction: column;
  row-gap: 24px;
}
.line {
  width: 100%;
  border-bottom: 1px solid rgba(40, 40, 40, 0.1);
}
.logo {
  max-width: 154px;
  width: 100%;
  object-fit: contain;
}
.menuContainer {
  display: flex;
  gap: 40px 20px;
  margin-top: 14px;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 0;
}
.menu {
  display: flex;
  flex-direction: column;
}
footer h5 {
  font-size: 18px;
  font-weight: 600;
}
.menu .titleBlock svg {
  display: none;
}
.menuList {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
}
.menuList a:first-child,
.menuList span:first-child,
.menuList p:first-child {
  padding-top: 24px;
}
.menuList span {
  max-width: 290px;
  font-size: 18px;
  color: rgba(40, 40, 40, 0.7);
  cursor: pointer;
}
.menuList span:hover {
  color: var(--red);
}
.menuText {
  max-width: 290px;
  font-size: 18px;
  color: rgba(40, 40, 40, 0.7);
}
.menuText a {
  cursor: pointer;
  text-decoration: underline;
  font-size: 18px;
  color: rgba(40, 40, 40, 0.7);
}
.menuText span:hover {
  color: var(--red);
}
.paymentTypes {
  max-width: 290px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}
.paymentTypes a {
  max-width: 66px;
  width: 100%;
  padding: 0 !important;
}
.paymentTypes a svg {
  width: 100%;
  padding: 6px;
  height: 30px;
  border-radius: 6px;
  cursor: pointer;
  border: 1px solid transparent;
  background-color: var(--white);
}
.paymentTypes a svg:hover {
  border: 1px solid var(--red);
}
.input {
  max-width: 324px;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  margin-top: 24px;
}
.input input {
  padding: 18px 135px 18px 30px;
  font-size: 16px;
  border: 1px solid rgba(40, 40, 40, 0.1);
  border-radius: 6px;
  width: 100%;
}
.input .inputBtn {
  border: 1px solid transparent;
  padding: 10px 25px;
  position: absolute;
  right: 10px;
  background-color: var(--red);
  cursor: pointer;
  border-radius: 5px;
}
.input .inputBtn span {
  color: #fff;
  font-size: 12px;
}
.socIconsContainer {
  display: flex;
  flex-direction: row;
  column-gap: 20px;
  margin-top: 16px;
}
.socIconsContainer a {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(40, 40, 40, 0.2);
  border-radius: 5px;
  width: 40px;
  height: 40px;
  padding: 6px;
  cursor: pointer;
}
.socIconsContainer a:hover {
  border-color: var(--red);
}
.socIconsContainer a:hover svg path {
  fill: var(--red);
}
.copyrightContainer {
  margin: 0 auto;
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}
.copyright {
  font-size: 18px;
  text-align: center;
}
/* .updateAtText {
  position: absolute;
  left: 50px;
  bottom: -10px;
} */
.inputError {
  border: 1px solid var(--red) !important;
}
@media only screen and (max-width: 1280px) {
  .menuContainer {
    flex-wrap: wrap;
  }
  .menu:last-child {
    width: 100%;
    flex-direction: row;
    gap: 32px;
  }
  .copyrightContainer {
    height: 60px;
  }
}
@media only screen and (max-width: 768px) {
  .menuContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .menu {
    width: 100%;
  }
  .titleBlock {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }
  .titleBlock:hover h5 {
    color: var(--red);
  }
  .titleBlock:hover svg path {
    stroke: var(--red);
  }
  .menu .titleBlock svg {
    display: flex;
    stroke-width: 3px;
  }
  .copyright {
    font-size: 16px;
  }
}
@media only screen and (max-width: 600px) {
  .menu:last-child {
    flex-wrap: wrap;
    gap: 16px;
    flex-direction: column;
  }
  .menu:last-child .input {
    width: 100%;
    max-width: none;
  }

  .updateAtText {
    left: 20px;
  }
}
@media only screen and (max-width: 430px) {
  .copyright {
    font-size: 12px;
  }
}
