.creditItem {
    width: calc(100% / 2 - 15px);
    background: #f7f7fb;
    border-radius: 5px;
    border: 1px solid transparent;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 48px;
    padding: 38px 50px;
}
.creditItem:hover {
    border-color: #d10000;
}
.redImgCredit{
    max-width: 88px;
    width: 100%;
    height: 88px;
}
.redCreditImage {
    object-fit: contain;
    width: 100%;
    height: 100%;
}
.creditInfoTitle {
    font-size: 18px;
    font-weight: bold;
}
@media (max-width: 1280px){
    .creditItem {
        padding: 38px 20px;
        gap: 20px;
    }
    .redImgCredit {
        max-width: 76px;
        height: 76px;
    }
    .creditInfoTitle {
        font-size: 16px;
    }
}
@media (max-width: 768px){
    .creditItem {
        width: 100%;
    }
    .creditInfoTitle {
        font-size: 16px;
    }
}
@media (max-width: 600px){
    .redImgCredit {
        max-width: 40px;
        height: 40px;
    }
}