.container_catalog_slides {
  display: flex;
  flex-direction: row;
  overflow: hidden;
  gap: 20px;
}
.catalog_home_slides {
  display: flex;
  flex-direction: column;
  gap: 36px;
}
.catalog_first_section {
  column-gap: 15px;
  margin-bottom: 108px;
  position: relative;
  margin-bottom: 108px;
}

.catalog_first_section_body {
  display: flex;
  gap: 20px;
  align-items: flex-start;
  padding: 15px 0 0;
}

.catalog_gray_back {
  width: 100%;
  height: 48%;
  background-color: #f7f7fb;
}

.catalog_left_bar {
  max-width: 352px;
  min-width: 300px;
  width: 100%;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  background-color: var(--white);
  display: flex;
  flex-direction: column;
  padding: 0 18px;
  height: max-content;
}

.catalog_home_slides {
  width: calc(100% - 352px);
}

.catalog_home_slides .swiper-slide a img {
  object-fit: cover;
}
.catalog_featured_products {
}
.sales_hits_block {
  padding-top: 30px;
  padding-bottom: 30px;
  background-color: #f7f7fb;
}
.catalog_slides_img img {
}
.loadContent {
  max-width: 352px;
  min-width: 300px;
  height: 500px;
}
@media (max-width: 1392px) {
  .catalog_left_bar {
    display: none;
  }
  .container_catalog_slides {
  }

  .catalog_home_slides {
    width: 100%;
  }
}
