.background {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.999);
  z-index: 999999999;
  display: flex;
  justify-content: center;
  align-items: center;
}
.main {
  border-radius: 5px;
  padding: 0 20px;
  background-color: var(--white);
  overflow: hidden;
  width: 100%;
  min-width: 500px;
  /* height: 80vh; */
}
.main div img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  background-color: #fff;
}

.closeBtn {
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
  z-index: 2;

  & svg {
    width: 25px;
    height: 25px;
  }

  &:hover {
    & svg {
      & path {
        stroke: var(--red);
      }
    }
  }
}
.miniImgs {
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 900px;
  overflow: auto;
  justify-content: flex-start;
}
.miniImg {
  width: 122px;
  height: 122px;
  cursor: pointer;
  border: 1px solid transparent;
  margin-right: 20px;
}
.miniImg img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.miniImgActive {
  border-color: var(--red);
}
.zoomContainer {
  position: relative;
}
.zoomContainer div img {
  max-width: 500px;
  max-height: 300px;
  margin: 0 auto;
}
.zoomContainer div div {
  position: absolute !important;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  bottom: 0 !important;
  width: 100% !important;
  height: 100% !important;
  padding: 100px !important;
  background-color: #fff !important;
  overflow: hidden !important;
}
.zoomContainer div div img {
  max-width: none !important;
  max-height: none !important;
  height: 500px !important;
}

.imgBlock div div img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  background-color: #fff;
  max-height: 300px;
}

.zoomContainer .slideBtn {
  z-index: 20;
  position: absolute;
  width: 50px;
  height: 50px;
  box-shadow: 0px -1px 16px rgb(0 0 0 / 12%);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 90px;
  background-color: #fff;
  cursor: pointer !important;
  top: calc(50% - 25px);
}
.slidePrevArrow {
  left: 5px;
  transform: rotate(180deg);
}
.slideNextArrow {
  right: 5px;
}
.slideBtn:hover svg path {
  stroke: var(--red);
}
@media only screen and (max-width: 768px) {
  .miniImg {
    width: 104px;
    min-width: 104px;
    height: 104px;
  }
}

@media only screen and (max-width: 600px) {
  .main {
    min-width: 400px;
  }
  .miniImgs {
    gap: 12px;
  }
  .miniImg {
    width: 73px;
    min-width: 73px;
    height: 73px;
  }
  .zoomContainer .slideBtn {
    width: 40px;
    height: 40px;
  }
  .zoomContainer .slideBtn svg {
    height: 18px;
  }
}
@media only screen and (max-width: 430px) {
  .main {
    width: 90%;
    min-width: auto;
  }
}
