.small_categores_item {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  background: #f6f8f6;
  padding: 46px 38px 0 38px;
  max-width: none;
  height: 310px;
}
.mini_ban_img {
  height: 300px;
}
.mini_ban_img img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
@media (max-width: 1280px) {
  .mini_ban_img {
    height: 136px;
  }
}

@media (max-width: 1284px) {
  .small_categores_item {
    height: 207px;
    padding: 18px 18px 0 18px;
  }
}
@media (max-width: 768px) {
  .small_categores_item {
    height: 150px;
    padding: 12px 12px 0 12px;
  }
  .mini_ban_img {
    height: 100px;
  }
  .small_categores_item span {
    font-size: 12px;
  }
}
@media (max-width: 600px) {
  .small_categores_item {
    width: 100%;
    height: 100%;
  }
}
@media (max-width: 480px) {
  .mini_ban_img {
    height: 80px;
  }
}
@media (max-width: 420px) {
  .mini_ban_img {
    height: 70px;
  }
}

/* @media (max-width: 440px) {
  .mini_ban_img {
    height: 110px;
  }
  .small_categores_item {
    width: 100%;
    height: 140px;
    justify-content: initial;
  }
} */
