.general_credit_banner img{
      max-height: 100%;
    max-width: 100%;
}
.service_description{
     margin-top: 26px;
}
.agreement_block_top{
  margin-top: 26px;
}
.agreement_block{
    display: flex;
    flex-direction: column;
    gap: 24px;
    margin: 42px 0;
    height: 280px;
    padding-right: 82px;
    overflow: auto;
}
@media only screen and (max-width: 1024px){
    .agreement_block {
        padding: 14px;
        height: auto;
    }
}

.agreement_block_loader{

}
.agreement_block_loader_item{
   margin: 20px 0 0 ;
}
.agreement_block p div{
    font-size: 18px;
    line-height: 28px !important;
    margin-bottom: 14px;
    color: rgba(40, 40, 40, 0.7) !important;
}

.agreement_block::-webkit-scrollbar {
    height: 4px;
    width: 4px;
}
.agreement_block::-webkit-scrollbar-thumb {
    background-color: var(--red);

}


/*service_list  start*/
.service_list_title h1{
     color: #00124e;
    margin-top: 42px;
}

.not_free_service {
    display: flex;
    justify-content: center;
    gap: 26px 20px;
    flex-wrap: wrap;
    align-items: flex-start;
    margin: 42px 0 50px 0;
}

.service_item {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    text-align: center;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    width: calc(100% / 7 - 20px);

}

.not_free_service_loading_item{
    align-items: center;
    justify-content: center;
    height: 200px;
    width: calc(100% / 7 - 20px);

}

.service_square,.service_square_active {
    height: 164px;
    border-radius: 5px;
    display: flex;
    padding: 32px 44px;
    border: 1px solid transparent;
    justify-content: center;
    background-color:#f7f7fb;
    width: 100%;

}
.service_square:hover{
    border-color: var(--red);

}
.service_square_active{
    border-color: var(--red);
}
.service_square img{
    height: 100%;
    width: 100%;
    object-fit: contain;
}
@media (max-width: 768px){
    .service_list_title h1{
        font-size: 24px;
    }
}
@media (max-width: 600px){
    .service_list_title h1{
        font-size: 20px;
    }
}
@media (max-width: 1280px){
    .service_list_title h1{
        font-size: 20px;
    }
    .service_item {
        width: calc(100% / 5 - 20px);

    }
    .not_free_service_loading_item{

        width: calc(100% / 5 - 20px);

    }


}
@media (max-width: 1204px){
    .service_item {
        width: calc(100% / 4 - 20px);
    }
    .not_free_service_loading_item{

        width: calc(100% / 4 - 20px);

    }

    .service_square,.service_square_active {
        height: 200px;
        padding: 35px 48px;

    }
}
@media (max-width: 768px){
    .service_item {
        width: calc(100% / 3 - 20px);
    }
    .not_free_service_loading_item{

        width: calc(100% / 3 - 20px);

    }


}
@media (max-width: 600px){
    .service_item {
        width: calc(100% / 2 - 20px);
    }
    .not_free_service_loading_item{

        width: calc(100% / 2 - 20px);

    }
    .service_square,.service_square_active {
        height: 150px;
        padding: 20px 32px;

    }

}
@media (max-width: 375px){

    .service_square,.service_square_active {
        height: 100px;

    }

}
/*service_list  END*/

/*info_of_banks_block  START*/

.info_of_banks_block{
    display: flex;
    border-radius: 5px;
    padding: 34px;
    gap: 16px;
    margin-bottom: 52px;
    justify-content: flex-start;
    align-items: flex-start;
    background-color: #f7f7fb;
}
.info_tables{
    width: 100%;
    display: flex;
    gap: 16px;
    flex-direction: column;
}

.service_tables{
    max-width: 898px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;
}
.bank_info_title{
    width: 100%;
    padding: 16px;
    background: #eeeef5;
    border-radius: 2px;
    justify-content: center;
    text-transform: uppercase;
    font-size: 18px;
    font-weight: bold;
}

.term_title_inf{
    padding: 8px 8px 8px 28px;
    min-height: 54px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: start;
     background: #eeeef5;
    border-radius: 2px;
    font-size: 16px;
    color: #898383;
}
.service_tables_price{
    max-width: 455px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 16px;

}
/*info_of_banks_block  END*/
.services_banner{
    height: 400px;
}


@media only screen and (max-width: 1280px){
    .info_of_banks_block {
      flex-wrap: wrap;
    }
    .service_tables_price{
        max-width: 100%;
    }
    .services_banner{
        height: 300px;
    }
}
@media only screen and (max-width: 600px){
    .info_of_banks_block {
        padding: 30px 14px;
    }
    .bank_info_title{
         font-size: 16px;
    }
    .term_title_inf{
         font-size: 12px;
        padding: 8px 8px 8px 14px;
    }
    .service_tables_price{
         gap: 10px;
    }
    .info_tables{
         gap: 10px;
    }
    .services_banner{
        height: 200px;
    }

}
@media only screen and (max-width: 400px){

    .services_banner{
        height: 100px;
    }

}
