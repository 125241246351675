.cart_payment_block {
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    background-color: white;
    padding: 80px 100px 74px 100px;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    margin-top: 40px;
}

.payment_title {
    font-size: 30px;
    font-weight: bold;
    color: black;
    text-transform: uppercase;
}

.payment_list {
    display: flex;
    flex-wrap: wrap;
    gap: 18px;
}
.payment_item::before,
.payment_item_active::before {
    content: "";
    position: absolute;
    left: 20px;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    top: 20px;
    background-color: #f7f7fb;
    transition: 0.3s;
}
.payment_item,
.payment_item_active {
    max-width: 273px;
    width: calc(100% / 3 - 20px);
    height: 136px;
    cursor: pointer;
    border: 2px solid #e5e5ee;
    border-radius: 5px;
    padding: 20px 18px 10px 18px;
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    row-gap: 14px;
}
.payment_item_active {
    border: 2px solid red;
}
.payment_item_active::before {
    background-color: var(--red);
}
.payment_item:hover {
    border: 2px solid red;
}
.payment_item.active {
}
.payment_item img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.payment_item_active img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.cart_img_types {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 10px;
}

.cart_imgsvg {
    height: 50px;
    max-width: 60px;
}
.credit_imgsvg {
    max-width: 150px;
    height: 84px;
}
.nameofcart_type {
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 600;
    text-align: center;
}

.paymentBackBtn {
    cursor: pointer;
    width: 54px;
    height: 54px;
    background-color: var(--back-gray);
    justify-content: center;
    border-radius: 6px;
    border: 1px solid transparent;
    display: flex;
    justify-content: center;
    align-items: center;
}
.infoBlock {
    width: 100%;
    height: 280px;
    padding-right: 82px;
    overflow: auto;
    font-size: 18px;
    line-height: 28px;
    margin-bottom: 14px;
    color: rgba(40, 40, 40, 0.7);
}
.bankInput {
    width: 100%;
    padding: 12px 24px;
    font-size: 14px;
    background-color: var(--back-gray);
    border-radius: 5px;
    border: 1px solid transparent;
    margin-bottom: 18px;
}
.inputError {
    border-color: var(--red) !important;
}
@media (max-width: 1440px) {
    .payment_item {
        width: calc(100% / 3 - 14px);
    }
}
@media (max-width: 992px) {
    .cart_payment_block {
        padding: 24px;
    }
}
@media (max-width: 768px) {
    .payment_list {
        gap: 12px;
    }
    .payment_item {
        width: calc(100% / 3 - 8px);
        padding: 14px;
        height: 114px;
    }
    .cart_imgsvg {
        height: 30px;
        max-width: 40px;
    }
    .nameofcart_type {
        font-size: 10px;
    }
    .payment_title {
        font-size: 18px;
    }
    .infoBlock {
        height: 504px;
        padding: 14px;
    }
}

@media only screen and (max-width: 600px) {
    .payment_item {
        width: calc(100% / 2 - 8px);
    }
    .payment_list {
        gap: 14px;
    }
}
@media only screen and (max-width: 365px) {
    .payment_item {
        width: calc(100% / 1 - 8px);
    }
    .infoBlock {
        height: 504px;
        padding: 12px;
    }
}
@media only screen and (max-width: 591px) {
    .cart_payment_block {
        padding: 20px;
    }
}
