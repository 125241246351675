.loadContent {
  width: 100%;
  height: auto;
  max-width: 1440px;
  aspect-ratio: 10 / 2.6;
  margin: 0 auto;
}
.mySwiper {
  margin-bottom: 50px;
}

.mySwiper2 {
  margin-top: 40px;
  /* margin-bottom: 50px; */
}

.slideImg {
  display: flex;
  justify-content: center;
  width: 100%;
  object-fit: contain;
  margin-bottom: 40px;
}
.slideImg > img {
  max-width: 688px;
  width: 100%;
  object-fit: contain;
}

.thumbImg {
  height: 194px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid white;
}
.thumbImgActive {
  border: 1px solid red;
}
.thumbImg:hover {
  border: 1px solid red;
  transition: 0.3s;
}
.thumbImg > img {
  width: 100%;
  height: unset;
  vertical-align: middle;
  cursor: pointer;
  /* object-fit: cover; */
}
.slideBtn {
  z-index: 2;
  position: absolute;
  width: 50px;
  height: 50px;
  box-shadow: 0px -1px 16px rgb(0 0 0 / 12%);
  justify-content: center;
  align-items: center;
  border-radius: 90px;
  background-color: #fff;
  cursor: pointer;
  top: calc(50% - 25px);
}
.slidePrevArrow {
  left: 7%;
  top: 85px;
  transform: rotate(180deg);
}
.slideNextArrow {
  right: 7%;
  top: 85px;
}
.slideBtn:hover svg path {
  stroke: var(--red);
}
.slideImg {
  width: 100%;
  max-height: 400px;
  object-fit: contain;
}
