.container {
    display: flex;
    flex-direction: column;
    row-gap: 14px;
    padding-bottom: 20px;
    width: 100%;
  }

  .titleBlock {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
  }
  .titleBlock .title {
    cursor: pointer;
    font-size: 12px;
    text-transform: uppercase;
    font-weight: bold;
  }
  .titleBlock:hover .title {
    color: var(--red);
  }
  .titleBlock:hover svg path {
    stroke: var(--red);
  }
  .arrow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    transform: rotate(0deg);
  }
  .dgropDownBlock {
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    height: fit-content;
    overflow: hidden;
  }
  .checkBox {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 17px;
    height: 17px;
    border: 1.5px solid rgba(40, 40, 40, 0.6);
    border-radius: 2px;
  }

  .eachItem {
    display: flex;
    column-gap: 10px;
    align-items: center;
    cursor: pointer;
  }
  .label {
    color: #7e7e7e;
    font-size: 14px;
  }
  .seeMore {
    font-size: 12px;
    cursor: pointer;
    text-decoration: underline;
    color: var(--red);
  }
  .icon {
    width: 24px;
    height: 24px;
    object-fit: contain;
  }
  .priceInputs {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    column-gap: 6px;
  }
  .priceInput {
    padding: 8px 22px 8px 12px;
    background-color: transparent;
    font-size: 14px;
    border-radius: 2px;
    border: 1px solid #28282833;
    width: 100%;
  }
  .inputsLine {
    width: 30px;
    height: 1px;
    background-color: var(--black);
    opacity: 0.2;
  }
  