.pageination{
    display: flex;
    align-items: center;
    column-gap: 14px;
    list-style: none;
}
.prevPageNextBntn {
    border-radius:50%;
    width: 42px;
    height: 42px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items:center;
    background-color:#f7f7fb;
}
.prevPageNextBntn:hover{
    background-color: #d10000;
}
.prevPageNextBntn:hover svg {
    fill: transparent;
}
.prevPageNextBntn:hover svg path{
    stroke: white
}
.pageItemTwo{
    padding: 10px 14px;
    height: 42px;
    font-size: 20px;
    font-weight: 500;
    cursor: pointer;
    justify-content: center;
    border: 1px solid rgba(40, 40, 40, 0.4);
    border-radius: 4px;
}
.pageItem{
    padding: 10px 14px;
    height: 42px;
    font-size: 20px;
    font-weight: 500;
    cursor: pointer;
    justify-content: center;
    border: 1px solid rgba(40, 40, 40, 0.4);
    border-radius: 4px;
}
.pageItem:hover{
    background-color: #d10000;
    border-color: transparent;
}
.pageItem:hover a {
    color:white;

}
.currentPage{
    background-color: #d10000;
    border-color: transparent;
}
.currentPage > a{
    color:white;
}

@media only screen and (max-width: 460px) {
    .prevPageNextBntn{
        width: 20px;
        height: 20px;
    }
    .prevPageNextBntn svg {
        width: 7px;
        height: 16px;
    }
    .pageItem,.pageItemTwo{
        font-size: 18px;
        padding: 6px 10px;
        height: 36px;
    }
     
    .pageination{
        column-gap: 10px;
    }
}

@media only screen and (max-width: 375px) {
    .prevPageNextBntn{
        width: 20px;
        height: 20px;
    }
    .prevPageNextBntn svg {
        width: 7px;
        height: 16px;
    }
    .pageItemTwo{
        font-size: 15px;
        padding: 9px 6px;
        height: 33px;
    }
    .pageItem{
        font-size: 15px;
        padding: 8px 9px;
        height: 33px;
    }
     
    .pageination{
        column-gap: 8px;
    }
}