.promotionsBlock {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  margin-bottom: 60px;
  row-gap: 20px;
  width: 100%;
}
.newsProdBlck {
  max-width: none;
  width: calc(100% / 4);
  min-width: 0;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  max-width: 336px;
}
.newsProdBlck:hover > .name {
  color: #980a21 !important;
}
.promImg {
  display: flex;
  justify-content: center;
  align-items: center;
}
.promImg img {
  max-width: 100%;
  max-height: 240px;
  width: fit-content;
  height: fit-content;
  object-fit: contain;
  border-radius: 5px;
}
.name {
  font-size: 18px;
  min-height: 46px;
  color: #d10000;
  /* display: flex; */
  text-align: center;
  font-weight: 700;
}

@media only screen and (max-width: 1024px) {
  .newsProdBlck {
    max-width: none;
    width: calc(100% / 2 - 14px);
  }
}
@media only screen and (max-width: 768px) {
  .promotionsBlock {
    gap: 10px;
  }
  .newsProdBlck {
    width: calc(100% / 2 - 25px);
  }
  .name {
    font-size: 12px;
  }
}
