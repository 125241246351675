.general_credit_banner img{
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.payment_block_list {
    margin: 60px 0 80px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 56px;
    background: #f7f7fb;
    border-radius: 5px;
}

.payments_block {
    gap: 24px;
    display: flex;
    width: 75%;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
}


.choose_payment_type {
    max-width: 273px;
    width: calc(100% / 3 - 20px);
    height: 136px;
    cursor: pointer;
    border-radius: 5px;
    padding: 20px 18px 10px 18px;
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 14px;
    background-color: var(--white);


}

.cart_img_types {
    display: flex;
    justify-content: center;
    column-gap: 10px;
}

.cart_imgSvg2 {
  max-height: 80px;
}

.cart_imgSvg2 img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}
.nameofcart_type{
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 600;
    text-align: center;
}
@media (max-width: 1280px){
    .choose_payment_type {
        width: calc(100% / 3 - 16px);
    }
    .nameofcart_type{
        font-size: 12px;

    }
    .payments_block{
         width: 90%;
    }
}
@media (max-width: 768px){
    .choose_payment_type {
        width: calc(100% / 2 - 10px);
        height: 154px;
        padding: 15px;
    }
    .nameofcart_type{
        font-size: 10px;

    }
    .payments_block{
         width: 100%;
        gap: 18px;
    }
}
@media (max-width: 600px){
    .choose_payment_type {
        width: calc(100% / 2 - 10px);
        height: 154px;
        padding: 15px;
    }
    .nameofcart_type{
        font-size: 10px;

    }
    .payments_block{
        width: 100%;
        gap: 18px;
    }
}
@media only screen and (max-width: 600px){
    .payment_block_list {
        padding: 30px 36px;
        margin: 20px 0 80px 0;
    }
    .choose_payment_type{
        height: 150px;
        padding: 0 15px;

    }
}
@media only screen and (max-width: 430px){

    .choose_payment_type{
       width: 100%;

    }
}
