.container {
  position: absolute;
  z-index: 40;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  pointer-events: none;
  overflow: hidden;
}
.leftTop {
  z-index: 40;
  position: absolute;
  top: calc(35% + -35px);
  left: -63px;
  width: 170px;
  height: 197px;
}
.leftBottom {
  z-index: 40;
  position: absolute;
  top: calc(35% + 160px);
  left: -2px;
  width: 72px;
  height: 74px;
}
.rightTop {
  z-index: 40;
  position: absolute;
  /* top: 50px; */
  top: calc(35% + 50px);
  right: -72px;
  width: 78px;
  height: 78px;
}
.rightBottom {
  z-index: 40;

  position: absolute;
  /* top: 166px; */
  top: calc(35% + 166px);
  right: -215px;
  width: 259px;
  height: 250px;
}
