.productsList {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 27.5px;
  justify-content: flex-start;
  padding-top: 20px;
}
.productsListFullWidth {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  width: 100%;
  padding-top: 20px;
}
.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-top: 30px;
  gap: 20px 10px;
  flex-wrap: wrap;
}
.recordsPerPageContainer {
  display: flex;
  align-items: center;
  column-gap: 10px;
  padding-bottom: 8px;
}
.recordsPerPageTitle {
  font-size: 16px;
  font-weight: bold;
  text-transform: uppercase;
  opacity: 0.4;
}
.pageRecordsCountContainer {
  position: relative;
}
.pageRecordsCount {
  font-size: 14px;
  cursor: pointer;
  display: flex;
  align-items: center;
  column-gap: 5px;
}
.pageRecordsCount svg {
  transform: rotate(180deg);
}
.pageRecordsDragable {
  position: absolute;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  padding-top: 10px;
  right: 0px;
  top: 20px;
  border: 1px solid #e0e0e0;
  z-index: 9;
}
.pageRecordsDragable span {
  color: #8e8e8e;
  line-height: 32px;
  font-size: 15px;
  padding: 5px 20px;
  cursor: pointer;
  font-weight: bold;
}
.pageRecordsDragable span:hover {
  color: var(--black);
}
.moreBtn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
  margin-top: 20px;
  background-color: var(--back-gray);
  border-radius: 5px;
  cursor: pointer;
  border: 1px solid transparent;
}
.moreBtn:hover {
  color: var(--red);
  border-color: var(--red);
}
.moreBtn span {
  font-size: 16px;
  text-transform: uppercase;
}
@media only screen and (max-width: 1280px) {
  .productsList {
    gap: 32px;
  }
}
@media only screen and (max-width: 1024px) {
  .productsList {
    gap: 15px;
  }
}
@media only screen and (max-width: 620px) {
  .productsList {
    gap: 8px;
  }
  .footer {
    justify-content: center;
  }
}

/* /////////////// */

.search_null_block_body {
  padding: 0 20px;
  margin: 40px 0 60px 0;
  justify-content: space-around;
  column-gap: 20px;
  background-color: #f7f7fb;
  display: flex;
  align-items: center;
}

.search_null_block_text {
  max-width: 458px;
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.search_null_block_text h1 {
  font-size: 40px;
}

.search_null_block_text button {
  border: 1px solid transparent;
  height: 35px;
  width: 100%;
  color: white;
  font-size: 12px;
  background-color: var(--red);
  cursor: pointer;
  border-radius: 5px;
}

.search_null_block_text button:hover {
  background-color: #980a21;
}

.search_null_block_image {
  height: 350px;
  margin: -20px 0;
}
.search_null_block_image img {
  object-fit: contain;
}

@media (max-width: 1280px) {
  .search_null_block_text {
    max-width: 445px;
  }
  .search_null_block_image {
    max-width: 308px;
  }
}

@media (max-width: 990px) {
  .search_null_block_text {
    max-width: 336px;
  }
  .search_null_block_image {
    max-width: 311px;
    height: 220px;
  }
  .search_null_block_text h1 {
    font-size: 30px;
  }
  .search_null_block_image img {
    width: 100%;
    height: 100%;
  }
}

@media (max-width: 782px) {
  .search_null_block_body {
    flex-direction: column;
  }
  .search_null_block_image {
    margin-top: 0;
    margin-bottom: -20px;
  }
  .search_null_block_image {
    height: 180px;
  }
  .search_null_block_text h1 {
    font-size: 20px;
    margin-top: 20px;
    text-align: center;
  }
}
