
.countdown_block{
    background-color: var(--white);
    padding: 14px 16px;
    border-radius: 10px;
    display: flex;
    align-items: center;
}

.count_name{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
    gap: 10px;

}
.countdown_win{
    background: #f7f7fb;
    color: black;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    padding: 10px;

}
.nameof_count{
    color: #282828;
    font-size: 10px;
    font-style: normal;
    font-weight: 400;
    border-radius: 10px;



}
.period{
    color: #282828;
    font-size: 19.636px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    text-transform: uppercase;
}

@media (max-width: 700px) {
  .countdown_block{
       margin-top: 20px;
  }



}


