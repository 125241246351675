.products {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 52px;
}
.variationsContainer {
  min-width: 100%;
  width: max-content;
  padding-bottom: 30px;
  display: flex;
  flex-direction: column;
  gap: 14px;
  margin-top: 44px;
}
.variationTitle {
  font-size: 18px;
  text-transform: uppercase;
  font-family: "Mardoto-Bold";
}
.variationValues {
  display: flex;
  padding: 18px 20px;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: var(--back-gray);
  gap: 52px;
  min-width: 100%;
  width: max-content;
}
.variationValues span {
  font-size: 14px;
  min-width: 218px;
  width: 218px;
}
