.container {
  position: absolute;
  top: 65px;
  width: 100%;
  left: 0;
  background: var(--white);
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  padding: 2px 0; 
  z-index: 13;
  box-shadow: 0px -1px 16px rgb(0 0 0 / 12%);
  height: auto;
  max-height: 400px;
  z-index: 99999999;
}
.wsButton{
  border: none;
  padding: 15px;
  margin-right: 5px;
  margin-bottom: 5px;
  border-radius: 10px;
}
.wsCursor{
  cursor: pointer;
}
.noHistory{
  color: #7B7B7B;
  font-size: 12px;
}
.wsCursor:hover{
  color: #414141;
}
.wsUl{
  list-style: none; 
}
.wsUl li{
  margin-bottom: 5px;
}
.wsHr{
  margin: 15px 0;
  border-color: #D6D6D6;
  border: none;
  border-bottom: 1px solid #D6D6D6;
}
.wsDisplayNone{
  display: none;
}
.searchInput {
   width: 100%;
  transition: none;
  padding: 15px 65px 15px 20px;
  font-size: 14px; 
  border-radius: 6.29px;
  border: 0.97px solid #28282833;
  padding: 20px 65px 20px 20px;
}
.searchBtn {
  display: flex;
  top: 22px;
  align-items: center;
  position: absolute;
  right: 9px;
  font-size: 16px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  padding: 9px 29px;
  color: var(--white);
  background-color: var(--red);
}
.wsColorGray{
  color: #7B7B7B;
}
.wsMT50{
  margin-top: 50px;
}
.wsMb30{
  margin-bottom: 20px;
}
.wsImageX{
  width: 10px;

  margin:0 5px;
}
.wsImage{
  width: 15px; 
  margin:0 5px;
}
.wsflex{
  display: flex;
}
.wsJusBetween{ 
  justify-content: space-between;
}
.wsJusCenter{ 
  justify-content: center;
}
.wsAlignCenter{ 
  align-items: center;
}
.wsCont{ 
  padding: 10px 20px;
}
.modalBackground{
  position: fixed;  
    top: 0; 
    left: 0;  
    width: 100vw; 
    height: 100vh;  
    background-color: #00000059; 
    z-index: 999;  
}
.main {
  padding: 10px 0;
  overflow-y: auto;
  height: auto;
}
.main::-webkit-scrollbar {
  height: 2px;
  width: 3px;
}

.main::-webkit-scrollbar-thumb {
  background-color: var(--red);
  border-radius: 10px;
  background-clip: padding-box;
  transition: all 0.3s ease-out;
}

.searchedProduct {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 36px;
  column-gap: 20px;
  cursor: pointer;
}
.searchedProduct:hover {
  background-color: #fae6e9;
}
.searchedProdName {
  display: flex;
  align-items: center;
  column-gap: 20px;
}
.img {
  width: 56px;
  height: 56px;
  object-fit: contain;
}
.name {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  max-width: 160px;
  height: auto;
  overflow: hidden;
  font-size: 14px;
  font-weight: bold;
}
.price {
  font-size: 18px;
  font-weight: bold;
}
.promoPriceContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  flex-wrap: wrap;
  column-gap: 8px;
  width: 100%;
}
.newPrice {
  font-size: 18px;
  font-weight: bold;
  color: var(--red);
}
.oldPriceContainer {
  position: relative;
}
.oldPrice {
  font-size: 12px;
  color: #898383;
}
.oldPriceContainer .line {
  width: 100%;
  position: absolute;
  border-bottom: 1px solid var(--red);
  transform: rotate(-6deg);
  left: 0;
  top: 6px;
}
.searchedProdHover {
  display: none;
  position: absolute;
  top: 0;
  right: -218px;
}
.searchedProduct:hover .searchedProdHover {
  display: flex;
}
.prodSlide {
  max-width: 218px;
  width: 218px;
  /* height: 166px; */
  margin-top: 10px;
  background: #ffffff;
  border: 1px solid #eeeeee;
  border-radius: 5px;
  position: relative;
  padding: 30px 16px 16px 16px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.brand {
  max-width: 60px;
  position: absolute;
  top: 10px;
  z-index: 10;
  object-fit: contain;
  max-height: 30px;
}
.descContainer {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}
.descContainer a:hover .productName {
  text-decoration: underline;
}
.categoryName {
  text-transform: uppercase;
  color: rgba(40, 40, 40, 0.4);
  font-family: "Mardoto-Bold";
  width: 100%;
  font-size: 10px;
  margin-bottom: 5px;
  word-break: break-word;
}
.nameCodeProds {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  max-width: 220px;
}
.productName {
  width: 100%;
  max-width: 200px;
  font-size: 13px;
  overflow: hidden;
  line-height: 16px;
  height: 32px;
}
.codeOfProd {
  font-size: 14px;
  opacity: 0.6;
}
.price {
  font-size: 18px;
  font-weight: bold;
}
.promoPriceContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  flex-wrap: wrap;
  column-gap: 8px;
  width: 100%;
}
.newPrice {
  font-size: 18px;
  font-weight: bold;
  color: var(--red);
}
.oldPriceContainer {
  position: relative;
}
.oldPrice {
  font-size: 12px;
  color: #898383;
}
.oldPriceContainer .line {
  width: 100%;
  position: absolute;
  border-bottom: 1px solid var(--red);
  transform: rotate(-6deg);
  left: 0;
  top: 6px;
}
.priceMounth {
  width: max-content;
  font-size: 12px;
  color: var(--gray-bold);
  margin-top: 10px;
}
.btnsContainer {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  column-gap: 14px;
  justify-content: flex-start;
  align-items: center;
}
.cardBtn {
  padding: 15px 21px;
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: var(--red);
  border-radius: 5px;
  column-gap: 10px;
  color: var(--white);
  border: none;
}
.cardBtn:hover {
  background-color: var(--dark-red);
}
.cardBtnSvg {
  width: 15px;
  height: 15px;
}
.cardBtnSvg path {
  fill: #fff;
}
.cardBtn span {
  color: #fff;
  font-size: 12px;
}
.compareBtn:hover svg rect {
  fill: var(--red);
}
.heartSvg {
  cursor: pointer;
}
.heartSvg:hover path {
  fill: var(--red);
}

.cashbackContainer {
  display: flex;
  flex-direction: column;
}
.cashbackText {
  font-size: 14px;
  font-weight: bold;
}
.cashbackPrice {
  color: var(--red);
  font-size: 16px;
  font-weight: bold;
}
@media only screen and (max-width: 1280px) {
  .searchedProduct {
    padding: 15px 26px;
  }
  .searchedProdName {
    column-gap: 14px;
  }
}
@media only screen and (max-width: 992px) {
  .wsCont{
    padding: 0;
  }
  .container{
    max-height: none;
    height: 100vh;
    top: 15px;
  }
}

 
@media only screen and (max-width: 450px) {
  .wsCont{
    font-size: 14px;
  }
  .wsButton{
    padding: 10px;
  }
}

@media only screen and (max-width: 410px) {
  .wsCont{
    font-size: 10px;
  }
  .img{
    width: 50px;
    height: 50px;
  }
  .column-gap{
    gap: 10px;
  }
  .searchedProduct{
    padding: 15px;
  }
  .name{
    font-size: 12px;
  }
  .newPrice,.price{
    font-size: 16px;
  }
  .wsButton{
    padding: 10px;
  }
}

@media only screen and (max-width: 380px) { 
  .searchedProduct,.searchedProdName{
    column-gap: 10px;
  }
  .searchedProduct{
    padding: 15px 10px;
  }
  .nameCodeProds{
    max-width: 120px;
  }
}