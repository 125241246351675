.user_page {

}

.user_page_body {
    display: flex;
    gap: 18px;
    align-items: flex-start;
    margin: 90px 0 170px 0;

}

.addresses_content {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 26px;

}



.user_second_title {
    font-size: 30px;
    font-weight: bold;
    color: black;
    text-transform: uppercase;
}

.fill_show {
    font-size: 14px;
}

.user_loc_block {
    width: 100%;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    gap: 26px;
    padding: 32px 26px 26px 26px;
    background-color: #f7f7fb;
}

.addresses_title {
    display: flex;
    align-items: center;
    gap: 10px;
}
.inputError {
    border-color: var(--red) !important;
}

.addresses_title span {
    font-size: 30px;
    font-weight: bold;
    color: black;
    text-transform: uppercase;
}

.add_another_addr {
    padding: 10px 36px;
    color: var(--white);
    font-size: 14px;
    border-radius: 5px;
    width: max-content;
    cursor: pointer;
    background-color: var(--red);
    border: none;
}
.if_user_has_address_disabled{
      display: none;
}

.add_another_addr:hover {
    background-color: var(--dark-red);

}

.for_delivery {
    display: none;
}

.for_delivery_active {
    display: block;
}

.addresses_form {
    margin-top: 26px;
}

.add_personal_inputs_block {

}

.add_personal_inputs_name {
    gap: 20px;
    display: flex;
}

.add_personal_inputs {
    width: 100%;

}

.add_personal_inputs input {
    width: 100%;
    padding: 12px 24px;
    font-size: 14px;
    background-color: var(--white);
    border-radius: 5px;
    border: 1px solid transparent;
}

.add_personal_inputs_name {
    display: flex;
}

.add_personal_inputs_block {
    display: flex;
    flex-direction: column;
    gap: 26px;
}

.addresses_form_maps {
    height: 390px;
    margin-top: 26px;
    margin-bottom: 26px;

}

.save_fills_btn {
    padding: 20px 106px;
    border-radius: 5px;
    font-size: 16px;
    color: var(--white);
    border: none;
    cursor: pointer;
    background-color: var(--red);
    margin-top: 26px;
}
.edit_fills_btn{
    padding: 10px 36px;
    border-radius: 5px;
    font-size: 16px;
    color: var(--white);
    border: none;
    cursor: pointer;
    background-color: var(--red);
    margin-top: 26px;
}
.save_fills_btn:hover {
    background-color: var(--dark-red);

}
.edit_fills_btn:hover {
    background-color: var(--dark-red);

}


@media (max-width: 768px) {
    .user_page_body {
        flex-direction: column-reverse;
        margin: 50px 0 60px 0;
    }

    .addresses_title span {
        font-size: 18px;
    }
}


@media (max-width: 430px) {

    .fill_show {
        font-size: 10px;
    }

    .add_personal_inputs_name {
        flex-direction: column;
    }

    .save_fills_btn {
        padding: 20px 66px;
        border-radius: 5px;
        font-size: 16px;
        color: var(--white);
        border: none;
        cursor: pointer;
        background-color: var(--red);
        margin-top: 26px;
    }
}

