.filter{
    width:100%;
    overflow: auto;
    flex: 1 1 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    padding-top: 38px;
    overflow: auto;
}
.container{
    height: 100%;
    overflow: auto;
    overflow-x: hidden;
    padding: 0px 20px 0px 60px;
}
.switch{
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #2828281a;
  padding-bottom: 50px;
  margin-bottom: 50px;
}

.switch p{
    cursor: pointer;
    font-size: 22px;
    font-weight: 700;
    line-height: 26px;
    color:var(--black)
}
.filter div{
  margin-bottom: 20px;
}
@media(max-width:768px){
    .container{
        padding: 0px 20px 20px 20px;
    }
}
/* sort====================================================== */
.sort{
    width:100%;
    overflow: auto;
    flex: 1 1 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    /* padding-top: 38px; */
    overflow: hidden;
}
.sortingItem p{
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .sortingItem p span{
    font-size: 20px;
    font-weight: 400;
    line-height: 23px;
    color:var(--black)   
  }
  .checkboxWrapper span{
    cursor: pointer;
  } 
  .checkboxWrapper span:hover{
    color: var(--red);
 } 
/* manufactor======================================== */
.manufactur{

}

/* cost======================================== */
.colors{
    width: 100%;
    overflow: auto;
    display: grid;
    grid-template-columns: 1fr 1fr;
    padding-top: 20px;
}
.costContainer{
  /* padding-top: 40px; */
  border-bottom: 1px solid #2828281a;
}
.colorsItem span:hover{
  color: var(--red);
} 
.costContainer p{
  cursor: pointer;
  font-size: 22px;
  font-weight: 700;
  line-height: 26px;
  color:var(--black);
  margin-bottom: 15px;
}

.colorsItem{
    height: 38px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.colorsItemCircle{
    width: 38px;
    height: 38px;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 14px;
    border: 1px solid transparent;
    margin-bottom: unset!important;
}
.circle{
    display: inline-block;
    width: 29px;
    height: 29px;
    border-radius: 25px;
    cursor: pointer;
    margin-right: -1px;
    margin-bottom: -1px;
    border: 1px solid #2828281a;
}
.circleText{
    font-size: 18px;
    font-weight: 400;
    line-height: 21px;
    color: var(--black);
    cursor: pointer;
}
@media(min-width:768px){
  .colorsItemCircle:hover{
          border: 1px solid var(--red);
      }
}

/* color======================================== */
.color{

}
.active{
  border: 1px solid var(--red);
}
.circleTextActive{
  color: var(--red);
}

/* manufactor country======================================== */
.manufacturCoutry{

}
.brandImg{
  max-width: 100px;
  width: 100%;
  height: 30px;
  position: relative;
}
.brandImg img{
 position: absolute;
 width: 100%;
 height: 100%;
 object-fit: contain;
 top: 0px;
 left: 0px;
 bottom: 0px;
 right: 0px;
 cursor: pointer;
}


/* checkbox======================================== */


.checkboxWrapper input {
    padding: 0;
    height: initial;
    width: initial;
    margin-bottom: 0;
    display: none;
    cursor: pointer;
  }
  
  .checkboxWrapper label {
    position: relative;
    cursor: pointer;
    background: #fff;
    width: 4.4%;
    height: 5%;
  }
  
  .checkboxWrapper label:before {
    content: "";
    -webkit-appearance: none;
    background-color: transparent;
    border: 1px solid var(--black);
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05),
      inset 0px -15px 10px -12px rgba(0, 0, 0, 0.05);
    padding: 10px;
    display: inline-block;
    position: relative;
    vertical-align: middle;
    cursor: pointer;
    margin-right: 5px;
  }
  
  .checkboxWrapper input:checked + label:after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 8px;
    width: 6px;
    height: 14px;
    border: solid #fff;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    
  }
  .checkboxWrapper input:checked + label {
    background: var(--red);
    width: 4.4%;
    height: 5%;
    overflow: hidden;
  }
  
  @media(max-width:768px){
    .checkboxWrapper{
        padding: 0px 10px;
    }
    .checkboxWrapper label {
      width: 9.6%;
  
    }
    .checkboxWrapper input:checked + label {
      width: 9.6%;
    }
    .circleText{
      font-size: 14px;
    }  
    .costContainer p{
      font-size: 12px;
    }
    .sortingItem p span{
      font-size: 12px; 
    }
    .switch p{
      font-size: 12px;
  }
  }
  
  