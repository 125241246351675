.reactswitchcheckbox {
    height: 0;
    width: 0;
    visibility: hidden;
}

.reactswitchlabel {
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    width: 41px;
    height: 18px;
    background: var(--red);
    position: relative;
    transition: background-color 0.2s;
    border-radius: 2px;
}

.reactswitchlabel .reactswitchbutton {
    content: "";
    position: absolute;
    top: 2px;
    left: 23px;
    width: 16px;
    height: 14px;
    border-radius: 2px;
    transition: 0.2s;
    background: #fff;
    box-shadow: 0 0 2px 0 rgba(10, 10, 10, 0.29);
}

.reactswitchcheckbox:checked+.reactswitchlabel .reactswitchbutton {
    left: 2px;
    margin-right: 2px;
}
.reactswitchcheckbox:checked+.reactswitchlabel {
    background-color: #000;
}
.reactswitchlabel:active .reactswitchbutton {
    /* width: 60px; */
}
