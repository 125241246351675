.main {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: flex-start;
  flex-direction: column;
}
.sliderBlock {
  display: flex;
  justify-content: space-between;
  gap: 10px;
  align-items: flex-start;
  width: 100%;
}

.infoBlockParent {
  width: 562px;
}

.infoBlock {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
  background-color: var(--gray);
  border: 1px solid var(--grey-shingle);
  padding: 25px 28px 32px 28px;
  border-radius: 25px;
}

.infoBlockLoader {
  max-width: 562px;
  width: 100%;
  height: 600px;
}
.row1 {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 10px;
  width: 100%;
  flex-wrap: wrap;
}
.colors {
  width: 100%;
  padding-top: 20px;
  display: flex;
  align-items: stretch;

  /* border-top: 3px dashed rgba(40, 40, 40, 0.1); */
}
/* .colors:first-child {
  display: inline-block;
  padding-right: 23px;
  font-size: 14px;
  color: rgba(40, 40, 40, 0.4);
  font-weight: 700;
}
.colors :nth-child(2) {
  border: 2px solid #d10000;
} */
.colorCircleContainer {
  width: 38px;
  height: 38px;
  border: 2px solid transparent;
  border-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.colorCircleContainer:hover {
  border: 2px solid #d10000;
}
.colorCircleContainer:not(:last-child) {
  margin-right: 13px;
}
.colorCirlce {
  display: inline-block;
  width: 28px;
  height: 28px;
  border: 1px solid rgba(40, 40, 40, 0.4);
  border-radius: 20px;
  cursor: pointer;
}
.prodCategory {
  display: flex;
  align-items: center;
  column-gap: 20px;
  font-size: 14px;
  text-transform: uppercase;
  color: rgba(40, 40, 40, 0.4);
  font-weight: 500;
}
.shareBtns {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* column-gap: 10px; */
}

.compareHeart {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 76px;
  height: 38px;
  gap: 8px;
  padding: 10px;
  /* border: 1px solid rgba(40, 40, 40, 0.2); */
  border-radius: 5px;
}
.compareHeart div svg {
  width: 18px;
  height: 18px;
}
.compareHeart div:hover svg path,
.compareHeart div:hover svg rect {
  fill: var(--red) !important;
}
.compareHeart span {
  height: 24px;
  border-right: 1px solid rgba(40, 40, 40, 0.2);
}
.shareBtn {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 38px; */
  height: 38px;
  border-radius: 5px;
  cursor: pointer;
  /* border: 1px solid #28282833; */
}
.shareBtn .shareBtnSvg:hover svg path {
  fill: var(--red);
}
.shareDropDown {
  display: flex;
  position: absolute;
  top: 50px;
  width: max-content;
  right: -10px;
  z-index: 11;
  flex-direction: column;
  gap: 6px;
  padding: 10px;
  background: var(--white);
  box-shadow: 0px 6.42202px 16.055px rgba(0, 0, 0, 0.1);
  border-radius: 8.02752px;
}
.shareDropDown a {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(40, 40, 40, 0.2);
  border-radius: 5px;
  width: 40px;
  height: 40px;
  padding: 6px;
}
.shareDropDown a:hover {
  border-color: var(--red);
}
.shareDropDown a svg path {
  fill: var(--black);
}
.shareDropDown a:hover svg path {
  fill: var(--red);
}
.prodName {
  font-size: 20px;
  text-transform: uppercase;
  font-weight: 900;
  color: var(--black);
  max-width: 70%;
}
.skuContainer {
  display: flex;
  align-items: center;
  column-gap: 12px;
}
.skuAndStikcer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.nameBoldGray {
  font-size: 12px;
  text-transform: uppercase;
  color: rgba(40, 40, 40, 0.4);
  font-weight: 700;
  margin-right: 4px;
}
.black_sp {
  font-size: 14px;
  font-weight: bold;
}
.dashLine {
  width: 100%;
  border-bottom: 1px solid rgba(40, 40, 40, 0.1);
}
.brandLogo {
  height: 36px;
  max-width: 130px;
  object-fit: contain;
  object-position: left;
}

.GiftSvgProductPage {
  width: 25.29px;
  height: 25.29px;
}

.isAvailable {
  display: flex;
  align-items: center;
  column-gap: 12px;
  text-transform: uppercase;
  font-size: 14px;
  width: max-content;
  min-width: max-content;
  /* margin-right: 10px; */
  font-family: "Mardoto-Bold";
  font-weight: bold;
}
.guarantee {
  display: flex;
  flex-direction: column;
  width: fit-content;
}
.guarantee span {
  display: flex;
  align-items: center;
  font-size: 10px;
  width: 100%;
  color: var(--white);
  background: linear-gradient(180.24deg, #e31335 0.21%, #c10322 48.39%);
  border-radius: 4px 4px 0 0;
  justify-content: center;
  font-size: 8px;
  padding: 4px;
  text-transform: uppercase;
  text-align: center;
}
.guarantee span:last-child {
  border-radius: 0 0 4px 4px;
  font-size: 9px;
  font-weight: bold;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.1);
  color: var(--black);
  background: var(--white) !important;
}
.priceBlock {
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  column-gap: 8px;
  /* width: 100%; */
}
.newPrice {
  color: var(--red);
  font-size: 40px;
  font-weight: 700;
}
.oldPriceBlock {
  position: relative;
  width: max-content;
}
.oldPrice {
  font-size: 14px;
  color: var(--black);
  font-weight: 700;
}
.oldPriceLine {
  width: 100%;
  position: absolute;
  border-bottom: 1px solid var(--red);
  top: 9px;
  transform: rotate(10deg);
  left: 0;
}
.price {
  font-size: 35px;
  font-weight: bold;
}
.creditBlock {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  flex-wrap: wrap;
}

.priceDateColumnWrapper {
  display: flex;
  gap: 35px;
  justify-content: space-between;
}

.priceDateColumn {
  display: flex;
  flex-direction: column;
  gap: 6px;
  width: max-content;
  font-size: 12px;
  color: var(--grayBold);
}
.creditText {
  font-size: 14px;
  color: rgba(40, 40, 40, 0.7);
  text-transform: uppercase;
  font-weight: 700;
}
.creditPrice {
  font-size: 16px;
  font-weight: 700;
}
.creditLine {
  height: 24px;
  border-right: 1px solid rgba(40, 40, 40, 0.2);
}
.buyCreditBtn {
  padding: 17px 40px;
  border: 1px solid var(--red);
  border-radius: 10.08px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}
.buyCreditBtn span {
  font-size: 12px;
  color: var(--red);
}
.buyCreditBtn:hover {
  background-color: var(--red);
}
.buyCreditBtn:hover span {
  color: var(--white);
}
.countAddCartBuyNowBlock {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}

.countAndBuyNow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
}

.countBlock {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 5px;
}

.countBtn {
  display: flex;
  align-items: center;
  min-width: 50.58px;
  min-height: 50.58px;
  justify-content: center;
  cursor: pointer;
  border: 1px solid #28282833;
  border-radius: 7px;
  background: #ffffff;
}

.countBtn:hover svg path {
  stroke: var(--red);
}
.countInput {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  width: 54px;
  height: 38px;
  text-align: center;
  background-color: transparent;
  user-select: none;
}
.buyBtn {
  display: flex;
  align-items: center;
  padding: 18px 26px;
  width: max-content;
  min-width: max-content;
  border-radius: 10.12px;
  column-gap: 10px;
  cursor: pointer;
  background-color: var(--red);
  width: fit-content;
  justify-content: center;
  user-select: none;
  border: none;
}

.orderBtn {
  color: var(--white);
}
.buyBtn span {
  font-size: 16.19px;
  color: var(--white);
  font-weight: 500;
}
.buyBtn:hover {
  background-color: var(--dark-red);
}
.cardBtnSvg {
  width: 15px;
  height: 15px;
}
.cardBtnSvg path {
  fill: #fff;
}
.buyItNow {
  padding: 18px 20px;
  background: transparent;
  border: 1px solid var(--black);
  width: fit-content;
  font-size: 16.19px;
}
.buyItNow span {
  /* font-size: 12px; */
  color: var(--black);
}
.buyItNow:hover span {
  color: white;
}
.buyItNow:hover {
  border: 1px solid transparent;
}
.productInfoCredit {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
  gap: 10px;
  margin-top: 16px;
}
.productInfoCredit div {
  cursor: pointer;
}
.productInfoCredit span {
  font-size: 14px;
  text-transform: uppercase;
  color: rgba(40, 40, 40, 0.4);
  font-weight: bold;
  margin-left: 5px;
}
.productInfoCredit .nameBoldGray:hover {
  color: var(--red);
}
.productLeftGeneral {
  max-width: 828px;
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 25px;
  margin-bottom: 52px;
  margin-top: 50px;
}
.secondTitle {
  font-size: 30px;
  font-weight: bold;
  color: black;
  text-transform: uppercase;
}
.infoProd {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 14px;
  /* max-height: 236px; */
  overflow: hidden;
}
.infoRow {
  column-gap: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.infoRowKey {
  color: rgba(0, 0, 0, 0.4);
  min-width: max-content;
  font-size: 14px;
  font-weight: bold;
}
.infoRowValue {
  min-width: max-content;
  font-size: 14px;
}
.infoRowLine {
  width: 100%;
  border-bottom: 3px dashed rgba(40, 40, 40, 0.1);
}

.infoRowLoaderContainer {
  margin-top: 45px;
}

.infoRowLoader {
  height: 30px;
  margin-bottom: 20px;
}

.seeMoreVariation {
  font-size: 12px;
  cursor: pointer;
  text-decoration: underline;
  color: var(--red);
}

.timeForEndSale {
  font-size: 10px;
  font-weight: 400;
  text-align: right;
}

.cashback {
  font-size: 25px;
  font-weight: bold;
}
.cashback span:last-child {
  color: var(--red);
}

.title {
  font-size: 30px;
  font-weight: bold;
  text-transform: uppercase;
  margin: 20px 0;
}

.installingPriceBlock {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
}

.installingBtnBlock {
  position: relative;
  width: 42px;
  min-width: 42px;
  height: 21px;
  background: var(--black);
  border-radius: 10px;
  border: 2px solid var(--black);
  cursor: pointer;
}
.installingBtnBlockActive {
  background: var(--red);
  border: 2px solid var(--red);
}
.installingBtn {
  width: 14px;
  height: 14px;
  background: var(--white);
  border-radius: 50%;
  margin-top: 1px;
  /* transform: translate("0px"); */
}
.installingBtnBlockActive .installingBtn {
  transform: translate(23px);
}
.giftBlock {
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  gap: 16px;
}
.giftImg {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 10px;
  overflow: auto;
  padding-bottom: 5px;
  /* border: 1px solid var(--red); */
  border-radius: 2px;
  padding: 15px 10px;
  display: flex;
  min-width: max-content;
  cursor: pointer;
  flex-direction: column;
  row-gap: 14px;
  align-items: center;
  transition: 0ms !important;
}
.giftImgChoosen {
  outline: 1px solid var(--red);
}
.giftImg img {
  max-width: 88px;
  width: 100%;
  height: 76px;
  object-fit: contain;
}
.giftImg span {
  font-size: 12px;
  text-align: center;
  max-width: 98px;
  text-transform: uppercase;
}

.brandBlock {
  flex-direction: row;
  gap: 10px;
  align-items: flex-end !important;
}

.giftBox {
  display: flex;
  align-items: center;
  position: relative;
  /* cursor: pointer; */
  min-width: 30px;
  width: 55px;
  height: 55px;
  min-height: 30px;
  justify-content: center;
  background: radial-gradient(
    96.77% 96.77% at 93.33% 96.77%,
    #af001d 0.51%,
    #e31335 100%
  );
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}

.chooseTheGift {
  font-size: 12px;
  font-weight: 700;
  text-align: left;
  color: #282828b2;
  margin-bottom: 13px;
}

.giftWrapper {
  display: flex;
  align-items: stretch;
  gap: 20px;
}

.giftList {
  display: flex;
  gap: 10px;
  flex: 1 1;
  overflow: auto;

  &::-webkit-scrollbar {
    display: none;
  }
}

.giftBox:hover .giftHovered {
  display: flex;
}
.giftHovered {
  display: none;
  padding: 15px;
  bottom: 30px;
  left: -40px;
  gap: 5px;
  text-align: start;
  border-radius: 10px;
  position: absolute;
  max-width: 244px;
  width: max-content;
  font-family: "Mardoto-Regular";
  background-color: var(--white);
  box-shadow: 0px 4.82129px 48.2129px rgb(0 0 0 / 12%);
}

.giftHoveredMain {
  border: 1px solid #dfdfe2;
  border-radius: 2px;
  display: flex;
  cursor: pointer;
  flex-direction: column;
  row-gap: 14px;
  padding: 10px;
  min-width: 1px;
  width: 100%;
  align-items: flex-start;
}
.giftHovered:hover .giftHoveredMain {
  border-color: var(--red);
}
.giftHoveredImg {
  height: 40px;
  max-width: 60px;
  object-fit: contain;
}

.stickerImg {
  width: 75px;
  object-fit: contain;
  align-self: center;
}

.priceAverageBlock {
  width: 100%;
}

@media screen and (max-width: 1440px) {
  .productLeftGeneral {
    max-width: calc(100% - 598px);
  }
}

@media screen and (max-width: 1300px) {
  .infoBlockParent,
  .infoBlockLoader {
    max-width: 475px;
    min-width: 475px;
  }
  .productLeftGeneral {
    max-width: calc(100% - 500px);
  }

  .creditBlock {
    gap: 20px;
  }

  .priceDateColumnWrapper {
    gap: 15px;
  }

  .productInfoCredit span {
    font-size: 10px;
  }

  .countAddCartBuyNowBlock {
    flex-wrap: wrap;
  }

  .buyCreditBtn {
    padding: 17px 18px;
  }
  .buyBtn {
    padding: 17px 36px;
  }

  .buyItNow {
    width: 100%;
  }
}

@media screen and (max-width: 1024px) {
  .sliderBlock {
    gap: 10px;
    flex-direction: column;
  }

  .infoBlockParent,
  .infoBlockLoader {
    max-width: 100%;
    width: 100%;
    min-width: 0;
  }

  .countAddCartBuyNowBlock {
    flex-wrap: nowrap;
  }
  .buyItNow {
    width: fit-content;
  }

  .productInfoCredit span {
    font-size: 14px;
  }

  .productLeftGeneral {
    margin-top: 40px;
    max-width: none;
  }

  .prodName {
    font-size: 18px;
  }

  .priceDateColumnWrapper {
    width: fit-content;
  }

  .buyCreditBtn {
    padding: 17px 48px;
  }

  .buyBtn {
    padding: 18px 26px;
  }
}

@media only screen and (max-width: 768px) {
  .secondTitle {
    font-size: 18px;
    margin: 0;
  }
  .title {
    font-size: 18px;
  }
}

@media only screen and (max-width: 630px) {
  .brandLogo {
    max-width: 80px;
  }
  .compareHeart div svg,
  .shareBtn svg {
    max-width: 20px;
  }
  .prodName {
    font-size: 16px;
  }
  .priceAverageBlock {
    justify-content: space-between;
  }
  .priceBlock {
    gap: 4px;
  }
  .nameBoldGray {
    font-size: 11px;
  }
  .newPrice,
  .price {
    font-size: 24px;
  }

  .creditText {
    font-size: 9px;
  }
  .creditPrice {
    font-size: 12px;
  }
  .buyCreditBtn {
    padding: 11px 26px;
  }
  .buyCreditBtn span {
    font-size: 10px;
  }
  .countBtn {
    width: 39px;
    height: 39px;
    min-width: 39px;
    min-height: 39px;
  }
  .countInput {
    width: 32px;
    font-size: 14px;
  }
  .buyBtn {
    padding: 8px 26px;
  }
  .productInfoCredit {
    font-size: 11px;
  }
  .infoRowKey {
    font-size: 12px;
  }
  .infoRowValue {
    font-size: 11px;
  }
  .blackFridei {
    gap: 0;
    row-gap: 25px;
    padding: 8px;
  }
  .blackFridei .isAvailable {
    column-gap: 6px;
    margin-right: 5px;
  }
  .blackFridei .isAvailable span {
    font-size: 12px;
  }

  .productInfoCredit {
    gap: 10px;
  }
  .productInfoCredit span {
    font-size: 12px;
    margin: 0 0 0 5px;
  }
  .productInfoCredit svg {
    width: 13px;
    height: 13px;
  }
}

@media only screen and (max-width: 550px) {
  .buyItNow {
    width: 100%;
  }
  .countAddCartBuyNowBlock {
    flex-wrap: wrap;
  }
}
@media only screen and (max-width: 470px) {
  .blackFridei .priceAverageBlock {
    max-width: 70%;
  }
  .oldPriceContainer {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
  /* .priceDateColumnWrapper {
    width: 50%;
  } */
  .countInput {
    width: auto;
  }
  .productInfoCredit {
    gap: 8px;
  }
  .productInfoCredit span {
    font-size: 10px;
    margin: 0 0 0 5px;
  }
  .productInfoCredit svg {
    width: 13px;
    height: 13px;
  }
}

@media only screen and (max-width: 450px) {
  .countBtn {
    /* width: 100%; */
    /* height: 32px; */
    min-width: 28px;
    min-height: 28px;
  }
  .countBlock {
    width: 45%;
    justify-content: space-between;
  }

  .addCartBtn {
    margin: 0;
  }

  .countInput {
    width: fit-content;
    font-size: 14px;
  }
  .buyBtn {
    width: 47%;
    padding: 10px;
    /* font-size: 10px; */
    display: flex;
    justify-content: center;
  }
  .countAddCartBuyNowBlock {
    flex-wrap: wrap;
    gap: 10px;
  }
  .buyItNow {
    width: 100%;
  }
  .brandBlock {
    flex-direction: row;
    gap: 10px;
    align-items: flex-end !important;
  }

  .buyCreditBtn {
    padding: 10px;
  }
  .buyCreditBtn span {
    font-size: 9px;
  }
  /* .creditBlock {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  } */
  /* .creditBlock .creditLine {
    max-width: none;
    width: 100%;
    height: 1px;
    border-bottom: 3px dashed rgba(40, 40, 40, 0.1);
  } */
  .creditText {
    font-size: 10px;
  }
  .productInfoCredit {
    gap: 5px;
  }
  .productInfoCredit span {
    font-size: 7px;
    margin: 0 0 0 5px;
  }
  .productInfoCredit svg {
    width: 12px;
    height: 12px;
  }
  .infoBlock {
    padding: 25px 15px 32px 15px;
  }

  .priceDateColumnWrapper {
    width: fit-content;
    gap: 20px;
  }
  .creditBlock {
    gap: 20px;
  }
}
@media only screen and (max-width: 375px) {
  .infoRow {
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
  }
  .infoRow .infoRowLine {
    display: none;
  }
  .priceDateColumnWrapper {
    width: 100%;
    gap: 20px;
  }
  .buyCreditBtn {
    width: 100%;
  }
}
.creditPriceBlackFriday {
  position: relative;
}