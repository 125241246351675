
.modal_jobs_block {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999999;
}

.modal_jobs_bg {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: .6;
    left: 0;
    top: 0;
}

.modal_jobs_content {
    position: relative;
    max-width: 530px;
    width: 100%;
    background-color: white;
    padding: 40px 36px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    flex-direction: column;
    row-gap: 30px;

}

.modal_jobs_content_img {
    display: flex;
    align-items: center;
    justify-content: center;
}
.modal_jobs_content_title{
     display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 30px;

}
.modal_jobs_content_title span {
    font-size: 30px;
    font-weight: bold;
    color: black;
    text-transform: uppercase;
    display: block;
    text-align: center;
}

.modal_jobs_content_button {
    max-width: 374px;
    width: 100%;
    padding: 16px;
    color: var(--white);
    font-size: 14px;
    border-radius: 5px;
    align-self: center;
    justify-content: center;
    background-color: var(--red);
    display: flex;
    align-items: center;
    cursor: pointer;
}

.modal_jobs_close {
    align-self: flex-end;
    cursor: pointer;
}

@media (max-width: 736px) {
    .modal_jobs_content_title span {
        font-size: 23px;
    }

    .modal_jobs_content {
        margin: 10px;
    }
}

@media (max-width: 500px) {
    .modal_jobs_content_title span {
        font-size: 15px;
    }

    .modal_jobs_content_img img {
        width: 60%;
        height: 60%;
    }
    .modal_jobs_content{
        padding: 20px 16px;
    }
    .modal_jobs_content_button{
        font-size: 10px;
        padding: 10px;
    }
}