.spinner {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    border: 3px solid #f3f3f3;
    border-top-color: #4f5052;
    animation: spinAnimation 1s linear infinite;
}

@keyframes spinAnimation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}