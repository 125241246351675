.cart_content_null_block {
  margin-top: 46px;
  background-color: #f7f7fb;
  padding: 90px 20px 60px 20px;
  display: flex;
  flex-direction: column;
  row-gap: 26px;
  align-items: center;
  justify-content: center;
  margin-bottom: 55px;
}
.cart_content_null_block h3 {
  font-size: 30px;
  font-weight: bold;
  font-family: "Mardoto-Regular";
  text-transform: uppercase;
}
.cart_content_null_block a {
  padding: 17px 106px;
  border-radius: 5px;
  color: var(--white);
  font-size: 14px;
  cursor: pointer;
  background-color: var(--red);
}
.cart_content_null_block img {
  width: auto;
}
@media only screen and (max-width: 768px) {
  .cart_content_null_block h3 {
    font-size: 18px;
    text-align: center;
  }
  .cart_main {
    padding-top: 62px;
  }
}
@media only screen and (max-width: 600px) {
  .cart_content_null_block a {
    padding: 12px 30px;
    font-size: 12px;
    text-align: center;
  }
}
