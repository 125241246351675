.main {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  overflow: auto;
  border-radius: 5px;
  padding: 36px;
  background: #f7f7fb;
  margin: 30px 0;
  position: relative;
}
.main::-webkit-scrollbar {
  height: 4px;
  width: 4px;
}
.main::-webkit-scrollbar-thumb {
  background-color: var(--red);
  border-radius: 10px;
  background-clip: padding-box;
  transition: all 300ms ease-out;
}
.main::-webkit-scrollbar-track {
  background: #e4e4e4;
  border-radius: 4px;
  height: 19px;
}
.header {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.backBtn {
  display: flex;
  align-items: center;
  width: 40px;
  height: 40px;
  background: #e5e5ee;
  border-radius: 2px;
  justify-content: center;
  cursor: pointer;
  border: 1px solid transparent;
}
.backBtn:hover {
  border-color: var(--red);
}
.backBtn:hover svg path {
  fill: var(--red);
}
.closeBtn {
  cursor: pointer;
}
.closeBtn:hover svg path {
  stroke: var(--red);
}
.bankLogo {
  max-width: 274px;
  max-height: 136px;
  border-radius: 5px;
  padding: 10px;
  margin-bottom: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--white);
  object-fit: contain;
}
.bankText {
  min-height: 150px;
  height: 280px;
  padding-right: 82px;
  overflow: auto;
  font-size: 18px;
  line-height: 28px;
  margin-bottom: 14px;
  color: rgba(40, 40, 40, 0.7);
  width: 100%;
}

.title {
  font-size: 18px;
  text-transform: uppercase;
  font-weight: bold;
}
.passportInput {
  background-color: var(--white);
  width: 100%;
  padding: 12px 24px;
  font-size: 14px;
  border-radius: 5px;
  border: 1px solid transparent;
  outline: none;
}
.passportInput:focus {
  border-color: var(--green);
}

.checkBoxBlock {
  display: flex;
  column-gap: 10px;
  align-items: center;
  cursor: pointer;
}
.checkBox {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 17px;
  min-width: 17px;
  height: 17px;
  border: 1.5px solid rgba(40, 40, 40, 0.6);
  border-radius: 2px;
}
.checkBoxText {
  color: #7e7e7e;
  font-size: 14px;
}

.creditInfoBlock {
  max-width: 530px;
  width: 100%;
  padding: 40px 36px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  flex-direction: column;
  row-gap: 30px;
  margin: 0 auto;
}
.creditInfoBlockTitle {
  font-size: 24px;
  padding-bottom: 14px;
  font-weight: bold;
  color: black;
  text-transform: uppercase;
  text-align: center;
}
.creditInfoBlockDescription {
  font-size: 14px;
  text-align: center;
  font-weight: 600;
  color: rgba(40, 40, 40, 0.6);
}
.addPhotoText {
  font-size: 18px;
  color: rgba(40, 40, 40, 0.7);
}
.uploadBtn {
  display: flex;
  align-items: center;
  padding: 20px;
  width: 300px;
  background-color: #282828;
  border-radius: 5px;
  justify-content: center;
  cursor: pointer;
}
.uploadBtn:hover {
  background-color: #000;
}
.uploadBtn span {
  font-size: 16px;
  color: white;
}

.uploadFileDesc {
  white-space: wrap;
  max-width: 100%;
  overflow: unset;
}

.uploadFileDescRow {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-start;
  max-width: 100%;
  width: 100%;
  /* justify-content: space-between; */
}
.uploadFileDescRow .step {
  width: 97.825px;
  flex-basis: 8.33333%;
  max-width: 8.33333%;
  -webkit-box-flex: 0;
  font-size: 14.4px;
  padding-right: 10%;
  height: 35px;
  white-space: nowrap;
}
.uploadFileDescRowDesc {
  flex-basis: 91.6667%;
  max-width: 91.6667%;
  -webkit-box-flex: 0;
  white-space: pre-wrap;
  padding-right: 15px;
  padding-left: 15px;
  font-size: 14.4px;
}
.buyBtn {
  width: 350px;
  padding: 20px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  background-color: var(--red);
  display: flex;
  align-items: center;
  justify-content: center;
}
.buyBtn span {
  color: var(--white);
  font-size: 16px;
}
.uploadedImgContainer {
  width: 100px;
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
}
.uploadedImgContainer img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.uploadedImagesRow {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.deleteImage {
  display: flex;
  cursor: pointer;
  border: none;
  background: transparent;
  color: var(--dark-red);
}
@media only screen and (max-width: 768px) {
  .uploadFileDescRow {
    flex-direction: column;
  }
}

.thanksModalBg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  z-index: 9999999999999999;
  display: flex;
  justify-content: center;
  align-items: center;
}
.thanksModal {
  max-width: 530px;
  width: 100%;
  background-color: var(--white);
  padding: 40px 36px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  flex-direction: column;
  row-gap: 30px;
}
.thanksModalClose {
  align-self: flex-end;
  cursor: pointer;
}
.thanksModalImg {
  max-width: 135px;
  height: 146px;
}
.thanksModalTitle {
  font-size: 30px;
  font-weight: bold;
  color: black;
  text-transform: uppercase;
}
.thanksModalSubTitle {
  font-size: 14px;
  text-align: center;
  font-weight: 600;
  color: rgba(40, 40, 40, 0.6);
}
.thanksModalBtn {
  max-width: 374px;
  width: 100%;
  padding: 16px;
  display: flex;
  border-radius: 5px;
  align-self: center;
  justify-content: center;
  background-color: var(--red);
  cursor: pointer;
}
.thanksModalBtn span {
  color: var(--white);
  font-size: 14px;
}

@media (max-width: 500px) {
  .uploadBtn {
    width: 80%;
  }
  .buyBtn {
    width: 100%;
  }
}
@media (max-width: 410px) {
  .uploadBtn span,
  .buyBtn span {
    font-size: 11px;
  }
}
