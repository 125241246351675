.form_maps {
  width: 100%;
  height: 100%;
}

.address_block_item {
  background: #f7f7fb;
  max-width: 408px;
  max-height: 300px;
  width: 100%;
  height: 100%;
  min-width: 0px;
  cursor: pointer;
  margin: 0 10px 0 0;
  border-radius: 20px;

  transform: translate3d(-50%, -60%, 0);
}
.store_img_chosen {
  width: 100%;
  max-height: 400px;
}
.store_img_chosen img {
  width: 100%;
  height: 100%;
}

.addr_cont_inf {
  max-width: 346px;
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  border-radius: 5px;
  padding: 28px;
  background-color: var(--white);
}

.locname_ch {
  font-size: 20px;
  font-weight: 600;
}

.time_phone {
  column-gap: 18px;
}
.chosen_phone_mail_user {
  display: flex;
  align-items: center;
  column-gap: 8px;
  color: rgba(40, 40, 40, 0.5);
  font-size: 13px;
}

@media (max-width: 426px) {
  .locname_ch {
    font-size: 15px;
    font-weight: 600;
  }

  .time_phone {
    gap: 10px;
    flex-direction: column;
    display: flex;
  }
  .address_block_item {
    padding: 15px;
    gap: 10px;
  }
}
.store_for_map_scrool {
  max-height: 411px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 40px;
}

.loc_name {
  font-size: 20px;
}

.time_phone {
  display: flex;
  column-gap: 18px;
  justify-content: flex-start;
}
