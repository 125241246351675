
.details_loader_categories_item {
    width: 20%;
    height: 40px;
}


.details_loader_categories {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 40px;
}

.details_vue1_categories {
    margin-bottom: 72px;
}

.details_product_title {
    flex-wrap: wrap;
    gap: 12px;
    margin: 20px 0;
    display: flex;
    justify-content: space-between;
}

.details_product_title span {
    font-size: 30px;
    font-weight: bold;
    color: black;
    text-transform: uppercase;
}

.details_product_title a {
    font-size: 16px;
    cursor: pointer;
    text-transform: uppercase;
    text-decoration: underline;
}

.details_vue1_slider {

}

.details_product_slider_loader {
    display: flex;
    gap: 20px;
}

.details_product_slider_loader_item {
    width: 20%;
    height: 300px;
}
.details_banners{

}
.details_footer_banners {
    display: flex;
    gap: 20px;
    margin: 50px 0;
}

.details_footer_banners_item {
    max-height: 196px;
}

.details_footer_banners_item img {
    border-radius: 5px;
    max-width: 100%;
    max-height: 100%;

}

.details_footer_banners_loader {
    height: 200px;
    width: 100%;
}
.details_banner_slider{
    max-width: 1170px;
    margin: 0 auto 50px;

}

.details_timer_block{
    background: #f7f7fb;
     margin-bottom: 60px;
    padding: 40px 64px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    column-gap: 20px;
    display: flex;
    justify-content: space-between;
}

.details_title_img{
    column-gap: 62px;
    display: flex;
    align-items: center;

}
.procent_img{

}
.get_titles{
    display: flex;
    flex-direction: column;
    row-gap: 8px;
}
.get_titles span{
    width: max-content;
    font-size: 30px;
    font-weight: bold;
    text-transform: uppercase;
}
.get_titles p{
    font-size: 18px;
    opacity: 0.4;
    text-transform: uppercase;
    font-weight: bold;
}
.details_timer_block{
    background: #f7f7fb;
    margin-bottom: 60px;
    margin-top: 40px;
    padding: 40px 64px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    column-gap: 20px;
    display: flex;
    justify-content: space-between;
}

@media (max-width: 918px) {
    .details_timer_block{
        margin-bottom: 40px;
        padding: 20px 34px;
        column-gap: 10px;

    }
    .details_title_img{
        column-gap: 32px;


    }
    .get_titles span {
        font-size: 18px;
    }

    .get_titles a {
        font-size: 14px;

    }


}
@media (max-width: 700px) {

    .details_timer_block{
        flex-direction: column;
        margin-bottom: 40px;
        padding: 10px 24px;
        align-items: center;
    }
    .get_titles span {
        text-align: center;
    }

    .get_titles a {
        text-align: center;

    }

}
@media (max-width:880px) {




}



