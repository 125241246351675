.contacts_icons {
  display: flex;
  align-items: center;
  width: 100%;
  margin: 40px 0 60px 0;
  background: var(--back-gray);
  border-radius: 5px;
  padding: 40px;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: space-around;
}

.cont_block {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  font-size: 20px;
  color: rgba(40, 40, 40, 0.7);

  &:hover {
    color: var(--red);
  }
}

.round_cont_svg {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  background: #eaeff5;
  border-radius: 90px;
  padding: 15px;
  border: 1px solid rgba(40, 40, 40, 0.2);

  > img {
    width: 100%;
    height: 100%;
    max-width: 32px;
    object-fit: contain;
  }
}

.cont_blokes {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  row-gap: 16px;
}
.cont_titles {
  font-size: 20px;
  font-weight: bold;
  text-align: center;
}

.social_media_f {
  display: flex;
  column-gap: 20px;
}

.social_svgs {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(40, 40, 40, 0.2);
  border-radius: 5px;
  width: 40px;
  height: 40px;
  padding: 6px;
}

.contact_second_title {
  font-size: 30px;
  font-weight: bold;
  color: black;
  text-transform: uppercase;
  margin-bottom: 20px;
}
.line {
  height: 42px;
  border-right: 1px solid rgba(40, 40, 40, 0.1);
}
.contactItem {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
  cursor: pointer;
}
.contactItem span {
  font-size: 20px;
  color: rgba(40, 40, 40, 0.7);
}
.contactItem:hover span {
  color: var(--red);
}
.roundSvgContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  background: #eaeff5;
  border-radius: 90px;
  padding: 15px;
  border: 1px solid rgba(40, 40, 40, 0.2);
}
.contactItem:nth-child(4) {
  flex-direction: column;
}
.contactItemTitle {
  font-size: 20px;
  font-weight: bold;
}
.socIconsContainer {
  display: flex;
  flex-direction: row;
  column-gap: 20px;
}
.socIconsContainer div {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(40, 40, 40, 0.2);
  border-radius: 5px;
  width: 40px;
  height: 40px;
  padding: 6px;
  cursor: pointer;
}
.socIconsContainer div:hover {
  border-color: var(--red);
}
.socIconsContainer div:hover svg path {
  fill: var(--red);
}

.contact_compare_form {
  position: relative;
}
.head_search {
  width: 100%;
  padding: 15px 65px 15px 20px;
  font-size: 14px;
  border-radius: 5px;
  background-color: transparent;
  border: 1px solid #28282833;
  outline: none;
}

.head_search:focus {
  border-color: #159859;
}
.search_btn_ {
  position: absolute;
  top: 7px;
  right: 9px;
  font-size: 16px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  padding: 9px 29px;
  color: #ffffff;
  background-color: #d10000;
}
.search_btn_:hover {
  background-color: #980a21;
}

.contact_maps_block {
  display: flex;
  gap: 20px;
  min-height: 500px;
}

.contact_addres_list {
  max-height: 500px;
  overflow-x: scroll;
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.contact_addres_list::-webkit-scrollbar {
  height: 0;
  width: 2px;
}
.contact_addres_list::-webkit-scrollbar-track {
  background-color: red; /* цвет трека */
}
.contact_form {
  max-width: 432px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.store_img_chosen {
  min-width: 60px;
  width: 60px;
  height: 60px;
}
.store_img_chosen img {
  width: 100%;
  border-radius: 50%;
  height: 100%;
}

.chosen_phone_mail_user {
  display: flex;
  align-items: center;
  column-gap: 8px;
  color: rgba(40, 40, 40, 0.5);
  font-size: 13px;
}
.contact_form_maps {
  width: 100%;
}

@media (max-width: 627px) {
  .contacts_icons {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 0;
  }
  .contactItem {
    gap: 10px;
  }
  .contactItem span {
    font-size: 16px;
    color: rgba(40, 40, 40, 0.7);
  }
  .roundSvgContainer {
    width: 45px;
    height: 45px;
  }
}
@media (max-width: 1085px) {
  .contact_maps_block {
    flex-direction: column;
  }
  .contact_form {
    max-width: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  .contact_form_maps {
    height: 500px;
  }
}
@media (max-width: 426px) {
  .contact_addres_list {
    gap: 10px;
  }

  .head_search {
    width: 100%;
    padding: 15px 65px 15px 20px;
    font-size: 14px;
    border-radius: 5px;
    background-color: transparent;
    border: 1px solid #28282833;
    outline: none;
  }

  .head_search:focus {
    border-color: #159859;
  }
  .search_btn_ {
    font-size: 10px;
  }
}
.loadContent_icons {
  width: 100%;
  height: 100px;
  max-width: 1440px;
  aspect-ratio: 10 / 2.6;
  margin: 0 auto;
}
.loadContent_maps {
  width: 100%;
  height: 400px;
  max-width: 1440px;
  aspect-ratio: 10 / 2.6;
  margin: 0 auto;
  padding: 50px 0 0;
}
.loadContent_form {
  width: 100%;
  height: 400px;
  max-width: 1440px;
  aspect-ratio: 10 / 2.6;
  margin: 0 auto;
  padding: 50px 0 0;
}
