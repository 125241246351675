.product {
  position: relative;
}
.productImg {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
  /* min-height: 200px;
  min-width: 100px;
  max-height: 200px;
  max-width: 200px; */
}
.plusBtn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: absolute;
  z-index: 2;
  /* top: 10px;
  right: 10px; */
  width: 40px;
  height: 40px;
  background-color: var(--red);
  cursor: pointer;
  border-radius: 90px;
}
.infoContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  position: absolute;
  z-index: 10;
  /* top: 60px;
  right: 10px; */
  width: auto;
  height: 0;
  padding: 0;
  border: 0px solid var(--white);
  -webkit-column-gap: 12px;
  -moz-column-gap: 12px;
  column-gap: 12px;
  background: rgba(255, 255, 255, 0.6);
  -webkit-backdrop-filter: blur(31px);
  backdrop-filter: blur(31px);
  border-radius: 5px;
  overflow: hidden;
}
.showedInfoContainer {
  border: 1px solid var(--white);
  height: auto;
  min-width: 290px;
  padding: 15px;
}
.smallImg {
  max-width: 96px;
  width: 100%;
  height: 96px;
}
.smallImg img {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
  object-fit: contain;
}
.aboutProduct {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  row-gap: 4px;
}
.aboutProductInfo {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  row-gap: 10px;
  cursor: pointer;
}
.price {
  font-size: 16px;
  font-weight: bold;
}
.category {
  text-transform: uppercase;
  color: rgba(40, 40, 40, 0.4);
  font-weight: bold;
  width: 100%;
  font-size: 10px;
  margin-bottom: 5px;
  word-break: break-word;
}
.name {
  height: 17px;
  width: 100%;
  max-width: 200px;
  font-size: 13px;
  overflow: hidden;
  line-height: 16px;
}
.priceMonth {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  font-size: 12px;
  color: var(--gray-bold);
}
.btnsContainer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-column-gap: 10px;
  -moz-column-gap: 10px;
  column-gap: 10px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
.btnsContainer svg {
  cursor: pointer;
}
@media only screen and (max-width: 768px) {
  .plusBtn {
    width: 30px;
    height: 30px;
  }
  .plusBtn svg {
    max-width: 15px;
  }
  .showedInfoContainer {
    min-width: 230px;
    padding: 12px;
  }
  .smallImg {
    max-width: 76px;
    width: 100%;
    height: 76px;
  }
  .name {
    overflow: visible;
    text-wrap: wrap;
    height: auto;
  }
}

@media only screen and (max-width: 430px) {
  .plusBtn {
    width: 20px;
    height: 20px;
  }
  .plusBtn svg {
    max-width: 9px;
  }
  .productImg {
    min-width: auto;
    min-height: auto;
  }
}
