.container {
  /* -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none; */
}
.slideItem {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 160px;
  cursor: pointer;
  height: 100%;
  position: relative;
  display: block;
  padding: "20px";
  margin: 0 10px;
}
.loadContent {
  width: 138px;
  height: 157px;
  margin-bottom: 38px;
}
.slideItemImg {
  height: 138px;
  width: 100%;
  object-fit: contain;
  background-color: #f9f9f9;
  padding: 10px 10px 0 10px;
  margin-bottom: 22px;
  border-radius: 10px;
}
.slideItemTitle {
  font-size: 16px;
  max-width: min-content;
  text-align: center;
  min-width: 80%;
  display: block;
  margin: 0 auto;
}
.headerCategorysLogo {
  max-width: 120px;
  max-height: 20px;
  height: 100%;
  padding: 0 22px;
}
.headerCategorysLoad {
  column-gap: 20px;
}
.slideBtn {
  z-index: 2;
  position: absolute;
  width: 50px;
  height: 50px;
  box-shadow: 0px -1px 16px rgb(0 0 0 / 12%);
  justify-content: center;
  align-items: center;
  border-radius: 90px;
  background-color: #fff;
  cursor: pointer;
}
.slidePrevArrow {
  left: 15px;
  transform: rotate(180deg);
}
.slideNextArrow {
  right: 15px;
}
.slideBtn:hover svg path {
  stroke: var(--red);
}

@media (max-width: 1280px) {
  .slideItemTitle {
    font-size: 12px;
  }
  .loadContent {
    height: 152px;
  }
}
@media only screen and (max-width: 768px) {
  .slideItem {
    padding: 0;
  }
  .slideItemImg {
    height: 110px;
  }
  .loadContent {
    width: 124px;
    height: 110px;
  }
}
@media (max-width: 600px) {
  .slideItemTitle {
    font-size: 12px;
  }
  .loadContent {
    width: 119px;
  }
  .slideItem {
    margin: 0 8px;
    max-width: 100px;
  }
  .slideItemImg {
    padding: 3px 2px 0 2px;
  }
}
@media only screen and (max-width: 430px) {
  .slideItemImg {
    height: 70px;
    /* width: auto; */

    /* margin-right: ; */
  }
  .loadContent {
    width: 79px;
    height: 70px;
  }
}
