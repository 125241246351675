.container {
  width: 100%;
  display: block;
  padding: 6px 0;
  position: fixed;
  z-index: 13;
  bottom: 0;
  box-shadow: 0px -1px 16px rgb(0 0 0 / 12%);
  background-color: var(--white);
  transform: translate(-100%, 100%);
}
.show {
  transform: translate(0%, 0%);
}
.wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1200px;
  width: 100%;
  padding: 0 20px;
  margin: 0 auto;
  gap: 12px;
}
.productImg {
  max-width: 100px;
  width: 100%;
  height: 70px;
  position: relative;
}
.productImg img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}
.nameBlock {
  display: flex;
  flex-direction: column;
  row-gap: 5px;
  max-width: 220px;
}
.name {
  font-size: 18px;
  text-transform: uppercase;
  font-weight: bold;
}
.price_block {
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  column-gap: 8px;
  width: 100%;
}
.priceText {
  font-size: 12px;
  text-transform: uppercase;
  color: rgba(40, 40, 40, 0.4);
  font-weight: bold;
}
.priceNumber {
  font-size: 20px;
  font-weight: bold;
}
.compareHeart {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 76px;
  height: 38px;
  gap: 8px;
  padding: 10px;
  border: 1px solid rgba(40, 40, 40, 0.2);
  border-radius: 5px;
}
.compareHeart div svg {
  width: 18px;
  height: 18px;
}
.compareHeart div:hover svg path,
.compareHeart div:hover svg rect,
.shareBtn div:hover svg path {
  fill: var(--red) !important;
}
.compareHeart span {
  height: 24px;
  border-right: 1px solid rgba(40, 40, 40, 0.2);
}
.buyBtn {
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: var(--red);
  padding: 12px 6px;
  justify-content: center;
  border-radius: 5px;
  column-gap: 10px;
  width: 100%;
  max-width: 210px;
  border: none;
  color: var(--white);
}
.buyBtn span {
  font-size: 10px;
  color: var(--white);
}
.buyBtn:hover {
  background-color: var(--dark-red);
}
.cardBtnSvg {
  width: 15px;
  height: 15px;
}
.cardBtnSvg path {
  fill: #fff;
}
.priceBlock {
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  column-gap: 8px;
  width: 100%;
}
.newPrice {
  color: var(--red);
  font-size: 25px;
  font-weight: bold;
}
.oldPriceBlock {
  max-width: fit-content;
  position: relative;
}
.oldPrice {
  font-size: 16px;
  color: #898383;
}
.oldPriceLine {
  width: 100%;
  position: absolute;
  border-bottom: 1px solid var(--red);
  top: 11px;
  transform: rotate(-6deg);
  left: 0;
}
.price {
  font-size: 20px;
  font-weight: bold;
}
.stickerImg {
  width: 60px;
  object-fit: contain;
  align-self: center;
}

@media screen and (max-width: 768px) {
  .name {
    font-size: 14px;
  }
  .oldPrice,
  .newPrice {
    font-size: 14px;
  }
}