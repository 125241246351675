.container {
  margin-bottom: 40px;
  position: relative;
}
.loadContent {
  width: 100%;
  height: auto;
  max-width: 1440px;
  aspect-ratio: 10 / 2.6;
  margin: 0 auto;
}
.slideBtn {
  z-index: 2;
  position: absolute;
  width: 50px;
  height: 50px;
  box-shadow: 0px -1px 16px rgb(0 0 0 / 12%);
  justify-content: center;
  align-items: center;
  border-radius: 90px;
  background-color: #fff;
  cursor: pointer;
  top: calc(50% - 25px);
}
.slidePrevArrow {
  left: 7%;
  transform: rotate(180deg);
}
.slideNextArrow {
  right: 7%;
}
.slideBtn:hover svg path {
  stroke: var(--red);
}
.slideImg {
  width: 100%;
  max-height: 486px;
  object-fit: contain;
}

@media only screen and (max-width: 1280px) {
  .container {
    padding: 0;
  }
}
