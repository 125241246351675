.search_null_block_body {
  padding: 0 20px;
  margin: 40px 0 60px 0;
  justify-content: space-around;
  column-gap: 20px;
  background-color: #f7f7fb;
  display: flex;
  align-items: center;
}
.search_null_categories {
  margin-bottom: 80px;
}
.search_null_block_text {
  max-width: 458px;
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.search_null_block_text h1 {
  font-size: 40px;
}

.search_null_block_text button {
  border: 1px solid transparent;
  height: 35px;
  width: 100%;
  color: white;
  font-size: 12px;
  background-color: var(--red);
  cursor: pointer;
  border-radius: 5px;
}

.search_null_block_text button:hover {
  background-color: #980a21;
}

.search_null_block_image {
  height: 410px;
  margin: -30px 0;
}
.search_null_block_image img {
  object-fit: contain;
}
@media (max-width: 1280px) {
  .search_null_block_text {
    max-width: 445px;
  }
  .search_null_block_image {
    max-width: 308px;
  }
}

@media (max-width: 990px) {
  .search_null_block_text {
    max-width: 336px;
  }
  .search_null_block_image {
    max-width: 311px;
    height: 220px;
  }
  .search_null_block_text h1 {
    font-size: 30px;
  }
  .search_null_block_image img {
    width: 100%;
    height: 100%;
  }
}

@media (max-width: 782px) {
  .search_null_block_body {
    flex-direction: column;
  }
  .search_null_block_image {
    margin-top: 0;
    margin-bottom: -20px;
  }
  .search_null_block_image {
    height: 180px;
  }
  .search_null_block_text h1 {
    font-size: 20px;
    margin-top: 20px;
    text-align: center;
  }
}
