.colors{
    width: 100%;
    /* height: 100%; */
    overflow: auto;
    display: grid;
    grid-template-columns: 1fr;
    row-gap: 8px;
    padding: 30px 0px 10px 77px;
}
.colorsItem span:hover{
    color: var(--red);
 } 

.colorsItem{
    height: 38px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    /* border:1px solid black; */
    
}
.colorsItemCircle{
    width: 38px;
    height: 38px;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 14px;
    border: 1px solid transparent;
    padding: 5px;
}
.circle{
    display: inline-block;
    width: 26px;
    height: 26px;
    border-radius: 25px;
    cursor: pointer;
    margin-right: 0px;
    margin-bottom: -1px;
    border: 1px solid #2828281a;
}
.circleText{
    font-size: 18px;
    font-weight: 400;
    line-height: 21px;
    color: var(--black);
    cursor: pointer;
}

.active{
    border: 1px solid var(--red);
}
.circleTextActive{
    color: var(--red);
 }
 @media(min-width:768px){
    .colorsItemCircle:hover{
            border: 1px solid var(--red);
        }
 }
@media(max-width:768px){
    .circleText{
        font-size: 12px;
    }
    .colors{
        padding: 30px 0px 10px 20px;
    }
}