.brands_sub_title {
  text-align: center;
  margin-bottom: 40px;
  margin-top: 40px;
}

.image_style img {
  max-width: 100%;
  max-height: 100%;
}
.brand__small_slider_banners {
  display: flex;
  margin-bottom: 48px;
  margin-top: 48px;
  gap: 30px;
  align-items: flex-start;
}

.small_categories_list {
  width: 100%;
  gap: 30px;
  display: flex;
}

.brand_banners_list {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.small_categories_item_block {
  display: flex;
  flex-direction: column;
  gap: 30px;
  max-width: 320px;
  width: 100%;
}

.vikas_catalog_block {
  gap: 30px;
  margin: 64px 0 54px 0;
  align-items: flex-start;
  display: flex;
}

.vikas_catalog_images {
  max-width: 320px;
  min-width: 280px;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 1;
}

.vikas_catalog_images > :not(:last-child) {
  margin: 0 0 20px;
}

.vikas_catalog_list {
  max-width: calc(100% - 320px);
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  width: 100%;
}

.vikas_catalog_list_title {
  text-align: center;
}

.catalog_list_cards {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, minmax(210px, 310px));
  flex-wrap: wrap;
  gap: 10px;
}

.more_prods {
  justify-content: center;
  padding: 12px;
  margin-top: 50px;
  background-color: #f7f7fb;
  border-radius: 5px;
  text-transform: uppercase;
  cursor: pointer;
  font-size: 16px;
  border: 1px solid transparent;
  display: block;
  width: 100%;
  text-align: center;
}

.more_prods:hover {
  color: var(--red);
  border-color: var(--red);
}

/*CATALOG END*/

.last_info_brand {
  background: #f7f7fb;
  border-radius: 5px;
  padding: 56px;
  color: #898383;
  margin: 62px 0;
  font-size: 18px;
  line-height: 150%;
}

.brand_loader_categories_item {
  width: 10%;
  height: 40px;
}

.brand_mini_slider_loader {
  width: 50%;
  height: 500px;
  margin-top: 40px;
}
.brand_categories_item {
  gap: 20px;
  padding-bottom: 10px;
  overflow: auto;
  width: 100%;
  display: flex;
}

.brand_loader_categories {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
}

.brand_mini_banners_loader {
  height: 500px;
  width: 100%;
}
.brands_simple {
  padding: 14px 18px;
  font-size: 12px;
  cursor: pointer;
  min-width: max-content;
  border: 1px solid #282828;
}
.brands_simple:hover {
  color: var(--red);
  border-color: var(--red);
}
.brands_simple_active {
  color: var(--red);
  border-color: var(--red);
}

.brand_banners_loader {
  width: 100%;
  margin: 0 auto;
  height: 400px;
  margin-top: 30px;
}

.brand_text_loader {
  width: 100%;
  margin-top: 30px;
}

.brands_sub_title_loader {
  width: 50%;
  margin: 0 auto;
  margin-bottom: 40px;
  margin-top: 40px;
}

.catalog_list_cards_loader {
  display: flex;
  gap: 20px;
}

.catalog_list_cards_loader_item {
  width: 218px;
  height: 300px;
}

.last_info_brand_loader {
  border-radius: 5px;
  width: 100%;
  margin: 62px 0;
  height: 300px;
}
@media screen and (max-width: 1440px) {
  .vikas_catalog_block {
    justify-content: space-between;
  }

  .vikas_catalog_images {
    max-width: 320px;
    min-width: 280px;
    width: 100%;
    display: flex;
    flex-direction: column;
  }
}

@media screen and (max-width: 1100px) {
  .vikas_catalog_images {
    min-width: 250px;
  }
  .vikas_catalog_list {
    max-width: calc(100% - 250px);
  }
}

@media (max-width: 1024px) {
  .vikas_catalog_list {
    max-width: 100%;
  }
  .catalog_list_cards {
    grid-template-columns: repeat(3, minmax(210px, 340px));
  }

  .small_categories_list {
    gap: 20px;
  }
  .small_categories_item_block {
    gap: 20px;
  }
  .vikas_catalog_block {
    flex-direction: column;
  }
  .vikas_catalog_images {
    flex-direction: row;
    width: 100%;
    max-width: none;
    gap: 20px;
  }
  .vikas_catalog_images > :not(:last-child) {
    margin: 0;
  }
  .catalog_list_cards {
    width: 100%;
  }
}

@media (max-width: 804px) {
  .brand_mini_slider_loader {
    width: 100%;
    height: 300px;
    margin-top: 20px;
  }
}

@media (max-width: 790px) {
  .vikas_catalog_block {
    flex-direction: column;
  }

  .vikas_catalog_images {
    max-width: 100%;
    width: 100%;
  }
}

@media (max-width: 788px) {
  .brand_banners_loader {
    width: 100%;
    margin: 0 auto;
    height: 200px;
  }
  .brand_mini_slider_loader {
    width: 100%;
    height: 300px;
  }
}

@media only screen and (max-width: 715px) {
  .catalog_list_cards {
    grid-template-columns: repeat(2, minmax(180px, 340px));
  }
  .small_categories_list {
    flex-wrap: wrap;
  }
  .small_categories_item_block:nth-child(2) {
    width: calc(50% - 10px);
    min-width: auto;
  }
  .small_categories_item_block:nth-child(3) {
    width: 100%;
    flex-direction: row;
    max-width: none;
  }
  .small_categories_item_block{
    max-width: none;
  }
}

@media (max-width: 488px) {
  .brand_banners_loader {
    width: 100%;
    margin: 0 auto;
    height: 150px;
  }
  .more_prods {
    margin-top: 20px;
  }
  .last_info_brand {
    padding: 32px 22px;
    margin: 32px 0;
    font-size: 14px;
  }
  .last_info_brand p p {
    text-align: center !important;
  }
}
@media (max-width: 400px) {
  .catalog_list_cards {
    grid-template-columns: repeat(2, minmax(130px, 340px));
  }
}
