.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  column-gap: 10px;
  padding: 18px 22px;
  border: 1px solid #2828281a;
  border-radius: 5px;
}
.sortBlock {
  display: flex;
  align-items: center;
  column-gap: 10px;
}
.sortType {
  font-size: 16px;
}
.sortBtnContainer {
  position: relative;
}
.sortBtn {
  width: 32px;
  height: 32px;
  border-radius: 90px;
  justify-content: center;
  cursor: pointer;
  background-color: var(--red);
  display: flex;
  justify-content: center;
  align-items: center;
}
.sortModal {
  position: absolute;
  top: 35px;
  padding: 8px 12px;
  right: 0;
  z-index: 1000;
  display: flex;
  width: max-content;
  flex-direction: column;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.05);
  border-radius: 5px;
  background-color: var(--white);
}
.sortModalItem {
  font-size: 16px;
  padding: 10px 0;
  cursor: pointer;
  border-bottom: 1px solid #2828281a;
}
.sortModalItem:last-child {
  border-bottom: none;
}
.sortModalItem:hover {
  color: var(--red);
}
.listTypeBlock {
  display: flex;
  column-gap: 20px;
  align-items: center;
}
.listTypeBtn {
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 34px;
  height: 34px;
  border-radius: 90px;
  justify-content: center;
}
@media only screen and (max-width: 600px) {
  .sortTitle,
  .sortType {
    font-size: 12px;
  }
  .sortBtn svg,
  .listTypeBtn svg {
    max-width: 15px;
  }
}
