.brand_small_slider {
  position: relative;
  max-width: 460px;
}

@media (max-width: 1284px) {
  .brand_small_slider,
  .brand_small_banners_item {
    max-width: 307px;
  }
}
@media (max-width: 768px) {
  .brand_small_slider {
    min-width: 225px;
    width: 225px;
  }
}
@media (max-width: 600px) {
  .brand_small_slider {
    min-width: auto;
    width: calc(50% - 10px);
  }
}

.slider_image {
  border-radius: 10px;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
