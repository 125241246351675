.background {
  position: fixed;
  z-index: 9999999999;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(40, 40, 40, 0.4);
}
.border-color-input input{
  border-color: red;
}
.container {
  z-index: 50;
  width: 0;
  height: 0;
  display: flex;
  flex-direction: column;
  row-gap: 38px;
  position: fixed;
  top: 0;
  right: 0;
  background-color: var(--white);
  overflow: auto;
}
.openedContainer {
  height: 100%;
  max-width: 620px;
  width: 620px;
  padding: 60px 70px;
}
.logo {
  max-width: 178px;
  width: 100%;
  object-fit: contain;
  max-height: 200px;
}
.main {
  display: flex;
  flex-direction: column;
  row-gap: 28px;
}
.headContent {
  display: flex;
  align-items: center;
  column-gap: 24px;
}
.backBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 54px;
  height: 54px;
  background-color: var(--back-gray);
  border-radius: 6px;
  border: 1px solid transparent;
}
.headContent span {
  font-size: 24px;
  font-weight: bold;
  text-transform: uppercase;
}
.subTitle {
  font-size: 18px;
}
.input {
  width: 100%;
  padding: 12px 24px;
  font-size: 14px;
  background-color: var(--back-gray);
  border-radius: 5px;
  border: 1px solid transparent;
}
.inputError {
  border-color: var(--red);
}
.errorEmail{
  font-size: 12px;
  color:var(--red);
  margin-top: 3px;
}
.passwordContainer {
  position: relative;
  display: flex;
  align-items: center;
}
.eye {
  position: absolute;
  right: 24px;
  cursor: pointer;
}
.forgotPassword {
  font-size: 16px;
  cursor: pointer;
  text-decoration: underline;
}
.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--red);
  border-radius: 5px;
  padding: 20px;
  border: none;
  cursor: pointer;
  position: relative;
}
.btn_hover:hover {
  background-color: var(--dark-red);
}
.darkBtn {
  background-color: var(--black);
}
.btn span {
  color: var(--white);
  font-size: 16px;
}
.line {
  width: 100%;
  border-bottom: 1px solid rgba(40, 40, 40, 0.1);
}
.twoInps {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  gap: 20px;
}
.btnArrow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: absolute;
  top: 25px;
  right: 38px;
}

/* sended email */

.email_icons {
  display: flex;
  align-items: center;
  align-self: center;
  position: relative;
  width: 155px;
  height: 155px;
  border-radius: 34px;
  padding: 20px;
  justify-content: center;
  background-color: var(--back-gray);
}

.email_icons img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.top_triangle {
  position: absolute;
  top: 0;
  right: -22px;
}

.bottom_triangle {
  position: absolute;
  bottom: 42px;
  left: -25px;
}

.we_send {
  align-self: center;
  max-width: 426px;
  color: #898383;
  font-size: 18px;
  line-height: 30px;
  text-align: center;
}

@media only screen and (max-width: 992px) {
  .container,
  .openedContainer {
    padding: 60px 50px 109px 50px;
  }
}
@media only screen and (max-width: 620px) {
  .container,
  .openedContainer {
    width: 100%;
  }
}
@media only screen and (max-width: 430px) {
  .container,
  .openedContainer {
    padding: 26px 22px 91px 22px;
  }
  .logo {
    max-width: 145px;
  }

  .input {
    font-size: 12px;
  }
  .btn {
    font-size: 14px;
    padding: 15px;
  }
}
