



.questions_list {
    max-width: 406px;
    width: 100%;
    background: #f7f7fb;
    border-radius: 5px;
    padding: 24px;
    display: flex;
    flex-direction: column;
}
.questions_item,.questions_item_active{
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
    padding: 16px 10px;
    border-bottom: 1px solid rgba(40, 40, 40, 0.1);
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    gap: 10px;
}
.questions_item_active{
    color: var(--red);
}
.questions_item_active span{
    transform: rotate(-90deg);
}
.questions_item_active span svg path{
    stroke: var(--red);
}
.input_filter_search {
    margin-bottom: 14px;
    position: relative;
}
.input_filter_search input {
    font-size: 14px;
    padding: 12px 30px 12px 20px;
    background-color: transparent;
    border-radius: 2px;
    border: 1px solid #28282833;
    width: 100%;
}
.input_filter_search input:focus{
    border-color: var(--green);

}
.search_filter_btn {
    cursor: pointer;
    border: none;
    background-color: transparent;
    position: absolute;
    top: 12px;
    right: 12px;

}
@media (max-width: 1280px) {
    .questions_list{
        max-width: none;
    }
}
@media (max-width: 600px) {
    .questions_item,.questions_item_active{
        font-size: 16px;
    }
    .input_filter_search input{
         font-size: 10px;
    }
}
