.container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: wrap;
  gap: 14px;
  margin-top: 30px;
  margin-bottom: 46px;
}
.homeBtn:hover svg path {
  fill: var(--red);
}
.line {
  width: 22px;
  height: 1px;
  background-color: var(--black);
  opacity: 0.4;
}
.title {
  color: var(--black);
  opacity: 0.85;
  font-size: 18px;
  text-transform: uppercase;
}

@media only screen and (max-width: 1024px) {
  .container {
    gap: 10px;
    margin-top: 20px;
    margin-bottom: 26px;
  }
}

@media only screen and (max-width: 600px) {
  .container {
    column-gap: 10px;
  }
  .title {
    font-size: 14px;
  }
}
