.container {
  display: flex;
  flex-direction: column;
  row-gap: 14px;
  padding-bottom: 20px;
  border-bottom: 1px solid #2828281a;
  width: 100%;
}
.titleBlock {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
}
.titleBlock .title {
  cursor: pointer;
  font-size: 12px;
  text-transform: uppercase;
  font-weight: bold;
}
.titleBlock:hover .title {
  color: var(--red);
}
.titleBlock:hover svg path {
  stroke: var(--red);
}
.arrow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  transform: rotate(0deg);
}
.dropDownBlock {
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  height: fit-content;
  overflow: hidden;
}
.checkBox {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 17px;
  height: 17px;
  border: 1.5px solid rgba(40, 40, 40, 0.6);
  border-radius: 2px;
}
.eachItem {
  display: flex;
  column-gap: 10px;
  align-items: center;
  cursor: pointer;
}
.label {
  color: #7e7e7e;
  font-size: 14px;
}
.seeMore {
  font-size: 12px;
  cursor: pointer;
  text-decoration: underline;
  color: var(--red);
}
.icon {
  width: 24px;
  height: 24px;
  object-fit: contain;
}
