.brand_see_all_title h1 {
  font-size: 30px;
  font-weight: bold;
  text-transform: uppercase;
}

.see_all_search {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  margin-top: 34px;
}

.see_all_brands_list {
  display: flex;
  gap: 22px;
  flex-wrap: wrap;
  margin-top: 34px;
  margin-bottom: 20px;
}

.pagination {
  display: flex;
  align-items: center;
  column-gap: 14px;
  margin-top: 20px;
}

.pagination_sp {
  padding: 10px 14px;
  height: 42px;
  font-size: 20px;
  font-weight: 500;
  cursor: pointer;
  justify-content: center;
  border: 1px solid rgba(40, 40, 40, 0.4);
  border-radius: 4px;
  display: flex;
  align-items: center;
}

.pagination_sp:hover {
  background-color: var(--red);
  color: var(--white);
  border-color: transparent;
}

.pagination_sp_active {
  background-color: var(--red);
  color: var(--white);
  border-color: transparent;
}

.prev_next_page {
  width: 42px;
  height: 42px;
  border-radius: 90px;
  cursor: pointer;
  justify-content: center;
  background-color: #f7f7fb;
  display: flex;
  align-items: center;
}

.prev_next_page:hover {
  background-color: var(--red);
  color: var(--white);
  border-color: transparent;
}

.prev_next_page:hover svg path {
  stroke: white;
}

.brand_loadContent_list {
  display: flex;
  gap: 22px;
  flex-wrap: wrap;
  width: 100%;
  margin-top: 34px;
}

.loadContent_item {
  padding: 5px 20px;
  max-width: 220px;
  min-height: 100px;
  width: calc(100% / 5 - 18px);
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-radius: 5px;
  border: 1px solid #eeeeee;
  justify-content: space-between;
}

.loadContent_animation {
  width: 100%;
  min-height: 100px;
}

@media (max-width: 1024px) {
  .loadContent_item {
    width: calc(100% / 4 - 18px);
  }
}

@media (max-width: 741px) {
  .loadContent_item {
    width: calc(100% / 3 - 18px);
  }

  .pagination {
    column-gap: 5px;
  }

  .pagination_sp {
    padding: 5px 10px;
    height: 30px;
    font-size: 17px;
  }
  .brand_see_all_title h1 {
    font-size: 25px;
  }
}

@media (max-width: 505px) {
  .pagination_sp {
    padding: 2px 7px;
    height: 30px;
    font-size: 10px;
  }

  .prev_next_page {
    width: 22px;
    height: 22px;
  }

  .prev_next_page svg {
    width: 50%;
    height: 50%;
  }
  .brand_see_all_title h1 {
    font-size: 20px;
  }
}

@media (max-width: 439px) {
  .loadContent_item {
    width: calc(100% / 2 - 18px);
  }
  .pagination_sp {
    padding: 2px 7px;
    height: 30px;
    font-size: 8px;
  }
}
.null_search_text {
  text-align: center;
  color: var(--red);
}
