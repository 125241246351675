
.address_block_item {
    background: #f7f7fb;
    border: 1px solid rgba(40, 40, 40, 0.2);
    border-radius: 5px;
    padding: 26px;
    gap: 20px;
    justify-content: flex-start;
    cursor: pointer;
    display: flex;
    margin: 0  10px 0 0;
    align-items: center;
}
.store_img_chosen {
    min-width: 60px;
    width: 60px;
    height: 60px;
}
.store_img_chosen img {
    width: 100%;
    border-radius: 50%;
    height: 100%;
}

.addr_cont_inf {
    display: flex;
    flex-direction: column;
    gap: 16px;
}
.locname_ch {
    font-size: 20px;
    font-weight: 600;
}

.time_phone {
    column-gap: 18px;
    display: flex;
}
.chosen_phone_mail_user {
    display: flex;
    align-items: center;
    column-gap: 8px;
    color: rgba(40, 40, 40, 0.5);
    font-size: 13px;
}



.block_item_import_maps{

}

@media (max-width: 426px) {
    .locname_ch {
        font-size: 15px;
        font-weight: 600;
    }

    .time_phone {
        gap: 10px;
        flex-direction: column;
    }
    .address_block_item{
        padding: 15px;
        gap: 10px;
    }
}
