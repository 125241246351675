.paginate {
  position: relative;
  & .swiper-wrapper {
    height: calc(100% - 15px) !important;
    & .swiper-slide {
      height: 100% !important;
    }
  }

  & .swiper-pagination {
    bottom: 0 !important;
    height: auto;
    background-color: transparent;

    & .swiper-pagination-bullet-active {
      background-color: var(--red);
    }
  }
}
