.loginCart {
    box-shadow: 0px 5px 15px 0px #0000000d;
    background-color: #fff;
    width: 100%;
    /* height: 402px; */
    padding: 80px 100px 74px 100px;
}
.loginCartContainer {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}
.loginCartTitle {
    margin-bottom: 31px;
}
.loginCartTitle h3 {
    font-size: 30px;
    font-weight: 700;
    line-height: 35px;
    color: var(--black);
}
.loginCartform {
    /* flex: 1 1 100%; */
}
.loginCartform form {
    display: flex;
    flex-direction: column;
}
.loginCartform form p {
    font-size: 18px;
    font-weight: 400;
    line-height: 23px;
    color: var(--black);
    /* margin-bottom: 14px; */
}
.loginCartInputs {
    display: flex;
    justify-content: space-between;
    /* margin-bottom: 25px; */
}
.inputError {
    border-color: var(--red);
}
.loginCartInputs input {
    width: 100%;
    height: 100%;
}
.loginCartLogin {
    margin-right: 21px;
    height: 51px;
    width: 100%;
}
.loginCartButtons {
    display: flex;
}

.loginCartSignIn {
    background: none;
    border: none;
    width: 246px;
    height: 51px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 30px;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    background-color: var(--red);
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
}
.loginCartSignUp {
    background: none;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    background-color: var(--black);
    border-radius: 5px;
    width: 140px;
    height: 51px;
    color: #fff;
    cursor: pointer;
}

.loginCartPassword {
    height: 51px;
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
}
.eye {
    position: absolute;
    right: 24px;
    cursor: pointer;
}
.loginCartFooter {
    margin-top: 25px;
}
.loginCartFooter p {
    font-size: 18px;
    font-weight: 400;
    line-height: 23px;
    color: var(--black);
}
.loginCartIcons {
    display: flex;
    margin-top: 20px;
}

.loginCartIcons span {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 51px;
    height: 51px;
    border-radius: 5px;
    border: 1px solid var(--black);
}
.loginCartIcons > :first-child {
    margin-right: 27px;
}
.loginCartIcons svg {
    height: 34px;
    width: 21px;
    margin-right: 0px;
}

.input {
    width: 100%;
    padding: 12px 24px;
    font-size: 14px;
    background-color: var(--back-gray);
    border-radius: 5px;
    border: 1px solid transparent;
}
@media (max-width: 1280px) {
    .loginCart {
        max-width: 100%;
        width: 100%;
    }
}

@media (max-width: 992px) {
    .loginCart {
        padding: 24px;
    }
}

@media (max-width: 768px) {
    .loginCartInputs {
        flex-direction: column;
        row-gap: 20px;
    }
}

@media (max-width: 400px) {
    .loginCartSignIn {
        margin-right: 12px;
        width: 140px;
    }
}
