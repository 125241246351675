.StoriesModal {
  inset: 0;
  background: #1d1d1dee;
  backdrop-filter: blur(25px);
  z-index: 999999999999999;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 26px;
  display: none;

  &.is-open {
    display: flex;
    position: fixed;
  }
}

.vieweInfo {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px 0 0;
  align-self: flex-end;
}

.viewImg {
  width: 33px;
  height: 33px;
}

.likeBtn {
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.likeCount {
  text-align: center;
  color: white;
  padding-top: 5px;
}

.viewsCont span {
  color: white;
  margin-left: 5px;
}

.viewsCont {
  display: flex;
  align-items: center;
  color: white;
  background: #00000040;
  border-radius: 11px;
  padding: 4px 15px;
  -webkit-backdrop-filter: blur(10px);
  backdrop-filter: blur(10px);
  max-width: 240px;
  width: max-content;
}

.StoriesContainer {
  width: 485px;
  height: 95dvh;
  min-width: 300px;
  border-radius: 30px;
  overflow: hidden;
}

.StoriesRightButtons {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 17px;
  margin-right: 22px;
  padding: 20px 0 60px;
}

.StoriesBottomControlsContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 17px;
}

.StoriesControlButtons {
  width: 40.7px;
  height: 40.7px;
  gap: 0px;
  color: #ffffff;
  border: none;
  background: transparent;
  cursor: pointer;
  background: #00000040;
  backdrop-filter: blur(10px);
  border-radius: 11px;

  &:hover {
    background: #fff;
  }
}

.StoryContentParent {
  width: 100% !important;
  height: 100% !important;
  position: relative;
  display: flex;
  justify-content: space-between;
  /* align-items: flex-end; */
  border-radius: 0 !important;
}

.StoryVideoImageContent {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
  object-fit: cover;
}

.StoryTextContainer {
  background: #00000040;
  border-radius: 20px;
  padding: 15px;
  backdrop-filter: blur(10px);
  min-height: 80px;
  max-width: 85%;
}

.StoryInfoContainer {
  margin: 0 0 60px 22px;
}

.StoryBuyNow {
  background: #e31335;
  margin-top: 21px;
  border: none;
  color: #ffffff;
  font-size: 16px;
  width: 166px;
  height: 40.7px;
  padding: 12.21px 14.43px 12.21px 14.43px;
  border-radius: 12.95px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.StoryBuyNowIcon {
  width: 22px;
  height: 22px;
  margin-right: 8px;
}

.StoryIcon {
  width: 37px;
  height: 37px;

  &:hover {
    path {
      stroke: #e31335;
    }
  }
}

.activeLike {
  background: #fff;
}

.activeLike path {
  stroke: #e31335;
}

.StoryTitle {
  font-size: 22px;
  font-weight: 500;
  text-align: left;
  color: #ffffff;
}

.StoryDescription {
  max-width: 352px;
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  color: #ffffff;
  margin: 10px 0 0;
}

@media screen and (max-width: 485px) {
  .StoriesContainer {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 0;
  }

  .StoryDescription {
    max-width: fit-content;
  }

  .StoryInfoContainer {
    margin: 0 0 60px 20px;
  }

  .StoryTitle {
    font-size: 18px;
  }

  .StoryDescription {
    font-size: 14px;
  }
}