.jobs_block_svg_title {
  justify-content: flex-start;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 14px;
  margin-top: 30px;
  margin-bottom: 46px;
}

.jobs_block_svg_title h3 {
  color: var(--red);
  opacity: 0.8;
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
}

.jobs_block_svg_title span {
  padding: 4px 16px;
  color: white;
  font-weight: bold;
  border-radius: 90px;
  font-size: 18px;
}

.jobs_name {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  cursor: pointer;
}
.about_acardion_list {
  display: flex;
  flex-direction: column;
  gap: 38px;
  margin: 56px 0 82px 0;
}
@media (max-width: 502px) {
  .about_acardion_list {
    gap: 20px;
  }
}
.loadContent_banner {
  width: 100%;
  height: 400px;
  max-width: 1440px;
  aspect-ratio: 10 / 2.6;
  margin: 0 auto;
  padding: 50px 0 0;
}
.loadContent_jobs {
  width: 100%;
  height: 200px;
  max-width: 1440px;
  aspect-ratio: 10 / 2.6;
  margin: 0 auto;
  padding: 50px 0 0;
}
.about_us_block {
  display: flex;
  flex-direction: column;
  gap: 26px;
}

.about_us_banner img {
  border-radius: 6px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.jobs_section {
  display: flex;
  flex-direction: column;
  gap: 38px;
  margin: 56px 0 82px 0;
}
