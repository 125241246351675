.title {
  font-size: 30px;
  font-weight: bold;
  text-transform: uppercase;
  margin: 60px 0 20px;
}
/* .categoriesBlock {
  display: flex;
  align-items: center;
  gap: 20px;
  justify-content: flex-start;
  flex-wrap: wrap;
} */
.categoryItem {
  display: flex;
  align-items: center;
  max-width: 340px;
  width: calc(100% / 4 - 20px);
  cursor: pointer;
  padding: 19px;
  min-width: 290px;
  border-radius: 5px;
  justify-content: flex-start;
  column-gap: 15px;
  border: 2px solid#EEEEEE;
}
.categoryItem .imgBlock {
  display: flex;
  align-items: center;
  min-width: 60px;
  width: 60px;
  min-height: 60px;
  height: 60px;
  border-radius: 90px;
  justify-content: center;
  background-color: #eaeff5;
}
.categoryItem .imgBlock img {
  max-width: 38px;
  max-height: 38px;
  object-fit: contain;
}
.activeCategory {
  border-color: var(--red);
}
.categoryItem:hover {
  border-color: var(--red);
}
.categoryItem span {
  font-size: 14px;
  max-width: 148px;
}
@media only screen and (max-width: 768px) {
  .title {
    font-size: 18px;
  }
}
