.container {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.searchContainer {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
}
.searchInput {
  width: 100%;
  padding: 15px 65px 15px 20px;
  font-size: 14px;
  border-radius: 5px;
  background-color: transparent;
  border: 1px solid #28282833;
}
.searchBtn {
  display: flex;
  align-items: center;
  position: absolute;
  right: 9px;
  font-size: 16px;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  padding: 9px 29px;
  color: var(--white);
  background-color: var(--red);
}
.searchBtn:hover {
  background-color: var(--dark-red);
}
.plusProductCompare {
  display: flex;
  align-items: center;
  min-width: 218px;
  max-width: 218px;
  background: #f7f7fb;
  border: 1px dashed var(--red);
  border-radius: 5px;
  height: 306px;
  cursor: pointer;
  justify-content: center;
}
.plusProductCompare div {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 62px;
  height: 62px;
  border-radius: 90px;
  border: 2px solid var(--red);
}
.productCartLoader {
  min-width: 218px;
  max-width: 218px;
  height: 306px;
}
.compareProductsBlock {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 52px;
  width: 100%;
}
.comparenProductsInfo {
  width: 100%;
  padding-bottom: 30px;
  display: flex;
  flex-direction: column;
  gap: 14px;
}
.comparedItemTitle {
  font-size: 18px;
  text-transform: uppercase;
  font-weight: bold;
}
.comparedItemValue {
  display: flex;
  width: 100%;
  padding: 18px 20px;
  gap: 20px;
  min-width: 480px;
  justify-content: flex-start;
  align-items: flex-start;
  background-color: var(--back-gray);
}
.comparedItemValue span {
  min-width: 218px;
  width: calc(100% / 3 - 10px);
  font-size: 14px;
}
@media only screen and (max-width: 992px) {
  .container {
    overflow: auto;
  }
  .compareProductsBlock {
    column-gap: 40px;
  }

  .container::-webkit-scrollbar-track {
    background: #e4e4e4;
    border-radius: 4px;
  }
  .container::-webkit-scrollbar-thumb {
    background-color: var(--red);
    border-radius: 10px;
    background-clip: padding-box;
    transition: all 0.3s ease-out;
  }
  .container::-webkit-scrollbar {
    height: 4px;
    width: 4px;
  }
}
@media only screen and (max-width: 600px) {
  .comparedItemTitle,
  .comparedItemValue span {
    font-size: 11px;
  }
  .productCartLoader {
    min-width: 182px;
    max-width: 182px;
    height: 299px;
  }
  .plusProductCompare {
    min-width: 182px;
    max-width: 182px;
    height: 299px;
  }
}
