.cart_content {
  overflow: hidden;
}
.cart_main {
  padding-top: 32px;
}
.cart_main_title_block {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 14px;
}

.cart_content_title {
  color: var(--red);
  opacity: 0.8;
  font-size: 18px;
  font-weight: bold;
  text-transform: uppercase;
}
@media only screen and (max-width: 768px) {
  .cart_main {
    padding-top: 0;
  }
}
