.bankItems {
  width: calc(100% / 6 - 20px);
  min-width: 220px;
  height: 118px;
  background: #ffffff;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.05);
  border-radius: 10.5908px;
  padding: 30px 0;
  border: 1px solid transparent;
  cursor: pointer;
}

.bankItems:hover {
  border-color: #d10000;
}
.bankItemsImage {
  object-fit: contain;
  width: 100%;
  height: 100%;
  max-width: 225px;
  padding: 0 10px;
  box-sizing: border-box;
}
.bankItemsLink {
  min-width: max-content;
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 10px;
  font-size: 13px;
}
@media (max-width: 1280px) {
  .banksAbout {
    margin: 30px 0;
  }
}
@media (max-width: 1024px) {
  .bankItems {
    width: calc(100% / 4 - 20px);
  }
}
@media (max-width: 768px) {
  .chooseBanksInfoTitle {
    font-size: 18px;
    margin: 0;
  }
  .chooseBanksInfoSecondTitle {
    font-size: 16px;
  }
  .bankItems {
    width: 100%;
    padding: 20px;
  }
  .bankItemsLink {
    margin-top: 0;
  }
  .bankItems {
    width: calc(100% / 3 - 20px);
  }
}
@media (max-width: 600px) {
  .bankItems {
    min-width: 46%;
    width: 46%;
    padding: 20px;
  }
}
@media (max-width: 430px) {
  .bankItems {
    min-width: 46%;
    width: 46%;
    padding: 20px;
  }
  .bankItemsLink {
    font-size: 8px;
  }
}
