.container {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  column-gap: 30px;
  overflow: hidden;
}
.leftContent {
  max-width: 950px;
  width: 100%;
  height: 388px;
  display: flex;
  position: relative;
  overflow: hidden;
}
.leftLoadContent {
  width: 100%;
  height: 100%;
  aspect-ratio: 10 / 4;
}
.leftSlider {
  border-radius: 10px;
  width: 100%;
}
.leftSliderImg {
  width: 100%;
  height: 100%;
  object-fit: contain;
  max-width: 3000px;
  border-radius: 10px;
}
.rightContent {
  display: flex;
  align-content: space-between;
  flex-direction: column;
  row-gap: 20px;
  width: 48%;
  min-width: 350px;
  overflow: clip;
}
.rightSlider {
  width: 100%;
  height: 180px;
}
.rightSlider img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 10px;
}
.rightSliderImg {
  width: 100%;
  max-width: 460px;
  object-fit: contain;
}
.slideBtn {
  z-index: 2;
  position: absolute;
  width: 50px;
  height: 50px;
  box-shadow: 0px -1px 16px rgb(0 0 0 / 12%);
  justify-content: center;
  align-items: center;
  border-radius: 90px;
  background-color: #fff;
  cursor: pointer;
  top: calc(50% - 25px);
}
.slidePrevArrow {
  left: 15px;
  transform: rotate(180deg);
}
.slideNextArrow {
  right: 15px;
}
.slideBtn:hover svg path {
  stroke: var(--red);
}
@media only screen and (max-width: 1280px) {
  .container {
    flex-direction: column;
    row-gap: 20px;
    justify-content: center;
  }
  .leftContent {
    max-width: none;
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    position: relative;
    overflow: clip;
  }
  .rightContent {
    flex-direction: row;
    column-gap: 20px;
    width: 100%;
    min-width: 100%;
    justify-content: space-between;
    overflow: clip;
  }
  .rightSlider {
    width: 100%;
    max-width: 48%;
    height: auto;
  }
  .rightSliderImg {
    max-width: none;
  }
}
@media only screen and (max-width: 992px) {
  .rightContent {
    column-gap: 12px;
  }
}

@media only screen and (max-width: 600px) {
  .rightSlider img {
    border-radius: 5px;
  }
}
@media only screen and (max-width: 430px) {
  .container {
    row-gap: 8px;
  }
}
