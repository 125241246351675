:root {
  /* --red: #d10000; rgb(227, 19, 53); */
  --black: #282828;
  --white: #fff;
  --gray: #f7f7fb;
  --gray-20: #f9f9f9;
  --back-gray: #f7f7fb;
  --dark-gray: #eaeff5;
  --gray-bold: #444444;
  /* --dark-red: #980a21; */
  --green: #159859;
  --grey-shingle: #93939380;
  --red: #d30000;
  --dark-red: #c20000;
}
* {
  color: var(--black);
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Mardoto-Regular", "arial", -apple-system, BlinkMacSystemFont,
    "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transition: all 300ms ease-out;
  -webkit-tap-highlight-color: transparent;
}
.rc-slider-handle,
.rc-slider-track {
  transition: all 0ms ease-out;
  border-color: rgba(209, 0, 0, 0.8) !important;
}
.rc-slider-handle-dragging {
  box-shadow: 0 0 0 5px rgba(209, 0, 0, 0.4) !important;
}
body {
  overflow: hidden;
}
.no-scroll{
  overflow: hidden!important;
}
img,
svg {
  vertical-align: middle;
}
a {
  text-decoration-line: none;
}
h1 {
  font-size: 30px;
  font-weight: bold;
  font-family: "Mardoto-Regular";
  text-transform: uppercase;
}
input:focus {
  outline: none;
  border-color: var(--green);
}
.wrapper {
  max-width: 1440px;
  width: 100%;
  margin: 0 auto;
  /* padding: 0 40px; */
  margin-bottom: 60px;
  position: relative;
  overflow-x: hidden;
}
.title {
  font-size: 30px;
  font-weight: bold;
  text-transform: uppercase;
  margin: 20px 0;
}

/* swiper */
.swiper-scrollbar-drag {
  background-color: var(--red) !important;
}
.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
  width: max-content !important;
  left: 50% !important;
  right: 50% !important;
  transform: translate(-50%, -50%);
  bottom: 26px !important;
  background: white;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 26px;
  padding: 0 16px !important;
}
.swiper-pagination .swiper-pagination-bullet.swiper-pagination-bullet-active {
  width: 10px;
  height: 10px;
  padding: 0;
}
.swiper-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet,
.swiper-pagination-horizontal.swiper-pagination-bullets
  .swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  margin: 0 7px !important;
  background-color: var(--black);
}
.swiper-pagination {
  display: none !important;
}

.scrollRow {
  display: flex;
  flex-direction: row;
  overflow: auto;
  column-gap: 5px;
  padding-bottom: 20px;
}
.scrollRow::-webkit-scrollbar {
  height: 0;
  width: 0;
}
.scrollBar::-webkit-scrollbar {
  height: 4px;
  width: 4px;
}
.scrollBar::-webkit-scrollbar-thumb {
  background-color: var(--red);
  border-radius: 10px;
  background-clip: padding-box;
  -webkit-transition: all 300ms ease-out;
  transition: all 300ms ease-out;
}
.scrollBar::-webkit-scrollbar-track {
  background: #e4e4e4;
  border-radius: 4px;
  height: 19px;
}

/* swiper end */
@media only screen and (max-width: 1440px) {
  .wrapper {
    padding: 0 40px;
  }
}

@media only screen and (max-width: 1024px) {
  .wrapper {
    padding: 0 30px;
    margin-bottom: 40px;
  }
}
@media only screen and (max-width: 992px) {
  .productCard .swiper-slide {
    height: max-content !important;
  }
  /* JIVOCHAT */
  .button_af6a {
    margin-bottom: 150px !important;
  }
}
@media only screen and (max-width: 768px) {
  .swiper-pagination {
    display: none !important;
  }
  .title {
    font-size: 18px !important;
  }
}
@media only screen and (max-width: 600px) {
  .wrapper {
    padding: 0 20px;
    margin-bottom: 30px;
  }
}

@media only screen and (min-width: 600px) {
  .swiper:hover .swiper-pagination {
    display: flex !important;
  }
}

/*  JIVOCHAT */
.button_af6a {
  margin-bottom: 80px !important;
}
._orientationRight_bc2d .button_a597 {
  margin-right: 7px !important;
}
._orientationLeft_e7ca.wrap_a898 {
  left: auto !important;
  right: 0px !important;
}
.popup_c40a.__left_d4c1 {
  left: auto !important;
  right: 0 !important;
}
.popupBlock_fdec.__left_d4c1 {
  left: auto !important;
  right: 0 !important;
}

/* /Toastify */
.Toastify,
.Toastify__toast-container,
.Toastify__toast-container--top-right {
  z-index: 99999999999 !important;
}
.style_product__wrapper__GCQmc .swiper-pagination {
  display: flex !important;
  position: absolute;
  /* bottom: -20px !important; */
}
