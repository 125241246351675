.imgBlock {
  display: grid;
  grid-template-columns: 80px 1fr;
  gap: 20px;
  height: 600px;
  width: calc(100% - 598px);
}

.imgBlockModal {
  width: 100%;
  justify-content: center;
  grid-template-columns: 80px 1fr 80px;
}

.imgBlock .mainImg {
  width: 100%;
  height: 100%;
  cursor: pointer;
  position: relative;
  object-fit: contain;
}
.mainImg .swiper-zoom-container {
  height: 100% !important;
}
.miniImgs {
  width: 100%;
  justify-content: flex-start;
  gap: 18px;
  max-height: 100%;
}

.miniLoader {
  width: 100%;
  height: 100%;
}

.miniImg {
  width: 80px;
  height: 80px;
  padding: 7.91px;
  border-radius: 11.86px;
  border: 1.58px solid #d8d8d8;

  &:hover {
    border-color: var(--red);
  }
}

.miniImgActive {
  border-color: var(--red);
}

.miniImg img,
.miniImg video {
  width: 100%;
  width: 100%;
  cursor: pointer;
}

.slideBtn {
  z-index: 2;
  position: absolute;
  width: 50px;
  height: 50px;
  box-shadow: 0px -1px 16px rgb(0 0 0 / 12%);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 90px;
  background-color: #fff;
  cursor: pointer;
  top: calc(50% - 25px);
}
.slidePrevArrow {
  left: 15px;
  transform: rotate(180deg);
}
.slideNextArrow {
  right: 15px;
}
.slideBtn:hover svg path {
  stroke: var(--red);
}

.zoomContainer {
  height: 100%;
  width: 100%;
}

.imgBlock div div img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  background-color: #fff;
}

.mainImgWrapper {
  position: relative;
  max-width: 698px;
  overflow: hidden;
  width: 100%;
  height: 600px;
}

.mainImgWrapperModal {
  justify-content: center;
  max-width: none;
}

.thumbnailWrapper {
  height: 600px;
}

@media screen and (max-width: 1300px) {
  .imgBlock {
    width: calc(100% - 500px);
  }
}

@media screen and (max-width: 1200px) {
  .imgBlock {
    grid-template-columns: 100%;
    grid-template-rows: 600px 80px;
    height: auto;
  }

  .thumbnailWrapper {
    height: 80px;
    grid-row: 2;
  }
}

@media screen and (max-width: 1024px) {
  .imgBlock {
    width: 100%;
    max-width: 100%;
    /* height: 600px; */
    grid-template-rows: 490px 80px;
  }

  .mainImgWrapper {
    max-width: 100%;
    width: 100%;
    height: 100%;
  }
  .mainImg {
    height: 100% !important;
  }

  .thumbnailWrapper {
    max-width: 100%;
    width: 100%;
  }
}

@media screen and (max-width: 600px) {
  .miniImgs {
    gap: 12px;
  }
  .miniImg {
    width: 73px;
    min-width: 73px;
    height: 73px;
  }
}
