.catalog_item {
  display: flex;
  align-items: center;
  gap: 16px;

  padding: 12px 0 16px 0;
  border-bottom: 1px solid rgba(40, 40, 40, 0.1);
}
.catalog_item_name {
  font-size: 15px;
  cursor: pointer;
}
.catalog_item_name:hover {
  color: var(--red);
}
.catalog_item_img {
  max-width: 20px;
}
.catalog_item_img img {
  width: 100%;
  height: 100%;
}
