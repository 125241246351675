.creditBanner{
    width: 100%;
}
.creditBannerImg{
    width: 100%;
}
.blogSection{
  margin-top: 80px;
}
.main {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 82px 0 58px 0;
    gap: 20px;
  }
  .titleBlock {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  .moreBtn {
    font-size: 16px;
    cursor: pointer;
    text-transform: uppercase;
    text-decoration: underline;
  }
  .titleBlock .moreBtn:hover {
    color: var(--red);
    text-decoration: none;
  }
  .ArticleItem {
    display: flex;
    flex-direction: column;
    row-gap: 16px;
    max-width: 336px;
    width: 23%;
    cursor: pointer;
    flex-grow: 4;
  }
    
  .ArticleItemImg {
    max-height: 104px;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 6px;
  }
  .ArticleItemTitle {
    font-size: 18px;
    min-height: 48px;
    font-weight: bold;
    max-height: auto;
  }
  .ArticleItemTitle:hover {
    color: var(--red);
  }
  .ArticleItemDate {
    color: #636363;
    font-size: 16px;
  }
  .loadContentImg {
    width: 100%;
    height: auto;
    aspect-ratio: 10 / 3.3;
  }
  .loadContentTitle {
    width: 100%;
    height: 48px;
  }
  .loadContentDate {
    width: 100%;
    height: 16px;
  }
  @media (max-width: 1300px) {
    .ArticleItemDate {
      display: none;
    }
    .loadContentDate {
      display: none;
    }
    .ArticleItemImg {
      max-height: 92px;
    }
  }
  
  @media only screen and (max-width: 1024px) {
    .ArticleItem {
      width: 30%;
      flex-grow: 3;
    }
    .ArticleItemTitle{
      font-size: 12px;
    }
  }
  @media only screen and (max-width: 768px) {
    .blogSection{
      margin-top: 40px;
    }
    .moreBtn {
      font-size: 14px;
    }
    .ArticleItem {
      width: 33%;
      /* min-width: fit-content; */
    }
    .ArticleItemTitle{
      font-size: 12px;
    }
  }
  
  @media only screen and (max-width: 600px) {
    .ArticleItem {
      width: 100%;
    }
    .blogSection{
      margin-top: 30px;
    }
    .main {
      padding-bottom: 20px;
      justify-content: center;
    }    
  }
  