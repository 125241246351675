.container {
  display: flex;
  flex-direction: column;
  width: 218px;
  border: 1px solid #eeeeee;
  border-radius: 5px;
  padding: 15px;
  height: 100%;
}
@media (min-width: 900px) {
  .container:hover {
    -webkit-box-shadow: 0px 0px 30px -5px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0px 0px 30px -5px rgba(0, 0, 0, 0.5);
    box-shadow: 0px 0px 30px -5px rgba(0, 0, 0, 0.5);
    transform: scale(1.04);
  }
}
.fullWidth {
  position: relative;
  border: 1px solid #eeeeee;
  padding: 20px;
  column-gap: 20px;
  display: flex;
}
.flexContainer {
  width: calc(100% / 5 - 22px);
}
.cartContainerChangeStyle {
  display: flex;
  flex-direction: column;
  max-width: 218px;
  border: 1px solid #eeeeee;
  border-radius: 5px;
  padding: 15px;
  height: 100%;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.imageBlock {
  width: 100%;
  height: 176px;
  position: relative;
}
.fullWidth .imageBlock {
  min-width: max-content;
}
.brandImg {
  object-fit: contain;
  max-height: 20px;
  max-width: 60px;
  width: 100%;
}
.image {
  height: 176px;
  padding-top: 25px;
  object-fit: contain;
}
.descContainer {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}
.fullWidth .descContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.fullWidth .descriptions {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
}
.fullWidth .btnsContainer {
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}
.fullWidth .topPart {
  width: 50%;
}

.descContainer a:hover .productName {
  text-decoration: underline;
}
.categoryName {
  text-transform: uppercase;
  color: rgba(40, 40, 40, 0.4);
  font-family: "Mardoto-Bold";
  width: 100%;
  font-size: 10px;
  margin-bottom: 5px;
  word-break: break-word;
  height: 28px;
  margin-top: 5px;
}
.productName {
  width: 100%;
  max-width: 200px;
  font-size: 13px;
  overflow: hidden;
  line-height: 16px;
  height: 32px;
}
.price {
  font-size: 18px;
  font-weight: bold;
}

.newPrice {
  font-size: 18px;
  font-weight: bold;
  color: var(--red);
}
.oldPriceContainer {
  position: relative;
}
.oldPrice {
  font-size: 12px;
  color: #898383;
}
.oldPriceContainer .line {
  width: 100%;
  position: absolute;
  border-bottom: 1px solid var(--red);
  transform: rotate(-6deg);
  left: 0;
  top: 6px;
}
.priceMounth {
  width: max-content;
  font-size: 12px;
  color: var(--gray-bold);
  margin-top: 10px;
}
.priceMounthCash{
  font-weight: bold;
}
.btnsContainer {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  column-gap: 14px;
  justify-content: flex-start;
  align-items: center;
}

.cardBtn {
  padding: 10px 11px;
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: var(--red);
  border-radius: 5px;
  column-gap: 6px;
}
.cardBtn:hover {
  background-color: var(--dark-red);
}
.cardBtnSvg {
  width: 15px;
  height: 15px;
}
.cardBtnSvg path {
  fill: #fff;
}
.cardBtn span {
  color: #fff;
  font-size: 11px;
}
.compareBtn:hover svg rect {
  fill: var(--red);
}
.heartSvg {
  cursor: pointer;
}
.heartSvg:hover path {
  fill: var(--red);
}
.stickersBlock {
  position: absolute;
  top: 126px;
  right: 22px;
  display: flex;
  column-gap: 4px;
  align-items: center;
  justify-content: flex-end;
  width: max-content;
  z-index: 999;
}
.giftBox {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  min-width: 30px;
  width: 30px;
  height: 30px;
  min-height: 30px;
  justify-content: center;
  background: radial-gradient(
    96.77% 96.77% at 93.33% 96.77%,
    #af001d 0.51%,
    #e31335 100%
  );
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}
.giftBox:hover .giftHovered {
  display: flex;
}
.giftHovered {
  display: none;
  padding: 15px;
  bottom: 30px;
  left: -40px;
  gap: 5px;
  text-align: start;
  border-radius: 10px;
  position: absolute;
  max-width: 244px;
  width: max-content;
  font-family: "Mardoto-Regular";
  background-color: var(--white);
  box-shadow: 0px 4.82129px 48.2129px rgb(0 0 0 / 12%);
}
.giftHoveredMain {
  border: 1px solid #dfdfe2;
  border-radius: 2px;
  display: flex;
  cursor: pointer;
  flex-direction: column;
  row-gap: 14px;
  padding: 10px;
  min-width: 1px;
  width: 100%;
  align-items: flex-start;
}
.giftHovered:hover .giftHoveredMain {
  border-color: var(--red);
}
.giftHoveredImg {
  height: 40px;
  max-width: 60px;
  object-fit: contain;
}
.stickerImg {
  max-width: 50px;
  max-height: 30px;
  object-fit: contain;
  max-width: 60px;
}
.cashbackContainer {
  margin-top: 10px;
}
.cashbackText {
  font-size: 14px;
  font-weight: bold;
}
.cashbackPrice {
  color: var(--red);
  font-size: 16px;
  font-weight: bold;
}
@media only screen and (max-width: 1280px) {
  .flexContainer {
    width: calc(100% / 4 - 24px);
    max-width: none;
  }
}
@media only screen and (max-width: 1120px) {
  .productsList {
    gap: 15px;
  }
  .flexContainer {
    width: calc(100% / 3 - 10px);
  }
}
@media only screen and (max-width: 992px) {
  .image {
    height: 100%;
    width: 100%;
  }
  .productCard .slideItem {
    height: 350px !important;
  }
}
@media only screen and (max-width: 635px) {
  .container {
    padding: 10px;
    width: 175px;
  }
  .cardBtn {
    padding: 13px 18px;
    column-gap: 7px;
  }
}
@media only screen and (max-width: 425px) {
  .container {
    padding: 8px;
    width: 160px;
  }
  .cardBtn {
    padding: 11px 12px;
    column-gap: 5px;
  }
}
@media only screen and (max-width: 600px) {
  .flexContainer {
    width: calc(100% / 2 - 6px);
  }
}

@media only screen and (max-width: 375px) {
  .flexContainer {
    width: 100%;
  }
}

.promoPriceContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  flex-wrap: wrap;
  column-gap: 8px;
  width: 100%;
}
/*Mobile Responsivi hamar*/
.btnsContainer_change {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  column-gap: 14px;
  justify-content: flex-start;
  align-items: center;
}
.btnsContainer_change .cardBtn {
  width: 100%;
}
