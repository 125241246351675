
.delete_modal_info{
  display: flex;
    align-items: center;
    flex-direction: column;
    gap: 20px;
}
.small2_warning{
    display: flex;
    align-items: center;
    border-color: #facea8;
    color: #f8bb86;
    position: relative;
    justify-content: center;
    width: 5em;
    height: 5em;
    border: 0.25em solid rgba(0,0,0,0);
    border-radius: 50%;
    border-color: #facea8;
    font-family: inherit;
}
.small2_icon_content{
    display: flex;
    align-items: center;
    font-size: 3.75em;
}

.small2_title{
    max-width: 100%;
    margin: 0;
    color: inherit;
    font-size: 1.875em;
    font-weight: 600;
    text-align: center;
    position: relative;
    text-transform: none;
    word-wrap: break-word;
    opacity: .7;

}
.small2_sub_title{
    opacity: .7;

}
.delete_modal_info_btn button{
    margin: 0.3125em;
    padding: 0.625em 1.1em;
    transition: box-shadow .1s;
    box-shadow: 0 0 0 3px rgba(0,0,0,0);
    font-weight: 500;
}
.tools_blue_btn{
    display: inline-block;
    background-color: rgb(48, 133, 214);
    border: 0;
    border-radius: 0.25em;
    color: #fff;
    font-size: 1em;
    cursor: pointer;
}
.tools_red_btn{
    display: inline-block;
    background-color: rgb(221, 51, 51);
    border: 0;
    border-radius: 0.25em;
    color: #fff;
    font-size: 1em;
    cursor: pointer;


}
.tools_blue_btn:hover{
    background-color: rgb(38, 114, 185);

}
.tools_red_btn:hover{
    background-color: rgb(168, 36, 36);

}