.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 15px;
  max-width: 344px;
  min-width: 130px;
  width: 100%;
  /* max-height: 439px; */
  background: transparent;
  position: relative;
  border-radius: 15px;

  & > svg {
    position: absolute;
    inset: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
  }
}

.InCardSlider {
  width: 100%;
  margin: 33px auto 0;
  height: fit-content;
  position: relative;
  z-index: 0;
}

/* .product__wrapper {
  height: 204px;
} */

.product__wrapper > .swiper-pagination {
  position: absolute;
  display: block !important;
}

.flexContainer {
  width: calc(100% / 6 - 22px);
}
.cartContainerChangeStyle {
  display: flex;
  flex-direction: column;
  max-width: 218px;
  border: 1px solid #eeeeee;
  border-radius: 5px;
  padding: 15px;
  height: 100%;
}

.headerLeft {
  position: absolute;
  z-index: 2;
  left: 16px;
  top: 17px;
  display: flex;
  gap: 11px;
  align-items: flex-start;
  justify-content: center;
  width: max-content;
  flex-direction: column;
}

.headerRight {
  position: absolute;
  z-index: 2;
  right: 16px;
  top: 17px;
}

.brandImg {
  object-fit: contain;
  max-height: 20px;
  max-width: 60px;
  width: 100%;
  object-position: left;
}

.image {
  height: 176px;
  padding-top: 25px;
  object-fit: contain;
}

/* .descContainer {
  display: flex;
  flex-direction: column;
  row-gap: 10px;
} */

.descContainer a:hover .productName {
  text-decoration: underline;
}
.categoryName {
  text-transform: uppercase;
  color: rgba(40, 40, 40, 0.4);
  font-family: "Mardoto-Bold";
  width: 100%;
  font-size: 10px;
  margin-bottom: 5px;
  word-break: break-word;
  height: 28px;
  margin-top: 5px;
}

.productName {
  width: 100%;
  max-width: 200px;
  font-size: 13px;
  overflow: hidden;
  line-height: 16px;
  height: 32px;
}

.price {
  font-size: 17px;
  font-weight: bold;
}

.newPrice {
  font-size: 16px;
  font-weight: bold;
  color: var(--red);
}
.oldPriceContainer {
  position: relative;
}
.oldPrice {
  font-size: 12px;
  color: #898383;
}
.oldPriceContainer .line {
  width: 100%;
  position: absolute;
  border-bottom: 1px solid var(--red);
  transform: rotate(-6deg);
  left: 0;
  top: 6px;
}
.priceMounth {
  width: max-content;
  font-size: 12px;
  color: var(--gray-bold);
  margin-top: 10px;
}

.cardBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: var(--red);
  border-radius: 10px;
  column-gap: 10px;
  width: 39.13px;
  height: 39.13px;
  position: absolute;
  right: 0;
  bottom: 0;
}

.cardBtn:hover {
  background-color: var(--dark-red);
}

.cardBtnSvg {
  width: 21.13px;
  height: 21.13px;
}
.cardBtnSvg path {
  fill: #fff;
}
.cardBtn span {
  color: #fff;
  font-size: 12px;
}
.compareBtn:hover svg rect {
  fill: var(--red);
}
.heartSvg {
  cursor: pointer;
  margin-bottom: 19px;
}
.heartSvg:hover path {
  fill: var(--red);
}

.giftBox {
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  min-width: 30px;
  width: 30px;
  height: 30px;
  min-height: 30px;
  justify-content: center;
  background: radial-gradient(
    96.77% 96.77% at 93.33% 96.77%,
    #af001d 0.51%,
    #e31335 100%
  );
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
}
.giftBox:hover .giftHovered {
  display: flex;
}
.giftHovered {
  display: none;
  padding: 15px;
  top: 30px;
  /* right: -5px; */
  gap: 5px;
  text-align: start;
  border-radius: 10px;
  position: absolute;
  max-width: 244px;
  width: max-content;
  font-family: "Mardoto-Regular";
  background-color: var(--white);
  box-shadow: 0px 4.82129px 48.2129px rgb(0 0 0 / 12%);
}

.guarantee {
  display: flex;
  flex-direction: column;
  width: fit-content;
}
.guarantee span {
  display: flex;
  align-items: center;
  font-size: 10px;
  width: 100%;
  color: var(--white);
  background: linear-gradient(180.24deg, #e31335 0.21%, #c10322 48.39%);
  border-radius: 4px 4px 0 0;
  justify-content: center;
  font-size: 8px;
  padding: 4px;
  text-transform: uppercase;
  text-align: center;
}
.guarantee span:last-child {
  border-radius: 0 0 4px 4px;
  font-size: 9px;
  font-weight: bold;
  box-shadow: 0px 4px 5px rgba(0, 0, 0, 0.1);
  color: var(--black);
  background: var(--white) !important;
}

.giftHoveredMain {
  border: 1px solid #dfdfe2;
  border-radius: 2px;
  display: flex;
  cursor: pointer;
  flex-direction: column;
  row-gap: 14px;
  padding: 10px;
  min-width: 1px;
  width: 100%;
  align-items: flex-start;
}
.giftHovered:hover .giftHoveredMain {
  border-color: var(--red);
}
.giftHoveredImg {
  height: 40px;
  max-width: 60px;
  object-fit: contain;
}
.stickerImg {
  max-width: 50px;
  max-height: 30px;
  object-fit: contain;
  max-width: 60px;
  object-position: left;
}
.cashbackContainer {
  margin-top: 10px;
  height: 12px;
}
.cashbackText {
  font-size: 14px;
  font-weight: bold;
}
.cashbackPrice {
  color: var(--red);
  font-size: 16px;
  font-weight: bold;
}

.promoPriceContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  column-gap: 20px;
  width: calc(100% - 33px);
}

@media only screen and (max-width: 1280px) {
  .flexContainer {
    width: calc(100% / 4 - 24px);
    max-width: none;
  }
}
@media only screen and (max-width: 1024px) {
  .flexContainer {
    width: 31%;
  }
}
@media only screen and (max-width: 992px) {
  .image {
    height: 100%;
    width: 100%;
  }
  .stickersBlock {
    top: 80px;
  }
  .productCard .slideItem {
    height: 350px !important;
  }
}

@media screen and (min-width: 900px) {
  .container:hover {
    transform: scale(1.04);
    & > svg {
      filter: drop-shadow(0 0 10px #939393c8);
      & path {
        stroke-width: 0 !important;
      }
    }
  }
}

@media screen and (max-width: 635px) {
  .container {
    padding: 10px;
    /* width: 175px; */
  }
}
@media screen and (max-width: 600px) {
  .flexContainer {
    width: calc(100% / 2 - 6px);
  }
  .container {
    padding: 8px;
    max-width: 178px;
    border-radius: 5px;
  }
  /* .product__wrapper {
    height: 118.64px;
  } */
  .InCardSlider {
    width: 100%;
    margin: 10px auto 0;
  }
  .header {
    right: 10px;
    top: 19px;
  }
  .heartSvg {
    margin-bottom: 10px;
  }
  .cardBtn {
    width: 27.03px;
    height: 26.13px;
    border-radius: 4.96px;
  }

  .cardBtnSvg {
    width: 14.87px;
    height: 14.87px;
  }

  .promoPriceContainer {
    width: calc(100% - 35px);
    column-gap: 7px;
  }

  .newPrice {
    font-size: 12px;
  }

  .oldPrice {
    font-size: 8px;
  }
  .price {
    font-size: 12px;
    font-weight: bold;
  }
}

@media screen and (max-width: 375px) {
  .flexContainer {
    width: 100%;
  }
}
