.container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  z-index: 99999999999;
  transform: translateX(-100%);
  display: flex;
  flex-direction: row;
  padding: 30px;
}
.openMenu {
  transform: translateX(0%);
}
.catalogList {
  width: 20%;
  height: 100%;
  overflow-y: scroll;
  padding-right: 15px;
}
.catalogList .menuItem {
  display: flex;
  padding: 10px;
  cursor: pointer;
  justify-content: space-between;
  align-items: center;
}
.menuItem img {
  width: 20px;
  height: 20px;
  object-fit: contain;
}
.menuItem .menuItemDynamicImg {
  width: 100%;
  height: 50px;
  align-self: center;
}
.menuItem a {
  font-size: 14px;
  margin-left: 15px;
}
.menuItemDynamic a {
  margin-left: 0;
}
.catalogList .menuItemActive,
.catalogList .menuItem:hover {
  border-radius: 5px;
  background-color: #eaeff5;
}
.catalogList .menuItemActive a,
.catalogList .menuItem:hover a {
  color: var(--red);
}
.catalogContent {
  /* width: 68%; */
  width: 100%;
  height: 100%;
  padding: 20px;
  border-left: 1px solid #0000001a;
  margin: 0 15px;
  overflow-y: scroll;
}
.closeBtn {
  cursor: pointer;
  z-index: 100;
}
.closeBtn:hover rect {
  fill: var(--red);
}
.bannerImage {
  height: 100%;
  width: 100%;
  object-fit: contain;
}
.content {
  margin-top: 30px;
  /* overflow-y: scroll; */
  height: 100%;
}
.titleContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 40px;
  column-gap: 15px;
}
.content img {
  width: 20px;
  height: 20px;
  object-fit: contain;
}
.titleContainer .title {
  color: rgb(153, 3, 6);
  font-size: 20px;
}
.titleContainer .count {
  padding: 4px 16px;
  color: var(--white);
  font-weight: bold;
  background-color: var(--red);
  border-radius: 90px;
  font-size: 18px;
}
.subCategories {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  align-items: flex-start;
  justify-content: space-between;
}
.subCategoryTitle {
  margin-top: 15px;
  display: flex;
  column-gap: 15px;
  cursor: pointer;
}

.subCategoryTitle h6 {
  font-weight: bold;
  font-size: 16px;
}
.subCategorySpan {
  font-size: 15px;
}
.subCategoryTitle:hover h6 {
  color: var(--red);
}
.subCategorySubs,
.subCategorySubs div {
  margin-top: 15px;
}
.brandsContainer {
  display: flex;
  justify-content: center;
  padding: 30px 0;
}
.brandItem {
  width: 135px;
  height: 55px;
  min-height: 50px;
  /* background: #eaeff5; */
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px;
  padding: 5px 10px;
  box-sizing: border-box;
  border-radius: 5px;
}

.brandImg {
  width: 100%;
  height: 100%;
  max-height: 40px;
  object-fit: contain;
}
.brandArrow {
  position: absolute;
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 99;
}
.arrowTop {
  top: -30px;
}
.arrowBottom {
  bottom: -30px;
}
.arrowTop svg {
  transform: rotate(-90deg);
}
.arrowBottom svg {
  transform: rotate(90deg);
}
@media (min-width: 907px) and (max-width: 1400px) {
  .catalogList {
    width: 31%;
  }
}
