.container {
    display: flex;
    flex-direction: column;
    row-gap: 14px;
    padding-bottom: 20px;
    width: 100%;
}

.enableSlider {
    display: flex;
    justify-content: space-between;
}

.sliderStyle {
    width: 92%;
    margin: 5px auto 5px !important;
}
.currentValues{
    display: flex;
    justify-content: space-between;
}
.trackStyle {
    background-color: #d10000;
}

.values {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 96%;
    margin: auto;
}

.valueItem {
    margin: 0 !important;
}
