
.details_description_loader_item {
    margin-top: 20px;
}

.details_description_loader {
    width: 50%;
    margin: 0 auto;
    height: 20px;


}

.details_description {
    font-size: 18px;
    color: #898383;
    text-align: center;
    line-height: 30px;
    margin-bottom: 32px;
}


.details_all_prods_catalog {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

.details_all_prods_catalog_loader {
    width: 16%;
    height: 200px;
}




.details_footer_banners {
    display: flex;
    gap: 20px;
    margin: 50px 0;
}

.details_footer_banners_item {
    max-height: 196px;
}

.details_footer_banners_item img {
    border-radius: 5px;
    max-width: 100%;
    max-height: 100%;

}

.details_footer_banners_loader {
    height: 200px;
    width: 100%;
}
.details_description p {
    font-size: 16px;
    line-height: 26px;
    color: #687083;
    margin-bottom: 0;
}


@media (max-width: 744px ) {
    .details_big_banner {
        margin-bottom: 20px;
    }

    .sales_block_svg_title {

        margin-top: 15px;
        margin-bottom: 26px;
    }

    .sales_block_svg_title {

        margin-top: 15px;
        margin-bottom: 16px;
    }

    .sales_block_svg_title {

        gap: 10px;

    }

    .sales_block_svg_title h3 {

        font-size: 12px;

    }

    .sales_block_svg_title span {
        padding: 4px 10px;
        font-size: 15px;
    }



    .details_big_banner_loader {
        height: 200px;
    }

    .details_description_loader_item {
        margin-top: 10px;
    }

    .details_description_loader {
        width: 100%;
        height: 15px;


    }

    .details_footer_banners_loader {
        height: 150px;
    }
}

@media (max-width: 460px ) {
    .details_big_banner {
        margin-bottom: 5px;
    }


    .details_big_banner_loader {
        height: 100px;
    }

    .details_description_loader_item {
        margin-top: 5px;
    }

    .details_footer_banners_loader {
        height: 100px;
    }
}

@media (max-width: 1337px) {
    .details_all_prods_catalog_loader {
        width: 19%;
    }
}

@media (max-width: 1058px) {
    .details_all_prods_catalog_loader {
        width: 23%;
    }
}

@media (max-width: 868px) {
    .details_all_prods_catalog_loader {
        width: 32%;
    }
}

@media (max-width: 622px) {
    .details_all_prods_catalog_loader {
        width: 48%;
    }

    .details_footer_banners {
        flex-direction: column;
        gap: 20px;
        margin: 30px 0;
    }

    .details_banner_slider {
        padding-top: 30px;
    }

}

@media (max-width: 402px) {
    .details_all_prods_catalog_loader {
        width: 100%;
    }


}

.details_banner_slider {
    max-width: 1170px;
    margin: 0 auto;
    padding-top: 70px;


}