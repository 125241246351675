.background {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(40, 40, 40, 0.4);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: center;
}
.main {
  max-width: 530px;
  width: 100%;
  max-height: 90vh;
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  overflow: auto;
  border-radius: 5px;
  padding: 40px 36px;
  background-color: var(--white);
}
.title {
  text-align: center;
  font-size: 24px;
  padding-bottom: 14px;
  font-weight: bold;
  color: black;
  text-transform: uppercase;
}
.description {
  font-size: 14px;
  text-align: center;
  font-weight: 600;
  color: rgba(40, 40, 40, 0.6);
}
.btn {
  max-width: 374px;
  width: 100%;
  padding: 16px;
  border-radius: 5px;
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--red);
  cursor: pointer;
}
.btn span {
  color: var(--white);
  font-size: 14px;
}

.closeBtn {
  align-self: flex-end;
  cursor: pointer;
}

@media screen and (max-width: 600px) {
  .main {
    margin: 0 20px;
  }
}
