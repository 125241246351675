.zoomImageWrapper {
  max-width: 100%;
  max-height: 100%;
  width: 100%;
  height: 100%;
}

.zoomControl {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 5px 10px;
  position: absolute;
  bottom: 15px;
  left: 50%;
  z-index: 100;
  transform: translateX(-50%);
  width: 310.49px;
  height: 60.75px;
  border-radius: 37.5px;
}

.zoomControl button {
  background: none;
  border: none;
  cursor: pointer;
  color: white;
  margin: 0 5px;
}

.zoomControl input[type="range"] {
  margin: 0 10px;
  cursor: pointer;
  background-color: transparent;
  appearance: none;
  flex: 1 1;
}

.zoomControl input[type="range"]::-webkit-slider-runnable-track {
  background-color: #ccc;
  height: 4px;
  border-radius: 2px;
}

.zoomControl input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  background-color: #e91e63;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  margin-top: -5px;
}

.imageContainer {
  width: 100%;
  height: 100%;
  overflow: hidden; /* Hide overflow for the zoom effect */
  display: flex;
  align-items: center;
  justify-content: center;
}

.zoomedImage {
  width: 100%;
  height: auto;
  transition: transform 0.3s ease; /* Smooth zoom transitions */
}

@media screen and (max-width: 425px) {
  .zoomControl {
    width: 200px;
    height: 40px;
    border-radius: 30px;
  }

  .zoomControl button svg {
    width: 20px;
  }

  .zoomControl input[type="range"] {
    margin: 0;
  }
}
