.InfoAboutPage {
  font-size: 18px;
  line-height: 30px;
  text-align: center;
  color: #898383;
  margin: 65px 0;
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.InfoAboutPage element div div {
  width: auto !important;
  max-width: 100% !important;
  height: auto !important;
}
.generalBanner img {
  border-radius: 5px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.twoBanersBlck {
  gap: 20px;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.twoBanersBlck a {
  width: 100%;
  height: 100%;
}
.twoBanersBlck img {
  border-radius: 5px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.titleBlck {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.titleBlck .moreBtn:hover {
  color: var(--red);
  text-decoration: none;
}
.topPicksProductBtn {
  text-align: end;
  text-decoration: underline;
  cursor: pointer;
}
.topPicksProductBtn:hover {
  color: #d10000;
  text-decoration: none;
}
.moreBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 12px;
  margin-top: 20px;
  background-color: #f7f7fb;
  border-radius: 5px;
  text-transform: uppercase;
  cursor: pointer;
  font-size: 16px;
  border: 1px solid transparent;
}
.generalBannerSection {
  margin: 44px 0;
}
.lastInfoBrand {
  background: #f7f7fb;
  border-radius: 5px;
  padding: 56px;
  color: #898383;
  margin: 62px 0;
  font-size: 18px;
  line-height: 150%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: auto;
  max-width: 100%;
}
.lastInfoBrand element div div {
  width: auto !important;
  max-width: 100% !important;
  height: auto !important;
}
.LoadContentGeneralBanner {
  height: 350px;
}
.LoadContentTwoBanersBlck {
  height: 350px;
}
@media only screen and (max-width: 1440px) {
  .promotionCategoryBlock {
    justify-content: flex-start;
  }
  .categoryBlockTitle {
    justify-content: flex-start;
  }
  .LoadContentGeneralBanner {
    height: 300px;
  }
  .LoadContentTwoBanersBlck {
    height: 300px;
  }
}
@media only screen and (max-width: 1024px) {
  .promotionCategoryBlock {
    margin-bottom: 20px;
  }
  .lastInfoBrand {
    padding: 40px 44px;
  }
  .LoadContentGeneralBanner {
    height: 200px;
  }
  .LoadContentTwoBanersBlck {
    height: 200px;
  }
}
@media only screen and (max-width: 768px) {
  .discoverBrands {
    gap: 14px;
  }
  .title {
    margin-bottom: 32px;
    font-size: 18px;
  }
  .secondTitle {
    font-size: 18px;
    margin-bottom: 20px;
  }
  .smartphonesTitle {
    font-size: 18px;
    margin-bottom: 20px;
  }
  .lastInfoBrand {
    padding: 30px 42px;
  }
}

@media only screen and (max-width: 430px) {
  .title {
    margin-bottom: 20px;
  }
  .twoBanersBlck {
    flex-wrap: wrap;
  }
  .LoadContentGeneralBanner {
    height: 96px !important;
  }
  .LoadContentTwoBanersBlck {
    height: 96px !important;
  }
}
