
.about_mini_description{
     margin-top: 20px;
}
.privacy_policy_description{
    font-size: 18px;
    line-height: 30px;
}

@media  (max-width: 768px){
    .privacy_policy_body h1{
        font-size: 24px;

    }
}



@media  (max-width: 600px){
    .privacy_policy_body h1{
        font-size: 20px;

    }
}