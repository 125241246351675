

.details_loader_categories_item {
    width: 20%;
    height: 40px;

}

.details_loader_categories {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 40px;
}
.details_vue3_all_prods_catalog{
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 48px;
    justify-content: center;
    margin-bottom: 64px;

}

.details_vue3_all_prods_catalog_loader {
    width: 16%;
    height: 200px;
}
