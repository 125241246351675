.container {
  display: flex;
  position: sticky;
  bottom: 0;
  right: 0;
  left: 0;
  width: 100%;
  z-index: 9999;
  align-items: center;
  column-gap: 10px;
  justify-content: space-around;
  padding: 15px 10px calc(env(safe-area-inset-bottom) + 15px) 10px;
  background-color: var(--white);
}
.container a {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.container a span {
  padding: 1px 5px;
  border-radius: 90px;
  color: var(--white);
  font-size: 12px;
  position: absolute;
  top: -15px;
  right: -19px;
  background-color: var(--red);
}
.container svg {
  cursor: pointer;
  width: 29px;
  height: 28px;
}
.container svg:hover path,
.container svg:hover rect {
  fill: var(--red);
}

@media only screen and (max-width: 600px) {
  .container svg {
    height: 19px;
    width: 19px;
  }
}
