
.compare_null_content{
    background: #f7f7fb;
    border-radius: 5px;
    padding: 82px 20px 56px 20px;
    display: flex;
    flex-direction: column;
    row-gap: 24px;
    align-items: center;
    margin-bottom: 40px;
    justify-content: center;
}
.empty_compare_img{
    max-width: 206px;
    width: 100%;

}


.empty_compare_page h1 {
    max-width: 600px;
    text-align: center;

}
@media (max-width: 768px){
    .compare_null_content h1 {
        font-size: 22px;
        text-align: center;

    }
}
@media (max-width: 600px) {
    .compare_null_content h1 {
        font-size: 18px;
        text-align: center;

    }

}

