
.cost{
    width: 100%;
    height: 100%;
    padding: 50px 60px;
}

.switch{
    margin-top: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .switch p{
      cursor: pointer;
      font-size: 22px;
      font-weight: 700;
      line-height: 26px;
      color:var(--black)
  }
@media(max-width:768px){
    .cost{
       width: 100%;
       padding: 20px 20px;
    }
    .switch p{
        font-size: 14px;
    }
}