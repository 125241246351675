.creditBanner {
  width: 100%;
  margin-bottom: 44px;
}
.creditBannerImg {
  width: 100%;
}
.description {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  margin-bottom: 44px;
  text-align: justify;
}
.LoadContentDesc {
  width: 100%;
  height: 100%;
  aspect-ratio: 10 / 2;
}
.descriptionText {
  text-align: left;
}
.LoadContent {
  width: 100%;
  height: 100%;
  aspect-ratio: 10 / 4;
}
.creditInfoBlock {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
  margin-bottom: 56px;
}
.LoadContentcreditInfoBlock {
  width: 100%;
  height: 100%;
  aspect-ratio: 10 / 5;
}
.chooseBanksInfo {
  display: flex;
  flex-direction: column;
  row-gap: 26px;
  width: 100%;
}
.chooseBanksInfoTitle {
  font-size: 30px;
  font-weight: bold;
  color: black;
  text-transform: uppercase;
}
.chooseBanksInfoSecondTitle {
  font-weight: bold;
  font-size: 18px;
}
.banksAbout {
  width: 100%;
  display: flex;
  /* flex-direction: column; */
  row-gap: 26px;
  gap: 20px;
  margin: 26px 0 60px 0;
  flex-wrap: wrap;
  justify-content: flex-start;
}

@media (max-width: 1280px) {
  .banksAbout {
    margin: 30px 0;
  }
}
@media (max-width: 1024px) {
}
@media (max-width: 768px) {
  .chooseBanksInfoTitle {
    font-size: 18px;
    margin: 0;
  }
  .chooseBanksInfoSecondTitle {
    font-size: 16px;
  }
}
@media (max-width: 430px) {
  .banksAbout {
    flex-direction: row;
    flex-wrap: wrap;
  }
}
