.personal_info_form {
    max-width: 530px;
    width: 100%;
    max-height: 90vh;
    display: flex;
    flex-direction: column;
    row-gap: 13px;
    overflow: auto;
    border-radius: 5px;
    background-color: var(--white);
    margin: 0 auto;
}
.personal_info_form span{
    font-style: normal;
    font-weight: 700;
    font-size: 20.6297px;
    line-height: 24px;
    text-transform: uppercase;
    color: #282828;
}

.add_personal_inputs {
    display: flex;
    gap: 20px;

}

.add_personal_inputs input {
    width: 100%;
    padding: 12px 24px;
    font-size: 14px;
    background-color: #f7f7fb;
    border-radius: 5px;
    border: 1px solid transparent;
}
.add_personal_inputs input::placeholder{
    opacity: .3;

}
.order_inputs_name{
     width: 100%;
}
.textarea_comment_order{
      min-height: 100px;
    width: 100%;
    padding: 12px 24px;
    font-size: 14px;
    background-color: #f7f7fb;
    border-radius: 5px;
    border: 1px solid transparent;
    resize: none;
    outline: none;
}
.add_personal_inputs input[type="number"]::-webkit-inner-spin-button{
    -webkit-appearance: none;
    margin: 0;
}
.add_personal_inputs input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.add_personal_inputs input[type="number"]{
    -moz-appearance: textfield;
}


.textarea_comment_order:focus {
    border-color: var(--green);
}
.add_personal_inputs input:focus {
    border-color: var(--green);
}


.inputError {
    border-color: var(--red) !important;
}
.order_submit{
    width: 100%;
    padding: 16px;
    font-size: 14px;
    color: var(--white);
    border: none;
    cursor: pointer;
    border-radius: 5px;
    background-color: var(--red);
    justify-content: center;
}
.order_submit:hover{
    background-color: #980a21;
}

@media (max-width: 600px) {
    .add_personal_inputs{
         flex-direction: column;
    }
    .order_form {
        padding: 26px 20px 16px 20px;

    }
}
.inputError{
     color: var(--red);
}