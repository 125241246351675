
.brand_see_all_item {
    padding: 5px 20px;
    max-width: 220px;
    width: calc(100% / 5 - 18px);
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-radius: 5px;
    border: 1px solid #eeeeee;
    justify-content: space-between;

}

.brands_img_item {
    width: 100%;
    height: 60px;
}

.brands_img_item img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.nameof_brand_item {
    font-size: 18px;
    font-weight: 600;
}

@media (max-width: 1024px) {
    .brand_see_all_item {
        width: calc(100% / 4 - 18px);
    }
}

@media (max-width: 741px) {
    .brand_see_all_item {
        width: calc(100% / 3 - 18px);
    }
}

@media (max-width: 439px) {
    .brand_see_all_item {
        width: calc(100% / 2 - 18px);
    }

    .nameof_brand_item {
        font-size: 12px;

    }
}