.title {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 16px;
  margin-bottom: 46px;
}
.titleText {
  font-size: 30px;
  font-weight: bold;
  text-transform: uppercase;
}
.titleCount {
  padding: 4px 16px;
  color: #fff;
  font-weight: bold;
  background-color: var(--red);
  border-radius: 90px;
  font-size: 18px;
}
.main {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  column-gap: 20px;
  margin-bottom: 94px;
  width: 100%;
}
.rightBlock {
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  width: 80%;
}
.logo {
  margin-bottom: 5px;
  width: 133px;
  height: auto;
  object-fit: contain;
}
.filterBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  column-gap: 10px;
  cursor: pointer;
  padding: 16px 34px;
  border-radius: 5px;
  background-color: var(--red);
  position: relative;
}
.filterBtn:hover {
  background-color: var(--dark-red);
}
.filterBtn svg {
  position: absolute;
  left: 34px;
}
.filterBtn span {
  color: var(--white);
  font-size: 16px;
}
@media (max-width: 1024px) {
  .rightBlock {
    width: 100%;
  }
}
@media (max-width: 1024px) {
  .titleText {
    font-size: 25px;
  }
}

@media (max-width: 493px) {
  .titleText {
    font-size: 22px;
  }
  .title{
    margin-bottom: 34px;
  }
}
