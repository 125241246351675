.user_page {
  position: relative;
}

.red_back {
  background: #fff1f3;
  position: absolute;
  top: 188px;
  height: 282px;
  width: 100%;
  z-index: -1;
  display: block;
}

.bonus_card_block {
  position: relative;
  max-width: 716px;
  height: 432px;
  width: 100%;
}

.bonus_card_block img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 6px;
}

.user_left_block,
.user_left_block_active {
  gap: 18px;
  display: flex;
  align-items: flex-start;
  margin: 90px 0 170px 0;
}

.user_left_block_body {
  display: flex;
  gap: 18px;
  justify-content: flex-start;
  align-items: flex-start;
  width: 100%;
}

.user_three_general_categories {
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 342px;
  width: 100%;
  justify-content: space-between;
}

.user_favicon {
  background: #efeff6;
  border: 2px solid transparent;
  border-radius: 5px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  cursor: pointer;
  min-height: 136px;
}

.user_favicon:hover {
  border-color: var(--red);
}

.title_fgc {
  width: 100%;
  gap: 10px;
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.fgc_title {
  font-size: 18px;
  font-weight: bold;
}

.fgc_num {
  font-size: 18px;
  color: rgba(40, 40, 40, 0.5);
}

.person_info_ {
  display: flex;
  column-gap: 10px;
  align-items: center;
}
.my_loc {
  font-size: 12px;
}
.person_img {
  min-width: 66px;
  width: 66px;
  height: 66px;
  background-color: #e5e5ee;
  border-radius: 90px;
  justify-content: center;
  display: flex;
  align-items: center;
}

.user_Image {
  max-width: 36px;
  max-height: 40px;
}

.user_Image img {
  width: 100%;
  max-height: 100%;
}

.inf_person_about {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.title_fgc {
  gap: 10px;
  flex-wrap: wrap;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.name_person_inf {
  font-size: 18px;
  font-weight: bold;
}

.edit_person_inf {
  cursor: pointer;
  align-self: flex-start;
}
.edit_person_inf svg:hover path {
  fill: red;
}

@media only screen and (max-width: 1280px) {
  .user_left_block_body {
    flex-direction: column;
  }

  .bonus_card_block {
    max-width: none;
    height: 362px;
  }

  .user_three_general_categories {
    max-width: none;
  }
  .user_left_block {
    margin: 50px 0 60px 0;
  }
}
@media only screen and (max-width: 768px) {
  .user_left_block {
    flex-direction: column-reverse;
  }
  .user_favicon {
    padding: 20px;
  }
}
@media only screen and (max-width: 600px) {
  .bonus_card_block {
    height: 202px;
  }
  .name_person_inf {
    font-size: 14px;
  }
}
