
.questions_checked_item {
    padding: 46px 76px;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    background-color: #f7f7fb;
    width: 100%;
}
.checked_item_title{
    font-size: 30px;
    font-weight: bold;
    color: black;
    text-transform: uppercase;
}
.filter_drop_down_block{
    display: flex;
    flex-direction: column;
    row-gap: 14px;
    padding-bottom: 20px;
    border-bottom: 1px solid #2828281a;
}
.filter_drop_down_title{
    display: flex;
    justify-content: space-between;
    padding-top: 18px;
    font-size: 18px;
    text-transform: none;
    align-content: space-between;
    cursor: pointer;
}
.rotate_svg_open{
    transform: rotate(180deg);
}
.rotate_svg_open svg path{
    stroke: red;
}
.sub_answer_block,.sub_answer_block_active{
    max-height: 0;
    transition: .3s;
    opacity: 0;

}
.sub_answer_block p {
    font-size: 16px;
    line-height: 26px;
    color: #687083;
    margin-bottom: 0;
}
.sub_answer_block_active  p{
    font-size: 16px;
    line-height: 26px;
    color: #687083;
    margin-bottom: 0;
}
.sub_answer_block_active{
    opacity: 1;

    max-height: 1000px;

}
@media (max-width: 750px) {
    .checked_item_title{
        font-size: 18px;
    }
}


@media (max-width: 750px) {
    .questions_checked_item{
         padding: 30px;
    }
    .filter_drop_down_title{
         font-size: 14px;
    }
    .sub_answer_block,.sub_answer_block_active{
         font-size: 12px;
    }
}
