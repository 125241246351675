.container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
}

.slideItem {
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  padding: 19px;
  min-width: 290px;
  width: 290px;
  border-radius: 5px;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  -webkit-column-gap: 15px;
  -moz-column-gap: 15px;
  column-gap: 15px;
  border: 2px solid#EEEEEE;
}
.loadContent {
  width: 290px;
  height: 102px;
}

.slideItemImg {
  width: 60px;
  height: 60px;
  background: #efeff6;
  border-radius: 30px;
  padding: 11px;
}
.slideItemTitle {
  text-wrap: wrap;
  font-size: 14px;
  max-width: 148px;
}
.headerCategorysLogo {
  max-width: 120px;
  max-height: 20px;
  height: 100%;
  padding: 0 22px;
}
.headerCategorysLoad {
  -webkit-column-gap: 20px;
  -moz-column-gap: 20px;
  column-gap: 20px;
}
@media only screen and (max-width: 1280px) {
  .slideItem {
    width: 240px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }
}

@media only screen and (max-width: 1024px) {
  .container {
    margin: 20px 0 36px 0;
  }
}
@media only screen and (min-width: 768px) {
  .slideItem:hover {
    color: var(--red);
    border-color: var(--red);
  }
  .container {
    column-gap: 15px;
  }
}
@media only screen and (max-width: 768px) {
  .slideItem {
    min-width: 250px;
    padding: 10px;
  }
  .slideItemImg {
    height: 45px;
    width: 45px;
  }
  .slideItemTitle {
    font-size: 13px;
  }
  .loadContent {
    width: 250px;
    height: 84px;
  }
}
@media only screen and (max-width: 430px) {
  .slideItem {
    min-width: 190px;
    padding: 8px;
  }
  .slideItemImg {
    height: 40px;
    width: 40px;
  }
  .slideItemTitle {
    font-size: 12px;
  }
  .loadContent {
    width: 200px;
    height: 84px;
  }
  .container {
    margin: 20px 0 30px 0;
  }
}
