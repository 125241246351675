.categoryBlock {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  justify-content: space-around;
}
.categories {
  padding: 14px 18px;
  font-size: 12px;
  cursor: pointer;
  min-width: max-content;
  border: 1px solid #282828;
}
.moreBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 12px;
  margin-top: 20px;
  background-color: #f7f7fb;
  border-radius: 5px;
  text-transform: uppercase;
  cursor: pointer;
  font-size: 16px;
  border: 1px solid transparent;
}
.moreBtn:hover {
  border-color: #d10000;
  color: #d10000;
}
.categoriesActive {
  color: #d10000;
  border-color: #d10000 !important;
}
 
@media only screen and (max-width: 1440px) {
  .categoryBlockTitle {
    justify-content: flex-start;
  }
 
}
 
@media only screen and (max-width: 1024px) {
  .categoryBlockTitle {
    margin-bottom: 35px;
  } 
}

 
@media only screen and (max-width: 768px) {
  .categoryBlockTitle {
    margin-bottom: 20px;
  } 
}
 