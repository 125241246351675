.background {
    position: fixed;
    z-index: 9999999999;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(40, 40, 40, 0.4);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .container {
    max-width: 530px;
    width: 100%;
    background-color: var(--white);
    padding: 40px 36px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    flex-direction: column;
    row-gap: 30px;
  }
  
  .close {
    align-self: flex-end;
    cursor: pointer;
  }
  
  .wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    align-items: center;
  }
  
  .title {
    max-width: 374px;
    width: 100%;
    font-size: 26px;
    font-weight: bold;
    margin-bottom: 15px;
  }
  
  .description {
    max-width: 374px;
    width: 100%;
    font-size: 14px;
    margin-bottom: 15px;
  }
  
  .orderProductName {
    color: #7e7e7e;
    cursor: pointer;
    font-size: 14px;
    max-width: 374px;
    width: 100%;
  }
  
  .name,
  .surname,
  .email,
  .phone {
    width: 100%;
    padding: 10px;
    border: 1px solid #ceced1;
    background-color: #fff;
    border-radius: 5px;
  }
  
  .button {
    max-width: 374px;
    width: 100%;
    padding: 16px;
    color: var(--white);
    font-size: 14px;
    border-radius: 5px;
    align-self: center;
    justify-content: center;
    background-color: var(--red);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border: none;
  
    &:hover {
      background-color: var(--dark-red);
    }
  }
  
  
  @media (max-width: 580px) {
    .background {
      padding: 20px;
    }
  }