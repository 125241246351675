

.back_to_user {
    display: none;
    cursor: pointer;
    width: 54px;
    height: 54px;
    background-color: #f7f7fb;
    justify-content: center;
    border-radius: 6px;
    border: 1px solid transparent;
}


@media (max-width: 768px) {

    .back_to_user {
        display: flex;
        align-items: center;

    }
}