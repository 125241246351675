
.prod_img_plus{
    justify-content: flex-start;
    width: max-content;
    display: flex;
    align-items: center;
    position: relative;
}
.dashboard_order_list{
    border-bottom: 1px dashed #e3e6ef;
    padding: 15px 0;
    grid-gap: 20px;
    align-items: center;
    display: flex;


}
.round_mini_img,.mini_img1{
    width: 52px;
    height: 52px;
    border: 1px solid var(--red);
    border-radius: 90px;
}
.mini_img1{
    z-index: 10;

}
.mini_img2{
    margin-left: -14px;
    z-index: 9;

}
.mini_img3{
    margin-left: -14px;
    z-index: 9;

}
.mini_img4{
    margin-left: -14px;
    filter: brightness(50%);
}

.round_mini_img img{
     width: 100%;
    height: 100%;
}
.plus_num{
    position: absolute;
    right: 18px;
    font-size: 18px;
    z-index: 7;
    font-weight: bold;
    color: var(--white);
}
@media only screen and (max-width: 600px){
    .round_mini_img {
        width: 35px;
        height: 35px;
    }
}