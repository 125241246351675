
.user_address_list {
    display: flex;
    flex-direction: column;
    row-gap: 24px;
}

.user_address_item {
    display: flex;
    width: 100%;
    justify-content: space-between;

}

.edit_person_inf {
    align-items: center;

    display: flex;
    gap: 20px;
}

.edit_person_inf svg {
    cursor: pointer;
}

.edit_person_inf svg:hover path {
    fill: red;
}

.user_address_info {
    display: flex;
    gap: 10px;
    align-items: center;
}

.user_address_info span {
    font-size: 12px;
    opacity: 0.6;
    cursor: pointer;

}

.check_address,.check_address_active {
    position: relative;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: #e5e5ee;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: .3s;
}

.check_address::after,.check_address_active::after {
    content: '';
    position: absolute;
    width: 100%;
    border-radius: 50%;
    height: 100%;
    left: 0;
    top: 0;
    transition: .3s;

}
.check_address::before,.check_address_active::before{
    content: '';
    position: absolute;
    width: 50%;
    height: 50%;
    left: 4px;
    top: 3px;
    border-radius: 50%;
    z-index: 2;
    transition: .3s;

}
.check_address_active::before{
    background-color: white;

}
.check_address_active::after{
    background-color: var(--red);

}