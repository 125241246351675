.user_page {
}

.user_page_body {
  display: flex;
  gap: 18px;
  align-items: flex-start;
  margin: 90px 0 170px 0;
}

.for_user_person {
  display: flex;
  width: 100%;
  flex-direction: column;
  gap: 30px;
}

.for_user_person_title {
  gap: 10px;
  display: flex;
  align-items: center;
}

.back_to_user {
  display: none;
  cursor: pointer;
  width: 54px;
  height: 54px;
  background-color: #f7f7fb;
  justify-content: center;
  border-radius: 6px;
  border: 1px solid transparent;
}

.user_second_title {
  font-size: 30px;
  font-weight: bold;
  color: black;
  text-transform: uppercase;
}

.already_created_info {
  padding: 18px 28px;

  gap: 16px;
  min-height: 90px;
  border-radius: 5px;
  background-color: #f7f7fb;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.person_info_ {
  display: flex;
  column-gap: 20px;
  align-items: center;
}

.person_img {
  min-width: 54px;
  width: 54px;
  height: 54px;
  background-color: #e5e5ee;
  border-radius: 90px;
  justify-content: center;
  display: flex;
  align-items: center;
}

.user_Image {
  max-width: 18px;
  max-height: 22px;
}

.user_Image img {
  width: 100%;
  max-height: 100%;
}

.inf_person_about {
  display: flex;
  column-gap: 20px;
  align-items: center;
}

.name_person_inf {
  font-size: 18px;
  font-weight: bold;
}

.phone_mail {
  display: flex;
  column-gap: 16px;
  align-items: center;
}

.chosen_phone_mail_user {
  display: flex;
  align-items: center;
  column-gap: 8px;
  color: rgba(40, 40, 40, 0.5);
  font-size: 14px;
}

.edit_personal_inf {
  cursor: pointer;
  align-self: flex-start;
}

.user_title {
  font-size: 24px;
  font-weight: bold;
  text-transform: uppercase;
}

.already_created_info {
  padding: 18px 28px;
  gap: 16px;
  min-height: 90px;
}

.password_area {
  position: relative;
  width: 100%;
}

.password_area input {
  padding: 14px 28px;
  border: none;
  width: 100%;
  background-color: var(--white);
}

.save_person_inf_btn {
  padding: 14px;
  max-width: 238px;
  font-size: 14px;
  width: 100%;
  justify-content: center;
  color: var(--white);
  border-radius: 5px;
  border: none;
  cursor: pointer;
  background-color: var(--red);
}

.save_person_inf_btn:hover {
  background-color: var(--dark-red);
}

.eye_line,
.eye {
  position: absolute;
  right: 10px;
  top: 10px;
  cursor: pointer;
}

.cun_cum {
  font-size: 16px;
  font-weight: 600;
}

.check_discounted,
.check_discounted_active {
  width: 62px;
  height: 28px;
  background-color: black;
  border-radius: 4px;
  position: relative;
  cursor: pointer;
}

.check_discounted::before,
.check_discounted_active::before {
  content: "";
  position: absolute;
  left: 1px;
  top: 2px;
  width: 50%;
  height: 82%;
  background-color: white;
  border-radius: 2px;
  transition: 0.3s;
}

.check_discounted_active {
  background-color: var(--red);
}

.check_discounted_active::before {
  left: 30px;
}
.inputError::placeholder {
  color: var(--red);
}
@media (max-width: 1280px) {
  .already_created_info {
    flex-wrap: wrap;
  }
  .user_page_body {
    margin: 50px 0 60px 0;
  }
}

@media (max-width: 1000px) {
  .user_page_body {
    flex-direction: column-reverse;
  }
}

@media (max-width: 768px) {
  .name_person_inf {
    font-size: 14px;
    font-weight: bold;
  }
  .back_to_user {
    display: flex;
    align-items: center;
  }
  .chosen_phone_mail_user {
    font-size: 12px;
  }
  .user_second_title {
    font-size: 18px;
  }
}

@media (max-width: 648px) {
  .inf_person_about {
    flex-wrap: wrap;
    gap: 10px;
  }
}

@media (max-width: 430px) {
  .person_info_ {
    gap: 12px;
    flex-wrap: wrap;
  }
  .back_to_user {
    width: 40px;
    height: 40px;
    padding: 10px;
  }

  .phone_mail {
    flex-wrap: wrap;
    gap: 10px;
  }
}
@media (max-width: 375px) {
  .person_info_ {
    gap: 12px;
    flex-wrap: nowrap;
  }
  .already_created_info {
    padding: 18px 8px;
    gap: 6px;
  }
  .already_created_info_first {
    flex-wrap: nowrap;
  }
}
