.drawer {
  background-color: #fff;
  position: fixed;
  width: 599px;
  left: -600px;
  z-index: 9999999999;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: space-between; */
  transition: left 0.3s ease;
}
.open {
  left: 0;
  animation: slideIn 0.3s ease;
}
.drawerHeader {
  width: 100%;
  height: 73px;
  border-bottom: 1px solid #2828281a;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  font-weight: 700;
  line-height: 28px;
  color: var(--black);
}
.drawerFooter {
  width: 100%;
  height: 106px;
  border-top: 1px solid #2828281a;
  display: flex;
  align-items: center;
  justify-content: center;
}
.drawerFooter button {
  border: none;
  background: none;
  background-color: var(--red);
  width: 259px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: 400;
  line-height: 21px;
  color: #fff;
  cursor: pointer;
  border-radius: 5px;
}
.drawerFooter button:hover {
  background-color: var(--dark-red);
}
.drawerContainer {
  width: 100%;
  /* height: 100vh; */
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 999999999;
  background-color: #0000004d;
}

.resetButton {
  border: 1px solid transparent;
  padding: 10px 25px;
  background-color: var(--red);
  cursor: "pointer";
  border-radius: 5px;
  color: white;
  margin: 20px auto 0 60px;
}

@media (max-width: 768px) {
  .drawerHeader {
    font-size: 20px;
  }
  .drawerFooter button {
    font-size: 16px;
  }
  .drawer {
    width: 297px;
    left: -300px;
    transition: left 0.3s ease;
  }
  .open {
    left: 0;
    animation: slideInMobile 0.3s ease;
  }
  .resetButton {

    margin: 20px auto 0 20px;
  }
}

@keyframes slideIn {
  0% {
    left: -599px; /* Initial position when closed */
  }

  100% {
    left: 0; /* Final position when opened */
  }
}
@keyframes slideInMobile {
  0% {
    left: -277px; /* Initial position when closed */
  }

  100% {
    left: 0; /* Final position when opened */
  }
}
