.background {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(40, 40, 40, 0.4);
  z-index: 31;
  display: flex;
  align-items: center;
  justify-content: center;
}
.main {
  max-width: 530px;
  width: 100%;
  background-color: var(--white);
  padding: 40px 36px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  flex-direction: column;
  row-gap: 30px;
}
.close {
  display: flex;
  align-self: flex-end;
  cursor: pointer;
}
.img {
  max-width: 135px;
  height: 146px;
}
.title {
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  align-items: center;
}
.title span {
  text-align: center;
  font-size: 30px;
  font-weight: bold;
  color: black;
  text-transform: uppercase;
}
.btn {
  display: flex;
  align-items: center;
  max-width: 374px;
  width: 100%;
  padding: 16px;
  border-radius: 5px;
  align-self: center;
  justify-content: center;
  background-color: var(--red);
  cursor: pointer;
}
.btn span {
  color: var(--white);
  font-size: 14px;
}
.btn:hover {
  background-color: var(--dark-red);
}
@media only screen and (max-width: 768px) {
  .title span {
    font-size: 18px;
    margin: 0;
  }
}
@media only screen and (max-width: 600px) {
  .img {
    height: 80px;
    margin-bottom: 20px;
  }
  .img svg {
    width: 100px;
  }
  .title span {
    font-size: 16px;
  }
  .main {
    max-width: none;
    width: 90%;
    padding: 20px 16px;
    row-gap: 20px;
  }
}
