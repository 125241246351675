.container {
}
.headerCategorysLoad {
  column-gap: 20px;
}
.saleImg {
  max-width: 224px;
  width: 100%;
  height: 100%;
  object-fit: contain;
  cursor: pointer;
  border-radius: 6px;
}
.saleImg:hover {
  opacity: 0.7;
}
.loadContent {
  width: 224px;
  aspect-ratio: 6.5 / 10;
}
.titleBlock {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0;
}
.titleBlock span {
  cursor: pointer;
}
.titleBlock span:hover {
  color: var(--red);
}
.title {
  font-size: 30px;
  font-weight: bold;
  text-transform: uppercase;
}
.slideBtn {
  z-index: 2;
  position: absolute;
  width: 50px;
  height: 50px;
  box-shadow: 0px -1px 16px rgb(0 0 0 / 12%);
  justify-content: center;
  align-items: center;
  border-radius: 90px;
  background-color: #fff;
  cursor: pointer;
  top: 50%;
}
.slidePrevArrow {
  left: 15px;
  transform: rotate(180deg);
}
.slideNextArrow {
  right: 15px;
}
.slideBtn:hover svg path {
  stroke: var(--red);
}
@media only screen and (max-width: 768px) {
  .title {
    font-size: 18px;
    margin: 0;
  }
}
