

.brand_categories_item{
    gap: 20px;
    padding-bottom: 10px;
    overflow: auto;
    justify-content: center;
    display: flex;
    align-items: center;
    
}

.brand_categories_item::-webkit-scrollbar {
     height: 2px;
     cursor: pointer;
     margin-top: 20px;
 }
.brand_categories_item::-webkit-scrollbar-thumb {
     background-color: var(--red);
     border-radius: 2px;
 }
.brand_categories_item::-webkit-scrollbar-track {
     background: #f1f1f1;
 }

.brands_simple {
    padding: 14px 18px;
    font-size: 12px;
    cursor: pointer;
    min-width: max-content;
    border: 1px solid #282828;


}
.brands_simple:hover{
    color: var(--red);
    border-color: var(--red);
}

@media (max-width: 915px) {
    .brand_categories_item{
         justify-content: inherit;
    }


}
