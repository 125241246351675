.container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  z-index: 99999999999;
  transform: translateX(-100%);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}
.openMenu {
  transform: translateX(0%);
}
.closeBtn {
  cursor: pointer;
}
.headerSection {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #2828281a;
  padding: 15px 20px;
}
.headerLogo {
  width: 118px;
}
.menuCategories {
  position: relative;
  top: 0;
}
.categories {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
  border-bottom: 1px solid #2828281a;
  cursor: pointer;
}
.headerCategorysItem {
  display: flex;
  align-items: center;
  padding: 15px 20px;
  border-bottom: 1px solid #2828281a;
  cursor: pointer;
}
.headerCategorysItem img {
  width: 20px;
  margin-right: 15px;
}

.brands {
  display: grid;
  justify-content: space-around;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 0 20px;
  padding: 10px;
}
.brandItem1 {
  height: 60px;
  text-transform: uppercase;
  padding: 15px 22px;
  border-radius: 5px;
  font-weight: 600;
  border: 1px solid transparent;
  text-align: center;
  margin-bottom: 15px;
  cursor: pointer;
}
.brandItem1 img {
  height: 100%;
  object-fit: contain;
}

.categoriesSection {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  z-index: 60;
  transform: translateX(100%);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}
.categoriesSectionOpened {
  transform: translateX(0%) !important;
}
.categoriesSectionItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 20px 15px 30px;
  border-bottom: 1px solid #2828281a;
  cursor: pointer;
}
.categoriesSectionItem:first-child {
  cursor: auto;
}
.backBtn {
  cursor: pointer;
}
.backBtn span {
  color: #cd0e0d;
  font-weight: bold;
  font-size: 14px;
  margin-left: 15px;
}
.categoriesSectionItem img {
  width: 20px;
  height: 20px;
  object-fit: contain;
}
.categoriesSectionItem .menuItemDynamicImg {
  width: auto;
  object-fit: contain;
  height: 40px;
  align-self: center;
}
.categoriesSectionItem a {
  font-size: 14px;
  margin-left: 15px;
}
.menuItemDynamic a {
  margin-left: 0;
}

/* .brandsContainer {
  position: absolute;
  bottom: 10px;
} */
.bannerImg {
  width: 100%;
  height: 100%;
  object-fit: contain;
  border-radius: 5px;
}
.brandItem {
  max-width: 135px;
  width: 135px !important;
  height: 55px !important;
  min-height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 5px;
  padding: 5px 10px;
  box-sizing: border-box;
  border-radius: 5px;
  margin-top: 20px;
}

.brandImg {
  width: 100%;
  height: 100%;
  max-height: 40px;
  object-fit: contain;
}
.brandArrow {
  height: 150px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
/* .arrowTop svg {
  transform: rotate(-90deg);
}
.arrowBottom svg {
  transform: rotate(90deg);
} */
.bannerImg {
  width: 100%;
  object-fit: contain;
  max-height: 200px;
}
