.title {
  font-size: 30px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 20px;
}
.prodoctsList {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 20px;
  row-gap: 20px;
}
@media only screen and (max-width: 1280px) {
  .prodoctsList {
    column-gap: 10px;
    justify-content: center;
  }
}
