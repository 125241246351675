.cart_header_block {
  background-color: #f7f7fb;
}
.logo {
  max-width: 118px;
  min-width: 100px;
  width: 100%;
  object-fit: contain;
}

.cart_header_body {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 10px;
  padding-bottom: 10px;
  position: relative;
  overflow: visible;
  height: max-content;
}

.cart_body_back_link {
  font-size: 18px;
  color: rgb(40 40 40 / 70%);
  cursor: pointer;

  & > span {
    margin-left: 20px;
  }
}

.header_cards_logo {
  min-width: 100px;
  padding: 0;
}

.phone_drpdown_block {
  position: relative;
  min-width: 270px;
  overflow: visible;
}

.phone_drpdown_general {
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: flex-end;
}

.phone_num_call {
  font-size: 20px;
  font-weight: 700;
  font-family: "Mardoto-Regular";
}
.open_phone_nums {
  cursor: pointer;
}

.drop_down_sub {
  position: absolute !important;
  right: 27px;
  top: 30px;
  display: flex;
  background-color: #ffffff;
  flex-direction: column;
  opacity: 0;
  visibility: hidden;
  max-height: none;
  height: fit-content;

  &.open {
    opacity: 1;
    visibility: visible;
  }
}

.drop_down_sub a {
  padding: 8px 12px;
  border-bottom: 1px solid #28282833;
  font-size: 20px;
  font-weight: 700;
  font-family: "Mardoto-Regular";
  white-space: pre;
}
@media (max-width: 1440px) {
  .phone_num_call {
    font-size: 18px;
  }
  .drop_down_sub {
    opacity: 0;
    visibility: hidden;
  }
}
@media (max-width: 992px) {
  .open_phone_nums {
    display: none;
  }
}
@media (max-width: 768px) {
  .cart_body_back_link {
    font-size: 12px;
  }
}
@media (max-width: 600px) {
  .cart_body_back_link {
    font-size: 0;
  }
  .phone_num_call {
    font-size: 13px;
  }
  .phone_drpdown_general {
    gap: 8px;
  }
  .phone_drpdown_svg {
    display: none;
  }
}
@media (max-width: 430px) {
  .header_cards_logo {
    max-width: 100px;
    min-width: 100px;
  }
}
