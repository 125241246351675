.showModal {
  display: block;
}
.hideModal {
  display: none;
}
.background {
  position: fixed;
  z-index: 9999999;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(40, 40, 40, 0.4);
}
.closeReviewModaleBtn {
  display: inline-block;
  color: #000;
  position: fixed;
  z-index: 9999999;
  right: 5px;
  top: 30px;
  transform: translate(-50%, -50%);
  cursor: pointer;
  opacity: 0.5;
}
.closeReviewModaleBtn path {
  fill: #000;
  stroke: #000;
}
.closeReviewModaleBtn:hover {
  opacity: 1;
}
.container {
  z-index: 50;
  display: flex;
  flex-direction: column;
  row-gap: 38px;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 5px;
  /* overflow: auto; */
  background-color: #ffffff;
}
.openedContainer {
  width: 631px;
  padding: 60px 40px;
  position: relative;
  max-width: 80%;
  max-height: 80%;
}
.mainSecond {
  /* display: none; */
}
.headContent {
  margin-bottom: 60px;
}
.modalTitle {
  font-size: 22px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: center;
}
.modalBody {
}
.reaction {
  filter: unset;
}
.faces {
  width: 100%;
  margin-bottom: 60px;
  display: flex;
  justify-content: center;
}
.reviewForm {
  width: 100%;
}
form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 28px;
}
.reviewFormTextarea {
  width: 100%;
  border-radius: 10px;
  background: #f7f7fb;
  padding: 20px;
  outline: none;
  border: none;
  font-size: 16px;
}
textarea::placeholder {
  opacity: 0.5;
}
.submitButton {
  width: 187px;
  height: 46px;
  padding: 11.314px 13.371px;
  border-radius: 5px;
  border: none;
  background: #e31335;
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  cursor: pointer;
  align-self: center;
}
.popupBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1 1 auto;
}
.vlvLogoModal {
  width: 40%;
  margin: 40px auto;
}
@media (max-width: 768px) {
  .openedContainer {
    padding: 30px 20px;
  }
  .headContent,
  .faces {
    margin-bottom: 30px;
  }
}
@media (max-width: 480px) {
  .closeReviewModaleBtn {
    display: none;
  }
}
