.bannerSliders{
    margin: 50px 0px 50px 0px;
    width: 100%;
    display: flex;
    gap: 20px;
    justify-content: space-between; 
}

.shadow1{
    height: 100px;
    position: absolute;
    bottom: 0px;
    left: 0px;
    z-index: 99999;
     width: 354px;
}
.shadow2{
    height: 100px;
    position: absolute;
    bottom: 0px;
    left: 0px;
    z-index: 99999;
     width: 709px;
}
.shadow3{
    height: 100px;
    position: absolute;
    bottom: 0px;
    left: 0px;
    z-index: 99999;
     width: 354px;
}
.bannerSlider1{
    position: relative;
    max-width: 24%;
    /* height: 320px; */
}
.bannerSlider2{
    position: relative;
    /* padding: 0px 20px; */
    max-width: 48%;
    /* height: 320px; */
}

.bannerSlider3{
    position: relative;
    max-width: 24%;
    /* height: 320px; */
}
.bannerSlider1:hover .shadow1,
.bannerSlider2:hover .shadow2,
.bannerSlider3:hover .shadow3 {
    box-shadow: 0px -85px 93px -92px rgba(250,242,242,0.75) inset;
    -webkit-box-shadow: 0px -85px 93px -92px rgba(250,242,242,0.75) inset;
    -moz-box-shadow: 0px -85px 93px -92px rgba(250,242,242,0.75) inset;
}
.bannerSlider1:hover img,
.bannerSlider2:hover img,
.bannerSlider3:hover img{
   transform: scale(1, 1);
}
.loadContent{
    height: 334px;
}




@media(max-width:768px){
    .bannerSlider1,.bannerSlider2,.bannerSlider3{
        padding: 0px 5px;
        max-width: 100%;
        margin-bottom: 20px;
     }
     .bannerSliders{
       flex-direction: column;
   }
}

