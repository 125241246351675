@font-face {
  font-family: "Mardoto-Bold";
  src: local("Mardoto-Bold"),
    local(arial) url("./assets/fonts/Mardoto-Bold.ttf") format("truetype"),
    local("arial") format("truetype");
  /* font-weight: bold; */
  /* font-display: optional; */
}
@font-face {
  font-family: "medium";
  src: local("Mardoto-Medium"),
    local(arial) url("./assets/fonts/Mardoto-Medium.ttf") format("truetype");
  /* font-display: optional; */
}
@font-face {
  font-family: "regular";
  src: local("Mardoto-Regular"),
    local(arial) url("./assets/fonts/Mardoto-Regular.ttf") format("truetype");
  /* font-display: optional; */
}
@font-face {
  font-family: "Mardoto-Regular";
  src: local("Mardoto-Regular"),
    url("./assets/fonts/Mardoto-Regular.ttf") format("truetype");
  /* font-display: optional; */
}
