.details_description_title {
    margin: 0 auto;
    font-size: 30px;
    text-align: center;
    max-width: 966px;
    font-weight: bold;
    margin-bottom: 60px;
}

@media  (max-width: 768px){
.details_description_title {
    font-size: 18px;
    max-width: 580px;
    margin-bottom: 32px;
}
}
@media  (max-width: 430px){
    .details_description_title {
        font-size: 18px;
        max-width: 580px;
        margin-bottom: 20px;
    }
}