.order_form {
    max-width: 530px;
    width: 100%;
    max-height: 90vh;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    overflow: auto;
    border-radius: 5px;
    padding: 36px 30px 26px 30px;
    background-color: var(--white);
    margin: 0 auto;
}

.order_form span {
    font-size: 20px;
    text-transform: uppercase;
    font-weight: bold;
}

.add_personal_inputs {
    display: flex;
    gap: 20px;

}

.add_personal_inputs input {
    width: 100%;
    padding: 12px 24px;
    font-size: 14px;
    background-color: #f7f7fb;
    border-radius: 5px;
    border: 1px solid transparent;
}

.order_inputs_name {
    width: 100%;
}

.textarea_comment_order {
    min-height: 100px;
    width: 100%;
    padding: 12px 24px;
    font-size: 14px;
    background-color: #f7f7fb;
    border-radius: 5px;
    border: 1px solid transparent;
    resize: none;
    outline: none;
}

.add_personal_inputs input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.add_personal_inputs input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.add_personal_inputs input[type="number"] {
    -moz-appearance: textfield;
}


.textarea_comment_order:focus {
    border-color: var(--green);
}

.add_personal_inputs input:focus {
    border-color: var(--green);
}


.inputError {
    border-color: var(--red) !important;
}

.order_submit {
    width: 100%;
    padding: 16px;
    font-size: 14px;
    color: var(--white);
    border: none;
    cursor: pointer;
    border-radius: 5px;
    background-color: var(--red);
    justify-content: center;
}

.order_submit:hover {
    background-color: #980a21;
}

@media (max-width: 600px) {
    .add_personal_inputs {
        flex-direction: column;
    }

    .order_form {
        padding: 26px 20px 16px 20px;

    }
}

.success_image {
    max-width: 135px;
    height: 146px;
}

.success_image img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.success_text {
    font-size: 30px;
    font-weight: bold;
    color: black;
    text-align: center;
    text-transform: uppercase;
}

.close_thnx_attent {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    cursor: pointer;
}

.success_btn {
    max-width: 374px;
    width: 100%;
    padding: 16px;
    color: var(--white);
    font-size: 14px;
    border-radius: 5px;
    align-self: center;
    justify-content: center;
    background-color: var(--red);
    text-align: center;
    cursor: pointer;
}

.success_btn:hover {
    background-color: #980a21;
}

@media only screen and (max-width: 768px) {
    .success_text {
        font-size: 18px;
        margin: 0;
    }
}
