.container {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin: 70px auto 0;
  flex-direction: column;
  gap: 40px;
  align-self: center;
  max-width: 570px;
}
.container img {
  max-width: 162px;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.container span {
  font-weight: bold;
  text-transform: uppercase;
  font-size: 25px;
  text-align: center;
}
