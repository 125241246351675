.add_personal_form {
    position: relative;
    z-index: 2;
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.05);
    border-radius: 5px;
    background-color: white;
    padding: 80px 100px 74px 100px;
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    margin-top: 40px;
}
.inputError {
    border-color: var(--red) !important;
}
.add_personal_form h1 {
    font-size: 30px;
    font-weight: bold;
    font-family: "Mardoto-Regular";
    text-transform: uppercase;
}

.add_personal_inputs {
    display: flex;
    gap: 36px;
}

.add_personal_inputs input {
    flex: 1;
    padding: 12px 24px;
    font-size: 14px;
    background-color: #f7f7fb;
    border-radius: 5px;
    border: 1px solid transparent;
}

.add_personal_inputs > input:nth-child(1) {
    width: 32%;
    flex: unset;
}

.add_personal_inputs_phone {
    flex: 1;
    display: flex;
    gap: 40px;
}

.add_personal_inputs_phone input {
    width: 100%;
}

.second_title {
    margin-top: 90px;
    font-size: 30px;
    font-weight: bold;
    color: black;
    text-transform: uppercase;
}

.delivery_list {
    display: flex;
    gap: 20px;
}

.city_input input {
    width: 60%;
    padding: 12px 24px;
    font-size: 14px;
    background-color: #f7f7fb;
    border-radius: 5px;
    border: 1px solid transparent;
}

.choose_type_delivery {
    max-width: 272px;
    position: relative;
    width: 100%;
    height: 85px;
    border: 2px solid #e5e5ee;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    row-gap: 3px;
    padding: 12px;
    cursor: pointer;
    align-items: center;
    justify-content: center;
}
.choose_type_delivery_active {
    max-width: 272px;
    position: relative;
    width: 100%;
    height: 85px;
    border: 2px solid red;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    row-gap: 3px;
    padding: 12px;
    cursor: pointer;
    align-items: center;
    justify-content: center;
}
.choose_type_delivery span {
    font-size: 14px;
    font-weight: 600;
    color: rgba(40, 40, 40, 0.4);
    text-transform: uppercase;
}
.choose_type_delivery_active span {
    font-size: 14px;
    font-weight: 600;
    color: rgba(40, 40, 40, 0.4);
    text-transform: uppercase;
}

.choose_type_delivery::before {
    content: "";
    position: absolute;
    left: 20px;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    top: 20px;
    background-color: #f7f7fb;
    transition: 0.3s;
}
.choose_type_delivery_active::before {
    content: "";
    position: absolute;
    left: 20px;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: var(--red);
    top: 20px;
    transition: 0.3s;
}

.best_deal_product {
}
@media (max-width: 992px) {
    .add_personal_form {
        padding: 24px;
    }
    .add_personal_inputs {
        flex-direction: column;
        gap: 20px;
    }
    .add_personal_form h1,
    .second_title {
        font-size: 20px;
    }
    .delivery_list {
        flex-direction: column;
        column-gap: 20px;
    }
    .choose_type_delivery,
    .choose_type_delivery_active {
        max-width: 100%;
    }
    .city_input input {
        width: 100%;
    }
    .add_personal_inputs > input:nth-child(1) {
        width: 100%;
        flex: unset;
    }
    .add_personal_inputs_phone {
        flex: 1;
        gap: 20px;
        flex-direction: column;
    }

    .second_title {
        margin-top: 60px;
    }
}
