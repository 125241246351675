.titleBlock {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
  }
  .titleBlock .title {
    cursor: pointer;
    font-size: 22px;
    font-weight: 700;
    line-height: 26px;
    color:var(--black)
  }
  .titleBlock:hover .title {
    color: var(--red);
  }
  .titleBlock:hover svg path {
    stroke: var(--red);
  }
  .arrow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    transform: rotate(0deg);
  }

  .dropDownBlock {
    display: flex;
    flex-direction: column;
    row-gap: 12px;
    height: fit-content;
    overflow: hidden;
    border-bottom: 1px solid #2828281a;
  }

  @media (max-width:768px){
    .titleBlock .title {
      font-size: 14px;
    }
  }