.InfoAboutPage {
  font-size: 18px;
  line-height: 30px;
  text-align: center;
  color: #898383;
  margin: 65px 0;
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.InfoAboutPage element div div {
  width: auto !important;
  max-width: 100% !important;
  height: auto !important;
}
/* .InfoAboutPage p{
    text-align: justify;
    font-size: 12pt;
} */
.title {
  font-size: 24pt;
  text-align: center;
  max-width: 966px;
  font-weight: bold;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 40px;
}
.categoryBlock {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.categoryBlockTitle {
  display: flex;
  justify-content: center;
  margin-bottom: 40px;
}
.categories {
  padding: 14px 18px;
  font-size: 12px;
  cursor: pointer;
  min-width: max-content;
  border: 1px solid #282828;
}
.categoriesActive {
  color: #d10000;
  border-color: #d10000 !important;
}

.secondTitle {
  font-size: 30px;
  text-align: center;
  max-width: 966px;
  font-weight: bold;
  text-align: center;
  margin: 0 auto;
  margin-bottom: 40px;
}
.smartphonesTitle {
  margin-top: 70px;
}
.promotionCategoryBlock {
  margin-bottom: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding-bottom: 15px;
  overflow: auto;
}

.discoverBrands {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
}
.discoverBaners {
  max-width: 460px;
  width: 100%;
}
img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.LoadContentImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.moreBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 12px;
  margin-top: 20px;
  background-color: #f7f7fb;
  border-radius: 5px;
  text-transform: uppercase;
  cursor: pointer;
  font-size: 16px;
  border: 1px solid transparent;
}
.bannersBlck {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 30px;
  margin-top: 62px;
  margin-bottom: 70px;
}
.bcannerContent {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.banner > img {
  width: 100%;
  object-fit: contain;
  border-radius: 5px;
}
.lastInfoBrand {
  background: #f7f7fb;
  border-radius: 5px;
  padding: 56px;
  color: #898383;
  margin: 62px 0;
  font-size: 18px;
  line-height: 150%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: auto;
  max-width: 100%;
}
.lastInfoBrand element div div {
  width: auto !important;
  max-width: 100% !important;
  height: auto !important;
}
.LoadContentGeneralBanner {
  height: 450px;
}
.LoadContentTwoBanersBlck {
  height: 450px;
}
@media only screen and (max-width: 1440px) {
  .promotionCategoryBlock {
    justify-content: flex-start;
  }
  .categoryBlockTitle {
    justify-content: flex-start;
  }
  .LoadContentGeneralBanner {
    height: 400px;
  }
  .LoadContentTwoBanersBlck {
    height: 400px;
  }
}
@media only screen and (max-width: 1024px) {
  .promotionCategoryBlock {
    margin-bottom: 20px;
  }
  .lastInfoBrand {
    padding: 40px 44px;
  }
  .LoadContentGeneralBanner {
    height: 300px;
  }
  .LoadContentTwoBanersBlck {
    height: 300px;
  }
}
@media only screen and (max-width: 768px) {
  .discoverBrands {
    gap: 14px;
  }
  .title {
    margin-bottom: 32px;
    font-size: 18px;
  }
  .secondTitle {
    font-size: 18px;
    margin-bottom: 20px;
  }
  .smartphonesTitle {
    font-size: 18px;
    margin-bottom: 20px;
  }
  .lastInfoBrand {
    padding: 30px 42px;
  }
  .LoadContentGeneralBanner {
    height: 220px;
  }
  .LoadContentTwoBanersBlck {
    height: 220px;
  }
}
@media only screen and (max-width: 600px) {
  .lastInfoBrand {
    padding: 32px 22px;
  }
  .bannersBlck {
    flex-wrap: nowrap;
    flex-direction: column;
    gap: 14px;
    margin-top: 62px;
    margin-bottom: 70px;
  }
  .bcannerContent {
    display: flex;
    flex-direction: column;
    gap: 14px;
  }
  .banner > img {
    width: 100%;
    border-radius: 5px;
  }
}
@media only screen and (max-width: 430px) {
  .title {
    margin-bottom: 20px;
  }
  .LoadContentGeneralBanner {
    height: 96px !important;
  }
  .LoadContentTwoBanersBlck {
    height: 96px !important;
  }
  .secondTitle {
    font-size: 16px;
  }
}
