.aboutUsBlock {
  display: flex;
  flex-direction: column;
  gap: 26px;
}
.blogBanner {
  width: 100%;
}
.blogBanner > img {
  width: 100%;
}
.descr {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.descr > span {
  font-size: 18px;
  line-height: 30px;
  color: #898383;
}
.shareBlck {
  position: relative;
  width: 38px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  cursor: pointer;
  border: 1px solid #28282833;
}
.shareBlck:hover > svg:nth-child(1) path {
  fill: #980a21;
}
.videoBlck {
  padding-top: 15px;
}
.LoadContentVideo {
  max-width: 640px;
  height: 360px;
}
.drpdwn_share {
  position: absolute;
  top: 50px;
  width: max-content;
  right: -15px;
  z-index: 11;
  display: none;
  flex-direction: column;
  gap: 6px;
  padding: 10px;
  background: var(--white);
  box-shadow: 0px 6.42202px 16.055px rgba(0, 0, 0, 0.1);
  border-radius: 8.02752px;
}
.socialLinks {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(40, 40, 40, 0.2);
  border-radius: 5px;
  width: 40px;
  height: 40px;
  padding: 6px;
}
.socialLinks:hover {
  border-color: #d10000;
}
.socialLinks:hover > svg path {
  fill: #d10000;
}
.LoadContentdate {
  width: 150px;
}
.LoadContentGeneralBanner {
  height: 450px;
}
.drpdwn_share_active {
  display: flex !important;
}
@media only screen and (max-width: 1280px) {
  .about_us_block {
    gap: 20px;
  }
  .LoadContentGeneralBanner {
    height: 300px;
  }
}
@media only screen and (max-width: 1024px) {
  .LoadContentGeneralBanner {
    height: 200px;
  }
}
@media only screen and (max-width: 1024px) {
  .LoadContentGeneralBanner {
    height: 96px !important;
  }
}
@media only screen and (max-width: 768px) {
  .videoBlck > element > p > iframe {
    max-width: 100%;
  }
  .videoBlck > .LoadContentVideo {
    max-width: 100%;
  }

  .title {
    font-size: 18px;
  }
}
