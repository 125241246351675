.cart_counter_block {
  display: flex;
  align-items: center;
}

.cart_counter_block span {
  border: none;
  font-size: 18px;
  width: 54px;
  text-align: center;
  background-color: transparent;
}

.counterBtn {
  min-width: 38px;
  min-height: 38px;
  justify-content: center;
  cursor: pointer;
  border: 1px solid rgba(40, 40, 40, 0.1);
  border-radius: 5px;
  background-color: #fff;
}
