/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v8.4.14,
* Autoprefixer: v10.4.7
* Browsers: last 4 version
*/

.main {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  margin-bottom: 80px;
  border-radius: 5px;
  -webkit-column-gap: 20px;
  -moz-column-gap: 20px;
  column-gap: 20px;
  padding: 44px;
  background-size: cover;
  -ms-flex-pack: distribute;
  justify-content: space-around;
  background-repeat: no-repeat;
  background-image: url(./assets/TreeInOneBg.png);
}
.leftBlock {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  row-gap: 28px;
  max-width: 530px;
}
.rightBlock {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end; /* center */
  position: relative;
}
.leftBlock .title,
.leftBlock .title span {
  font-size: 48px;
  text-transform: uppercase;
  font-weight: bold;
}
.priceBlock {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-column-gap: 18px;
  -moz-column-gap: 18px;
  column-gap: 18px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding-left: 50px;
}
.bigPrice {
  font-size: 40px;
  font-weight: bold;
}
.btnsBlock {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  row-gap: 28px;
  max-width: 530px;
  -webkit-column-gap: 36px;
  -moz-column-gap: 36px;
  column-gap: 36px;
}
.btn {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 18px 20px;
  border-radius: 5px;
  cursor: pointer;
  background-color: var(--red);
  gap: 10px;
  width: 100%;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}
.btn span {
  font-size: 12px;
  color: var(--white);
}
.btn svg path {
  fill: var(--white);
}
.btn:hover {
  background-color: var(--dark-red);
}
@media only screen and (max-width: 1440px) {
  .leftBlock .title,
  .leftBlock .title span,
  .leftBlock .title b {
    font-size: 42px;
  }
  .bigPrice {
    font-size: 36px;
  }
  .btnsBlock {
    -webkit-column-gap: 20px;
    -moz-column-gap: 20px;
    column-gap: 20px;
  }
}
@media only screen and (max-width: 1024px) {
  .main {
    margin-bottom: 56px;
  }
  .leftBlock {
    width: 35%;
  }
  .leftBlock .title,
  .leftBlock .title span,
  .leftBlock .title b {
    font-size: 32px;
  }
}

@media only screen and (max-width: 992px) {
  .main {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    row-gap: 50px;
  }
  .leftBlock {
    max-width: none;
    width: 100%;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .leftBlock .title {
    text-align: center;
  }
}

@media only screen and (max-width: 600px) {
  .bigPrice {
    font-size: 24px;
  }
}
@media only screen and (max-width: 430px) {
  .main {
    padding: 18px;
  }
  .leftBlock {
    gap: 20px;
  }
  .btnsBlock {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    gap: 10px;
    width: 100%;
  }
  .btn {
    width: 100%;
    padding: 10px;
  }
  .btn span {
    font-size: 10px;
  }
  .leftBlock .title,
  .leftBlock .title span {
    font-size: 28px;
    text-transform: uppercase;
    font-weight: bold;
  }
}
