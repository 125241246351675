.job_accordion_item,
.job_accordion_item_active {
  background: #f7f7fb;
  border: 1px solid #eeeeee;
  border-radius: 5px;
  padding: 30px 60px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  cursor: pointer;
}

.job_accordion_item_title {
  display: flex;
  justify-content: space-between;
  padding: 10px 0 0;
}
.accordion_sub_hidden_list {
  max-height: 0;

  overflow: hidden;
  transition: max-height 0.4s ease;
}
.job_accordion_item_active .accordion_sub_hidden_list {
  max-height: 3000px;
  display: block;
}

.job_accordion_item_title span {
  font-size: 30px;
  font-weight: bold;
  color: black;
  text-transform: none;
}

.accordion_pdf_save {
  display: flex;
  margin-top: 10px;
  gap: 30px;
}
.send_message_btn {
  color: #ffffff;
  font-size: 17px;
  padding: 20px 100px;
  border: none;
  cursor: pointer;
  background-color: #d10000;
  border-radius: 5px;
  width: 350px;
}
.accordion_files_block {
  display: flex;
  flex-direction: column;
  gap: 4px;
  position: relative;
  width: 300px;
}
.file_label {
  padding: 20px 0;
  background: var(--gray-bold);
  color: white;
  border-radius: 5px;
  font-size: 17px;
  cursor: pointer;
  text-align: center;
}
.file_label_error {
  border: 2px solid red;
  color: var(--red);
}
.accordion_files_block input {
  display: none;
}
@media (max-width: 842px) {
  .accordion_pdf_save {
    flex-direction: column;
    text-align: center;

    width: 100%;
  }
  .send_message_btn,
  .file_label {
    padding: 20px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
  }
  .job_accordion_item_title span {
    font-size: 24px;
  }
  .job_accordion_item,
  .job_accordion_item_active {
    padding: 30px 50px;
  }
  .accordion_sub_hidden_text {
    font-size: 16px;
  }
}
@media (max-width: 502px) {
  .job_accordion_item,
  .job_accordion_item_active {
    padding: 10px 40px;
  }

  .job_accordion_item_title span {
    font-size: 15px;
  }
  .accordion_sub_hidden_text {
    font-size: 12px;
  }
  .send_message_btn,
  .file_label {
    font-size: 13px;
  }
  .accordion_pdf_save {
    gap: 10px;
  }
  .accordion_files_block {
    width: 80%;
  }
  .send_message_btn {
    width: 100%;
  }
}
@media (max-width: 410px) {
  .file_label,
  .send_message_btn {
    font-size: 11px;
  }
}
