.product_modal_block {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.product_modal_bg {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: black;
  opacity: 0.6;
}

.modal_content_scrool {
  max-width: 754px;
  width: 100%;
  background-color: var(--white);
  padding: 44px 30px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  row-gap: 14px;
  max-height: 62vh;
  overflow: auto;
  flex-direction: column;
  position: relative;
  margin: 20px;
}

.modal_content_scrool::-webkit-scrollbar {
  height: 0;
  width: 2px;
}

.modal_content_scrool::-webkit-scrollbar-track {
  background-color: var(--red); /* цвет трека */
}

.modal_content_img_title {
  display: flex;
}

.modal_content_title {
}

.modal_content_title span {
  font-size: 14px;
  color: rgba(40, 40, 40, 0.6);
}

.modal_content_title h3 {
  font-size: 30px;
  font-weight: bold;
  color: black;
  text-transform: uppercase;
}

.finish_check_block_border {
  border: 1px dashed rgba(40, 40, 40, 0.1);
  border-radius: 5px;
  padding: 15px 12px;
  width: 100%;
}

.finish_check_block {
  background: #f7f7fb;
  border-radius: 5px;
  padding: 8px 22px;
  display: flex;
  flex-direction: column;
}

.check_info_element {
  padding: 10px 16px;
  gap: 10px;
  border-bottom: 1px solid #2828281a;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.bold_first_text {
  font-size: 16px;
  font-weight: bold;
}

.total_info_col {
  background: #f7f7fb;

  border-radius: 5px;
  padding: 24px 38px 35px 32px;
  column-gap: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
}

.prod_count_imgs {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.count_title_check {
  justify-content: flex-start;
  column-gap: 16px;
  align-items: center;
  display: flex;
}

.round_mini_img {
  width: 52px;
  height: 52px;
  border: 1px solid var(--red);
  border-radius: 90px;
}

.prod_total {
  font-size: 16px;
  font-weight: bold;

  color: rgba(40, 40, 40, 0.4);
}

.prod_total_price {
  font-size: 30px;
}

.backto_btn {
  width: 100%;
  padding: 16px;
  color: var(--white);
  font-size: 14px;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  background-color: var(--red);
  display: block;
  margin-top: 10px;
  text-align: center;
  cursor: pointer;
}

.close_shnor {
  position: absolute;
  top: 20px;
  right: 20px;
  cursor: pointer;
}

@media (max-width: 600px) {
  .bold_first_text {
    font-size: 14px;
  }
  .prod_total_price {
    font-size: 20px;
  }
  .modal_content_title h3 {
    font-size: 22px;
  }
  .modal_content_img_title img {
    width: 30%;
    height: 30%;
  }
  .modal_content_scrool {
    padding: 35px 20px;
  }
  .total_info_col {
    background: #f7f7fb;
    border-radius: 5px;
    padding: 15px 20px 20px 15px;
  }
  .check_info_element {
    padding: 5px 10px;
  }
  .check_info_element p {
    font-size: 13px;
  }
}
@media only screen and (max-width: 430px) {
  .bold_first_text {
    font-size: 12px;
  }
  .prod_total_price {
    font-size: 16px;
  }
  .modal_content_title h3 {
    font-size: 16px;
  }
  .finish_check_block {
    padding: 6px 7px;
  }
  .modal_content_scrool {
    padding: 4px 4px;
  }
  .check_info_element p {
    font-size: 10px;
  }

  .backto_btn {
    font-size: 10px;
  }
  .count_title_check {
    column-gap: 6px;
  }
}
