.leftLoad{
    min-width: 248px;
    height: 500px;
}
.logLoad{
  max-width: 248px;
  height: 100px;
  margin-top: 10px;
  margin-bottom: 10px;
}
.rightLoad{
  width: 100%;
  height: 500px;
  margin-left: 89px;
}
.sliderLoad{
  width: 100%;
  height: 500px;
  margin-top: 40px;
}
.bottomLoad{
  width: 100%;
  height: 700px;
}
.title {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  column-gap: 16px;
  margin-bottom: 46px;
  position: relative;
}
.title img{
  max-width: 150px;

}
.titleText {
  font-size: 30px;
  font-weight: bold;
  text-transform: uppercase;
}
.titleCount {
  padding: 4px 16px;
  color: #fff;
  font-weight: bold;
  background-color: var(--red);
  border-radius: 90px;
  font-size: 18px;
  margin-bottom: -5px;
}
.brandTop{
    display: flex;
    justify-content: space-between;
}
.brandLeft{
    min-width: 248px;
    width: 248px;
    padding: 50px 0px 50px 20px;
    background-color:var(--gray);
    border-radius: 5px;
    overflow: hidden;
    height: 80%;
}
.brandLeftContainer{
    display: flex;
    flex-direction: column;
}
.brandLeftContainer h1{
    margin-bottom: 45px;
    font-size: 26px;
    font-weight: 700;
    line-height: 30px;
    color: var(--black);
}
.brandLeftList{
    display: flex;
    flex-direction: column;
}
.brandLeftList :not(:last-child){
    display: inline-block;
    margin-bottom: 18px;
}
.brandLeftList span{
    cursor: pointer;
    color: var(--black);
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
}
.brandLeftList span:hover{
  color:red;
}
.brandRight{
    width: 100%;
    margin-left: 89px;
    display: grid;
    gap: -20px;
    justify-content: space-between;
    grid-template-columns: minmax(100px, 234px) minmax(100px, 234px) minmax(100px, 234px) minmax(100px, 234px);
    row-gap: 30px;
    /* column-gap: 20px; */
}

.brandRightItem{
    width: 100%;
    height: 269px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.brandRightItemTop{
  width: 100%;
  height: 219px;
  border-radius: 229px;
  background-color:var(--gray-20);
  margin-bottom: 22px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.brandRightItemTop img{
  max-width: 144px;
  width: 100%;
  height: 144px;   
}
.brandRightItem {
  transition: all 1s ease-out
}

.brandRightItem:hover img{
  transform:scale(1.2,1.2);
  transition: all 1s ease-out;
}

.brandRightItem:hover span{
  color:red;
  transition: all 1s ease-out;
}
.brandRightItemBottom{
  text-align: center;
}
.brandRightItemBottom span{
    font-size: 17px;
    font-weight: 400;
    line-height: 20px;
    color: var(--black);
    cursor: pointer;   
}
.brandRightItemBottom span:hover{
  color:red;
}
.sliderbrand{
    margin: 15px 0 50px;
}
.brandBottom{
  margin-top: 73px;
  display: flex;
  flex-direction: column;
}
.brandBottomTop{
  margin-bottom: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.brandBottomTopItemLeft{
    flex: 1 1 100%;
}
.brandBottomTopLeftContent{
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .brandBottomTopLeftContent h3{
      font-size: 30px;
      font-weight: 700;
      line-height: 35px;
  }
  .brandBottomTopLeftContent p{
      width: 100%;
      margin-top: 16px;
      font-size: 22px;
      font-weight: 400;
      line-height: 26px;
  }
.brandBottomTopItemRight{
    flex: 1 1 100%;
    margin-left: 99px;
}
.brandBottomTopItemRightContent{
    max-width: 681px;
    width: 100%;
    height: 454px;
 }
 .brandBottomTopItemRightContent img{
  border-radius: 10px;
 }
/* .brandBottomBottom{
  min-height: 260px;
} */
.brandBottomBottom p{
    width: 100%;
    font-size: 22px;
    font-weight: 400;
    line-height: 26px;
}
@media(max-width:1400px){
  .brandRight{
     margin-left:40px;
  }
  .brandRight{
    gap: 20px;
    justify-content: space-between;
    grid-template-columns: minmax(100px 180px) minmax(100px 180px) minmax(100px 180px) minmax(100px 180px) !important;
  }
.brandRightItemTop{
  height: 210px;
}
  .brandRightItemTop img{
    max-width: 100px;
    width: 100%;
    height: 100px;   
  }
 }
  @media(max-width:1230px){
    .brandRight{
      gap: -20px;
      justify-content: space-between;
      grid-template-columns: minmax(100px 160px) minmax(100px 160px) minmax(100px 160px) minmax(100px 160px);
       } 
   

    .brandRightItemTop img{
        max-width: 100px;
        width: 100%;
        height: 100px;   
      }
      .brandRightItemTop{
        height: 180px;
      }
   }

@media(max-width:1100px){

.brandRightItemTop img{
    max-width: 80px;
    width: 100%;
    height: 80px;   
  }
  .brandRightItemTop{
    height: 140px;
  }
}  
@media(max-width:992px){
  .rightLoad{
    margin-left: unset;
  }
  .leftLoad{
    display: none;
}
  .brandRight{
    gap: -20px;
    justify-content: space-between;
    grid-template-columns: minmax(100px 180px) minmax(100px 180px) minmax(100px 180px) minmax(100px 180px);
 } 
  .brandLeft{
    display: none;
  }
  .brandRight{
    margin-left: unset;
  }
  .brandBottom{
    margin-top: 50px;
  }
  .brandRightItemTop{
    height: 180px;
  }
  .brandBottomTop{
    margin-bottom: 50px;
    flex-direction: column;
  }
  .brandBottomTopItemLeft{
    margin-bottom: 50px;
  }
  .brandBottomTopItemRight{
    margin-left: unset;
    width: 100%;
  }
  .brandBottomTopItemRightContent{
    max-width: 100%;
    width: 100%;
  }
  .brandRightItemTop img{
    max-width: 120px;
    width: 100%;
    height: 120px;   
  }
}

@media(max-width:768px){
      .brandRight{
        grid-template-columns: 200px 200px 200px;
    }
      .brandRightItemTop{
        height: 190px;
      }
}

@media(max-width:686px){
  .brandRight{
    grid-template-columns: 170px 170px 170px;
}
    .brandRightItemTop{
      height: 170px;
    }
}
@media(max-width:550px){
    .brandRight{
      gap: -20px;
      /* column-gap: 90px;  */
      justify-content: space-between;
      grid-template-columns: minmax(100px,200px) minmax(100px,200px);
    }
    .brandRightItemTop{
      height: 190px;
    }
}
@media(max-width:470px){
    .brandRight{
        grid-template-columns: 1fr 1fr;
    }
    .brandRightItemTop{
      height: 190px;
    }
}
@media(max-width:470px){
  .brandRightItemTop{
    height: 170px;
  }
}
@media(max-width:393px){
    .brandRight{
        grid-template-columns: 1fr 1fr;
        row-gap: 27px;
    }
    .brandRightItemTop{
      height: 160px;
    }
    .brandBottom{
        margin-top: 20px;
    }
    .sliderbrand{
        margin-top: unset;
    }
    .brandBottomTopItemLeft{
        margin-bottom: 32px;
    }
    .brandBottomTop{
        margin-bottom: 48px;
    }
    .brandRightItemBottom span{
        font-size: 11px;
        font-weight: 400;
        line-height: 13px;
    }
    .brandBottomTopLeftContent h3{
        font-size: 20px;
        font-weight: 700;
        line-height: 23px;     
    }
    .brandBottomTopLeftContent p{
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;     
    }
}
@media(max-width:360px){
    .titleText{
      font-size: 25px;
    }
    .brandRight{
        grid-template-columns: 1fr 1fr;
        row-gap: 27px;
    }
    .brandRightItemTop{
      height: 140px;
    }
    .brandRightItemTop img{
      max-width: 98px;
      width: 100%;
      height: 98px;   
  }
}