.container {
  display: flex;
  flex-direction: column;
  min-width: 248px;
  max-width: 248px;
  width: 100%;
  padding: 24px 18px;
  border-radius: 5px;
  row-gap: 16px;
  background-color: var(--back-gray);
}
.searchContainer {
  position: relative;
  width: 100%;
}
.search {
  padding: 8px 22px 8px 12px;
  background-color: transparent;
  font-size: 10px;
  border-radius: 2px;
  border: 1px solid #28282833;
  width: 100%;
}
.searchBtn {
  cursor: pointer;
  position: absolute;
  top: 4px;
  right: 12px;
}
.discountedContainer {
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  padding-bottom: 20px;
  border-bottom: 1px solid #2828281a;
  width: 100%;
}
.discountedBtnBlock {
  position: relative;
  width: 40px;
  height: 18px;
  background: var(--black);
  border-radius: 2px;
  border: 2px solid var(--black);
  cursor: pointer;
}
.discountedBtnBlockActive {
  background: var(--red);
  border: 2px solid var(--red);
}
.discountedBtn {
  width: 16px;
  height: 14px;
  background: var(--white);
  border-radius: 2px;
  /* transform: translate("0px"); */
}
.discountedBtnBlockActive .discountedBtn {
  transform: translate(19px);
}
.setDefaultFilterBtn {
  background-color: var(--black);
  text-align: center;
  order: 1;
  border: none;
  min-height: 30px;
  cursor: pointer;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.setDefaultFilterBtn span {
  /* padding: 8px; */
  font-size: 12px;
  color: var(--white);
}
.setDefaultFilterBtn:hover {
  background-color: #000;
}
