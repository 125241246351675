.background {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(40, 40, 40, 0.4);
  z-index: 9999999999;
  display: flex;
  justify-content: center;
  align-items: center;
}
.main {
  max-width: 530px;
  width: 100%;
  max-height: 90vh;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  overflow: auto;
  border-radius: 5px;
  padding: 36px 30px 26px 30px;
  background-color: var(--white);
}
.loader {
  width: 530px;
  height: 90vh;
  max-width: 100%;
  max-height: 90%;
}
.header {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.title {
  font-size: 25px;
  font-weight: bold;
  color: black;
  text-transform: uppercase;
}
.closeBtn {
  align-self: flex-end;
  cursor: pointer;
}
.twoInputs {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}
.input {
  width: 100%;
  padding: 12px 24px;
  font-size: 14px;
  background-color: var(--back-gray);
  border-radius: 5px;
  border: 1px solid transparent;
  outline: none;
}
.input:focus {
  border-color: var(--green);
}
.priceBlock {
  width: 100%;
  column-gap: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.priceText {
  font-size: 16px;
  color: rgba(40, 40, 40, 0.4);
}
.paymentTitle {
  font-size: 20px;
  font-weight: bold;
  text-transform: uppercase;
}
.paymentsBlock {
  width: 100%;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 18px;
  justify-content: flex-start;
}
.buyBtn {
  display: flex;
  align-items: center;
  border-radius: 5px;
  justify-content: center;
  background-color: var(--red);
  width: 100%;
  padding: 16px;
  cursor: pointer;
}
.buyBtn:hover {
  background-color: var(--dark-red);
}
.buyBtn span {
  font-size: 14px;
  font-family: "Mardoto-Regular";
  color: var(--white);
}
.main::-webkit-scrollbar-track {
  background: #e4e4e4;
  border-radius: 4px;
  height: 19px;
}
.main::-webkit-scrollbar-thumb {
  background-color: var(--red);
  border-radius: 10px;
  background-clip: padding-box;
  transition: all 300ms ease-out;
}
.main::-webkit-scrollbar {
  height: 4px;
  width: 4px;
}
.errorMessage {
  width: 100%;
  text-align: center;
  color: var(--red);
}
.inputError {
  border: 1px solid var(--red) !important;
}
@media only screen and (max-width: 768px) {
  .main {
    max-height: 80vh;
  }
  .title {
    font-size: 20px;
  }
}

@media only screen and (max-width: 600px) {
  .main {
    width: 95%;
    max-height: 80vh;
  }

  .twoInputs {
    gap: 16px;
    flex-direction: column;
  }
}
@media only screen and (max-width: 430px) {
  .twoInputs {
    flex-direction: column;
    gap: 20px;
  }
  .paymentsBlock {
    gap: 10px;
  }
}
