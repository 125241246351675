.GiftItem {
  cursor: pointer;
  min-width: 160.58px;
  max-height: 55px;
  width: auto;
  padding: 5.4px 9.99px;
  gap: 5.4px;
  border-radius: 7.68px;
  border: 0.61px solid #bababa;
  display: flex;
  align-items: center;

  &:hover {
  border-color: var(--red);

  }
}
.activeGift{
  border-color: var(--red);
}
.GiftImage {
  width: 45px;
  height: 53px;
}

.GiftText {
  font-size: 11px;
  font-weight: 400;
  text-align: left;
  max-width: 100px;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
